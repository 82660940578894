const bookeditgeneral = {
    state: () => ({
        statusID: "0"
    }),
    mutations: {
        StatusID(state, statusId) {
            state.statusID = statusId
        }
    },
    actions: {
        getStatusAction({commit},statusId){
            commit('StatusID',statusId)
        }
    },
    modules: {}
}

export default bookeditgeneral;