			
			<template>
        <footer>
          <div class="container-fluid mx-auto">
            <div class="flex justify-between">
              <a href="#" class="terms">Terms & Conditions</a>
              <p class="copyright">&copy; {{ year }} Logistics Dynamics LLC</p>
            </div>
          </div>
        </footer>
      </template>
      <script>
      export default {
      data() {
          return {
            year:""
          }
        }
      ,
      created() {
        const d = new Date();
       this.year = d.getFullYear();
      }
      }
      </script>