<template>
  <div class="container mx-auto">HomePage</div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style></style>
