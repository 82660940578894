
import { HttpTransportType, HubConnectionBuilder } from "@microsoft/signalr";

import {store} from "@/store/store.js"

class LDIHub{
   constructor(){
       console.log(process.env.VUE_APP_HUB_URL);
       this.client=new HubConnectionBuilder()
   .withUrl(process.env.VUE_APP_HUB_URL+"ldi", {
    skipNegotiation: true,
    transport: HttpTransportType.WebSockets
   })
   .withAutomaticReconnect()
   .build(); // creates connection
   this.client.onreconnected(() => {
    this.client.invoke("JoinRoom",`${store.state.login.portalID}`);
   });
   }
   
   
   start(){
       console.log("In start");

       console.log("portalID-",`Bearer ${store.state.login.portalID}`);
       this.client.start()
       .then(() => this.client.invoke("JoinRoom",`${store.state.login.portalID}`).then((message) => console.log('Method connected: '+message))
       .catch(err => console.log('Error while starting method: ' + err)))
       .catch(err => console.log('Error while starting connection: ' + err));
       console.log("Proxy");
      
  this.client.invoke("SendMessage","LDI","Chat started...").then(() => console.log('Method connected'))
  .catch(err => console.log('Error while starting method: ' + err));
  
  this.client.on("ClientConnected", (message)=>{console.log('Message from Server:  '+message)});


   }
  
}

export default new LDIHub();