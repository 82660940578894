<template>
  <Dialog 
    v-model:visible="sourceloadpopup"
    :closable="false"
    header="Select Carrier"
    :style="{ width: '1205px' }"
    maximizable
    :modal="true" 
    class="theme-dialog sourceloadpopupResponsive carrier-select-dialog"
    @show="handleShow"
    @unmaximize="{isSourceLoadPopupMaximized=false;}"
    @maximize="{isSourceLoadPopupMaximized=true;}"
    >

    <template #header>
      <span id="pv_id_47_header" class="p-dialog-title" v-if="isCarrierSmartSearch">Carrier Smart Search</span>
      <span id="pv_id_47_header" class="p-dialog-title" v-else>Select Carrier</span>
      <div class="p-dialog-header-close-icons">
        <button class="p-dialog-header-icon p-dialog-header-close p-link" @click="close('close')" aria-label="close"
          type="button">
          <span class="p-dialog-header-close-icon pi pi-times"></span>
        </button>
      </div>
    </template>
    <div class="confirmation-content sourceload-body">
      <p v-if="isCarrierSmartSearch" class="mb-4">
       View frequented carriers based on lane history. Enter lane details and click the Search button to view.
      </p>
      <p v-else class="mb-4">
       View available and potential carriers to source this load. Select a single carrier and click the Select button to add a carrier to the load.
      </p>
      <div class="flex mb-3 gap-2">
        <div class="flex gap-2 w-7">
          <div class="field-wrapper" :class="isSourceLoadPopupMaximized && isCarrierSmartSearch?'w-[17%]':''">
            <label>Pickup City</label>
            <input v-if="!isCarrierSmartSearch" type="text" class="form-control" :class="!isSourceLoadPopupMaximized?'city-width-minimized':'city-width-maximized'" v-model="sourceLoadData.PickupCity" disabled />
            <Dropdown v-else
            ref="cityFilter"
            v-model="sourceLoadData.PickupCity"
            @before-show="clearFilterSearch(2)"
            :loading="pickupCityStateLoading"
            :options="pickupCities"
            optionLabel="City"
            autoFilterFocus
            optionValue="City"
            id="PickupCityID"
            placeholder="Select a City"
            :filter="true"
            :filterMatchMode="'startsWith'"
            :showClear="true"
          :virtualScrollerOptions="{ itemSize: 42 }"
            class="carriersmartsrchcitydropdown no-search-icon"
            :class="isSourceLoadPopupMaximized?'':'carriersmartsrchcitydropdownMinimized'"
            @change="changePickupCity($event)"
          >
            <template v-slot:loader="{ options }">
              <div class="flex align-items-center p-2" style="height: 38px">
                <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
              </div>
            </template>
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.City }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(2)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
          </div>
          <div class="field-wrapper" :class="isSourceLoadPopupMaximized && isCarrierSmartSearch?'w-[15%]':''">
            <label>State<span class="required" v-if="isCarrierSmartSearch">*</span></label>
            <Dropdown
            ref="pickstatefilter"
            v-if="isCarrierSmartSearch"
            :class="conditionalStateDropDownClass"
            v-model="sourceLoadData.PickupState"
            :loading="pickupCityStateLoading"
            :options="pickupstatesList"
            optionLabel="State"
            autoFilterFocus
            optionValue="State"
            :dataKey="State"
            :filter="true"
            placeholder="Select"
            class="carriersmartsrchstatedropdown"
            :showClear="true"
            @before-show="clearFilterSearch(5)"
            @change="changePickupState($event)"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.State }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(5)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
            <input v-else type="text" class="form-control" :class="!isSourceLoadPopupMaximized?'state-width-minimized':'state-width-maximized'" v-model="sourceLoadData.PickupState" disabled />
          </div>
          <div class="field-wrapper"
          :class="conditionalDateWidthClass"
          >
            <label>Date</label>
            <Calendar id="icon" v-model="sourceLoadData.PickupDate" class="selectCarrierDate" :showIcon="true" placeholder="MM/DD/YYYY"
              :disabled="!isCarrierSmartSearch" :showOnFocus="false" @date-select="onDateSelect()"
                              @keypress="isNumberSlash($event)"
                              @blur="handlePickupDateFormat($event)" :showOtherMonth="true"
                              :selectOtherMonths="true" />
          </div>
          <div class="field-wrapper w-300"
          :class="{'w-400':isSourceLoadPopupMaximized}">
                    </div>
          <div class="field-wrapper w-300">
           
                 </div>
        </div>
        <div class="flex gap-2 w-40">
          <div class="field-wrapper w-300">
            <label>DAT Market Rate</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="marketRate" disabled />
              <svg class="source-icon" v-if="increasedMarketRate">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedMarketRate">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>DAT Market Cost</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="marketCost" disabled />
              <svg class="source-icon" v-if="increasedMarketCost">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedMarketCost">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>Portal Rate</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="portalRate" disabled />
              <svg class="source-icon" v-if="increasedPortalRate">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedPortalRate">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>Portal Cost</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="portalCost" disabled />
              <svg class="source-icon" v-if="increasedPortalCost">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedPortalCost">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="flex gap-2" :class="isCarrierSmartSearch?'mb-3':'mb-5'">
        <div class="flex gap-2 w-7">
          <div class="field-wrapper" :class="isSourceLoadPopupMaximized && isCarrierSmartSearch?'w-16-5':''">
            <label class="block">Drop City</label>
            <Dropdown
            v-if="isCarrierSmartSearch"
            ref="dropCityFilter"
            @before-show="clearFilterSearch(3)"
            v-model="sourceLoadData.DestinationCity"
            :loading="destinationCityStateLoading"
            :options="destinationCities"
            optionLabel="City"
            autoFilterFocus
            :dataKey="City"
            id="destCityID"
            optionValue="City"
            placeholder="Select a City"
            :filter="true"
            :filterMatchMode="'startsWith'"
            :showClear="true"
              :virtualScrollerOptions="{ itemSize: 42 }"
            class="carriersmartsrchcitydropdown no-search-icon"
            :class="isSourceLoadPopupMaximized?'':'carriersmartsrchcitydropdownMinimized'"
            @change="changeDestinationCity($event)"
          >
            <template v-slot:loader="{ options }">
              <div class="flex align-items-center p-2" style="height: 38px">
                <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
              </div>
            </template>
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.City }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(3)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
            <input v-else type="text" class="form-control" :class="!isSourceLoadPopupMaximized?'city-width-minimized':'city-width-maximized'" v-model="sourceLoadData.DestinationCity" disabled />
          </div>
          <div class="field-wrapper" :class="isSourceLoadPopupMaximized && isCarrierSmartSearch?'w-13-8':''" >
            <label class="block">State</label>
            <Dropdown v-if="isCarrierSmartSearch"
            v-model="sourceLoadData.DestinationState"
            :loading="destinationCityStateLoading"
            :options="destinationstatesList"
            :dataKey="State"
            optionLabel="State"
            optionValue="State"
            placeholder="Select"
            autoFilterFocus
            class="carriersmartsrchstatedropdown"
            :class="isSourceLoadPopupMaximized?'':'carriersmartsrchstatedropdownMinimized'"
            :filter="true"
            :showClear="true"
            ref="dropstatefilter"
            @before-show="clearFilterSearch(6)"
            @change="changeDestinationState($event)"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.State }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(6)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown> 
            <input type="text" v-else class="form-control" :class="!isSourceLoadPopupMaximized?'state-width-minimized':'state-width-maximized'" v-model="sourceLoadData.DestinationState" disabled />
          </div>
          <div class="field-wrapper" :class="isSourceLoadPopupMaximized?'w-[21%]':''">
            <label>Weight</label>
            <div class="field-tooltip-wrapper">
            <input maxlength="6" type="text" class="form-control" v-model="sourceLoadData.Weight" @keypress="isNumber(event)"
            @input="ValidationCheck('weight')" :class="{ error: isMaxWeight }"
             :disabled='!isCarrierSmartSearch'/>
            <span v-if="isMaxWeight" v-tooltip="{
              value: 'The weight entered exceeds 200,000 lbs',
              class: 'custom-error',
              disabled: !isMaxWeight,
  }">
    <svg class="icon tooltip-error-icon">
      <use href="/svg/icons.svg#info" xlink:href="/svg/icons.svg#info"></use>
    </svg>
            </span>
          </div>
          </div>
          <div class="field-wrapper">
            <label>Miles</label>
            <input type="text" class="form-control" v-model="sourceLoadData.Mileage" :disabled="!isCarrierSmartSearch" maxlength="4" @keypress="isNumber($event)"/>
          </div>
          <div v-if="!isCarrierSmartSearch" class="field-wrapper">
            <label>Equipment</label>
            <input type="text" class="form-control" v-model="sourceLoadData.EquipmentType" :disabled="true" />
          </div>
          <div class="field-wrapper pos-r">
            <label>Size</label>
              <input maxlength="4" type="text" class="form-control" v-model="sourceLoadData.EquipMinSize" @keypress="isNumber(event)"
              @input="ValidationCheck('size')"
               :disabled='!isCarrierSmartSearch'/>
               <div v-if="isSizeError" class="errortext">
                Load will not pose to DAT
              </div>
          </div>
          <div class="field-wrapper" :class="isCarrierSmartSearch ? 'carrier-smart-typecheckbox' : ''">
            <div  v-if="!isCarrierSmartSearch" class="checkbox-wrapper team-wrapper mt-21-px mb-1" >
              <Checkbox name="boards" v-model="sourceLoadData.TeamService" :binary="true" :disabled="!isCarrierSmartSearch"
                class="disabledField" />
              <label>Team</label>
            </div>

            <div v-if="isCarrierSmartSearch" class="checkbox-wrapper team-wrapper">
              <Checkbox  name="boards" v-model="sourceLoadData.FTLLoad" @change="validateCheckboxes('FTLLoad')"  :binary="true" :disabled="!isCarrierSmartSearch"
              class="disabledField" />
            <label>FTL</label>
            </div>

           
            <div class="checkbox-wrapper team-wrapper">
              <Checkbox name="boards" v-model="sourceLoadData.PartialLoad" @change="validateCheckboxes('PartialLoad')"  :binary="true" :disabled="!isCarrierSmartSearch"
                class="disabledField" />
              <label>Partial</label>
            </div>
          </div>
        </div>
        <div class="flex gap-2 w-40">
          <div class="field-wrapper w-300">
            <label>LDI Rate</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="ldiRate" disabled />
              <svg class="source-icon" v-if="increasedLdiRate">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedLdiRate">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>LDI Cost</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control " :value="ldiCost" disabled />
              <svg class="source-icon" v-if="increasedLdiCost">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedLdiCost">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>LDI Margin</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control" :value="ldiMargin" disabled />
              <svg class="source-icon" v-if="increasedLdiMargin">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedLdiMargin">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
          <div class="field-wrapper w-300">
            <label>Portal Margin</label>
            <div class="flex blue-text-input">
              <input type="text" class="form-control border-control" :value="portalMargin" disabled />
              <svg class="source-icon" v-if="increasedPortalMargin">
                <use href="/svg/icons.svg#Up Arrow Icon" xlink:href="/svg/icons.svg#Up Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
              <svg class="source-icon" v-if="!increasedPortalMargin">
                <use href="/svg/icons.svg#Down Arrow Icon" xlink:href="/svg/icons.svg#Down Arrow Icon"></use>
                <title>Filter Data</title>
              </svg>
            </div>
          </div>
        </div>
      </div>
        <div class="flex w-12 mb-1" v-if="isCarrierSmartSearch">
           <div v-if="isCarrierSmartSearch" class="field-radiobutton w-3 flex">
                      <RadioButton value="Type" @click="clearGroupData()" name="eqpRadio" v-model="GroupTypeRadio" aria-checked="isCustomer"/>
                      <label class="ml-2 font-medium" :class="!isSourceLoadPopupMaximized?'mt-3':''">Type<span v-if="GroupTypeRadio=='Type'" class="required">*</span> : &nbsp;&nbsp;&nbsp;</label>
            <MultiSelect ref="equipmentFilter" @before-show="clearFilterSearch(4)" v-model="sourceLoadData.EquipmentTypeIDList" :options="equipmentList"
            optionLabel="EquipmentType" :showToggleAll="false" optionValue="EquipTypeID" placeholder="Select" filter autoFilterFocus
            class="p-column-filter hutype-dropdown w-[75%] ml-2" :disabled="GroupTypeRadio!='Type'" :class="computedClass">
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(4)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
            <template #option="slotProps">
              <div
                class="p-multiselect-representative-option"
                :key="slotProps.ID"
              >
                <span class="image-text">{{ slotProps.option.EquipmentType }}</span>
              </div>
            </template>
          </MultiSelect>
          </div>
           <div v-if="isCarrierSmartSearch" class="field-radiobutton w-6 ml-3">
                      <RadioButton value="Group" name="eqpRadio" v-model="GroupTypeRadio" @click="clearTypeData()" aria-checked="isCustomer" />
                      <label class="ml-2 font-medium flex">Group<span v-if="GroupTypeRadio!='Type'" class="required">*</span> &nbsp;: <span class="flex" v-if="GroupTypeRadio!='Type' && carrierSmartSearchValidationFlag && isCheckedAnyGroup" v-tooltip="{
              value: 'Please Select Atleast One Checkbox.',
              class: 'custom-error',
              disabled: !(GroupTypeRadio!='Type' && carrierSmartSearchValidationFlag && isCheckedAnyGroup),
  }"> &nbsp;
    <svg class="icon tooltip-error-icon group-error-icon">
      <use href="/svg/icons.svg#info" xlink:href="/svg/icons.svg#info"></use>
    </svg>
            </span></label>
                      <div class="flex items-center ml-2">
                      <Checkbox name="boards" v-model="Vans" :binary="true" :disabled="GroupTypeRadio=='Type'"  :class="GroupTypeRadio=='Type'?'disabled':''"/>
                      <label>Vans</label>
                    </div>
                    <div class="flex items-center ml-2">
                      <Checkbox name="boards" v-model="Flatbeds" :binary="true" :disabled="GroupTypeRadio=='Type'" :class="GroupTypeRadio=='Type'?'disabled':''"/>
                      <label>Flatbeds</label>
                    </div>
                    <div class="flex items-center ml-2">
                      <Checkbox name="boards" v-model="Reefers" :binary="true" :disabled="GroupTypeRadio=='Type'" :class="GroupTypeRadio=='Type'?'disabled':''"/>
                      <label>Reefers</label>
                    </div>
                    <div class="flex items-center ml-2">
                      <Checkbox name="boards" v-model="Specialized" :binary="true" :disabled="GroupTypeRadio=='Type'" :class="GroupTypeRadio=='Type'?'disabled':''"/>
                      <label>Specialized</label>
                    </div>
          </div>
         
          <div style="text-align: right;" class="w-3 mt-2" v-if="isCarrierSmartSearch">
            <button  class="theme-btn tab-focus" @click="smartSearchCarriers">
              Search
            </button>
          </div>
        </div>
        <hr class="mb-5" v-if="isCarrierSmartSearch">
      <div>
        <div class="card-sourceload">
          <TabView class="tabview-custom" ref="tabview4" :v-model:activeIndex="activeIndex">
            <TabPanel v-if="!isCarrierSmartSearch" :disabled="!isCarrierTabClickEnabled">
              <template #header>
                <div @click="getActiveTabName('carrierdomicile')">Carriers</div>
              </template>
              <div class="tab-upper-right-section w-[50%]">
                <div class="flex gap-2 justify-end">
                  {{ options }}
                  <div class="field-wrapper w-2">
                    <label>MC#</label>
                    <div class="filter-field txtWidth">
                      <InputText ref="MCSelectCarrier" type="text" @keyup.enter="searchCarrierDomicileGrid()" v-model="selectedMC"
                        class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchMC">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>

                  </div>
                  <div class="field-wrapper w-2">
                    <label>DOT#</label>
                    <div class="filter-field txtWidth">
                      <InputText type="text" @keyup.enter="searchCarrierDomicileGrid()" v-model="selectedDot"
                        class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchDot">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>

                  </div>
                  <div class="field-wrapper w-2">
                    <label>USDOT#</label>
                    <div class="filter-field txtWidth">
                      <InputText type="text" @keyup.enter="searchCarrierDomicileGrid()" v-model="selectedUSDot"
                        class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchUSDot">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div class="field-wrapper w-4">
                      <label>Carrier Name</label>
                      <div class="filter-field txtWidth">
                          <AutoComplete :suggestions="suggestions" @complete="search" v-model="selectedCarrier"
                          :style="{ width: '100%' }"
                          class="carrier-name-autocomplete carrier-name-autocomplete-responsive w-full txtWidth">
                          </AutoComplete>
                          <span class="load-id search-icon" id="basic-addon3" @click="clearSearchCarrier">
                              <svg class="icon">
                                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                              </svg>
                          </span>
                      </div>                        
                  </div>
                  <div class="field-wrapper">
                    <button class="mt-5" @click="searchCarrierDomicileGrid"
                      v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <div v-if="this.customers && this.customers.length > 0">
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="customers" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}" v-model:filters="filters"
                    ref="sdt" :totalRecords="totalCarrierDomicile" @page="onPage($event)" @sort="onSort($event)"
                    @filter="onFilter($event)" v-model:selection="selectedCustomers" :selectAll="selectAll"
                    @select-all-change="onSelectAllChange" @row-select="onRowSelect" @row-unselect="onRowUnselect"
                    :rowClass="rowClassCarrier" :scrollable="true" :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>

                    <Column field="CarrierMCNum" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.CarrierMCNum, class: 'tooltip-features' }">
                            {{
                              data.CarrierMCNum
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumber, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>
                    <Column field="State" header="State" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="ELDEnabled" header="ELD?" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="ContactName" header="Contact" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="Phone" header="Phone" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="email">
                    </Column>
                  </DataTable>
                </div>
              </div>
              <div class="search-section-wrapper" v-if="!(this.customers && this.customers?.length >
                  0) &&
                !isDataNotFound &&
                !loading
                ">
                <p>Add search criteria to get the results</p>
              </div>
              <div class="search-section-wrapper blink_me" v-if="loading && !this.customers?.length > 0">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="search-section-wrapper" v-if="isDataNotFound && !loading">
                <p>No records available.</p>
              </div>
            </TabPanel>
            <!-- --------------------------------------------------------------------------------------- -->

            <TabPanel v-if="!isCarrierSmartSearch" :disabled="!isFavoriteTabClickEnabled">
              <template #header>
                <div @click="customerServicesOnSearchFav('tab')">Favorites</div>
              </template>
              <div class="tab-upper-right-section">
                <div class="flex gap-2 justify-end">
                  {{ options }}
                  <div class="field-wrapper w-2">
                    <label>MC#</label>
                    <!-- <span class="p-input-icon-right"> -->
                    <div class="filter-field txtWidth">
                      <InputText type="text" v-model="selectedMCFav" class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchFavMC">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>
                    <!-- </span> -->
                  </div>
                  <div class="field-wrapper w-2">
                    <label>DOT#</label>
                    <!-- <span class="p-input-icon-right"> -->
                    <div class="filter-field txtWidth">
                      <InputText type="text" v-model="selectedDotFav" class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchFavDot">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>
                    <!-- </span> -->
                  </div>
                  <div class="field-wrapper w-2">
                    <label>USDOT#</label>
                    <!-- <span class="p-input-icon-right"> -->
                    <div class="filter-field txtWidth">
                      <InputText type="text" v-model="selectedUSDotFav" class="form-control bordernone w-full" />
                      <span class="load-id search-icon" id="basic-addon3" @click="clearSearchFavUSDot">
                        <svg class="icon">
                          <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                        </svg>
                      </span>
                    </div>
                    <!-- </span> -->
                  </div>
                  <div class="field-wrapper w-4">
                      <label>Carrier Name</label>
                      <div class="filter-field txtWidth">
                          <AutoComplete :suggestions="suggestions" @complete="search" v-model="selectedCarrierFav"
                          class="txtWidth carrier-name-autocomplete carrier-name-autocomplete-responsive">
                          </AutoComplete>
                          <span class="load-id search-icon" id="basic-addon3" @click="clearSearchFavCarrier">
                              <svg class="icon">
                                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                              </svg>
                          </span>
                      </div>
                  </div>
                  <div class="field-wrapper">
                    <button class="mt-5" @click="searchCarrierDomicileGridFav"
                      v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <div v-if="this.customersFav && this.customersFav.length > 0">
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="customersFav" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}"
                    v-model:filters="filtersFav" ref="fdt" :totalRecords="totalRecordsFav" @page="onPageFav($event)"
                    @sort="onSortFav($event)" @filter="onFilterFav($event)" v-model:selection="selectedCustomersFav"
                    :selectAll="selectAllFav" @select-all-change="onSelectAllChangeFav" @row-select="onRowSelectFav"
                    @row-unselect="onRowUnselectFav" :rowClass="rowClassFav" :scrollable="true" :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>

                    <Column field="CarrierMCNum" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.CarrierMCNum, class: 'tooltip-features' }">
                            {{
                              data.CarrierMCNum
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumber, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>


                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>
                    <Column field="State" header="State" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="ELDEnabled" header="ELD?" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="ContactName" header="Contact" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="Phone" header="Phone" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="email">
                    </Column>
                  </DataTable>
                </div>
              </div>
              <!-- <div class="search-section-wrapper" v-if="isShowGrid">
                              <p>Add search criteria to get the results</p>
                            </div> -->
              <div class="search-section-wrapper blink_me" v-if="loading && !this.customersFav?.length > 0">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <div class="search-section-wrapper" v-if="isFavoriteDataNotFound && !loading">
                <p>No records available.</p>
              </div>
            </TabPanel>

            <!-- ----------------------------------------------------------------------------------------- -->
            <TabPanel :disabled="!isHistoryTabClickEnabled">
              <template #header>
                <div @click="carrierHistoryOnSearch('tab')">History</div>
              </template>
              <div class="tab-upper-right-section">
                <div class="flex gap-2 justify-end">
                  <div class="flex mt-3">
                    <div v-if="!isCarrierSmartSearch" class="field-radiobutton">
                      <RadioButton value="Customer" v-model="historyRadio" aria-checked="isCustomer"
                        :class="isCustomerFlag ? 'disabledField' : 'enabledField'" :disabled="isCustomerFlag" />
                      <label>Customer</label>
                    </div>
                    <div class="field-radiobutton">
                      <RadioButton value="Portal" v-model="historyRadio" aria-checked="isPortal" />
                      <label>Portal</label>
                    </div>
                    <div class="field-radiobutton">
                      <RadioButton value="All" v-model="historyRadio" aria-checked="isALL" />
                      <label>All</label>
                    </div>
                  </div>
                  <div class="field-wrapper w-2 mr-2">
                    <label>Months</label>

                    <input type="number" class="form-control" min="0" :max="isCarrierSmartSearch ? 12 : 24" maxlength="2" inputmode="numeric"
                      pattern="\d*" @keyup="validateMonths" @blur="validateMonths" v-model="months"
                      :class="months > 24 ? 'errorMonths' : ''" />
                  </div>

                  <div class="field-wrapper w-2">
                    <label>Radius</label>
                    <Dropdown v-model="selectedRadiusHistory" :options="deliveringradius" optionLabel="radius"
                      optionValue="value" @change="onRadiusChange()" />
                  </div>
                  <div class="field-wrapper" @click="carrierHistoryOnSearch('search')">
                    <button class="mt-4" v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <div v-if="this.carrierHistory &&
                  this.carrierHistory.length >
                  0">
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="carrierHistory" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}" v-model:filters="filters"
                    ref="hhdt" :totalRecords="totalRecordsHistory" @page="onHistoryPageLoad($event)"
                    @sort="onSortHistory($event)" @filter="onHistoryFilter($event)"
                    v-model:selection="selectedCarrierHistory" :selectAll="selectAllCarrierHistory"
                    @select-all-change="onCarrierSelectAllChangeHistory" @row-select="onCarrierHistoryRowSelect"
                    @row-unselect="onCarrierHistoryRowUnSelect" :rowClass="rowClassHistory" :scrollable="true"
                    :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>
                    <Column class="wrk" header="WRK" field="wrk">
                      <template #body="{ data }">
                        <div>
                          <InputSwitch @click="UpdateHistoryWRKModel(data)" v-model="data.wrk" />
                        </div>
                      </template>
                    </Column>

                    <Column field="CarrierMCNum" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.CarrierMCNum, class: 'tooltip-features' }">
                            {{
                              data.CarrierMCNum
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumber, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="EquipmentTypeName" header="EQP" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>
                    <Column field="PickupDate" header="PU Date" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column class="location" field="PickupCity" header="Origin" filterMatchMode="startsWith"
                      ref="name" :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.PickupCity, class: 'tooltip-features' }">
                          {{
                            data.PickupCity
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="DistanceFromOrigin" header="DH/O" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column class="location" field="DropCity" header="Destination" filterMatchMode="startsWith"
                      ref="name" :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.DropCity, class: 'tooltip-features' }">
                          {{
                            data.DropCity
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="DistanceFromDestination" header="DH/D" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Weight" header="Weight" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="LineHaul" header="Amount" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="ContactName" header="Contact" filterMatchMode="startsWith" ref="name"
                      class="contact" :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.ContactName, class: 'tooltip-features' }">
                          {{
                            data.ContactName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column class="phone" field="Phone" header="Phone" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="email">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Email, class: 'tooltip-features' }">
                          {{
                            data.Email
                          }}
                        </span>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
              <div class="search-section-wrapper" v-if="this.carrierHistory &&
                  this.carrierHistory.length == 0 &&
                  !loading
                  ">
                <p>{{ searchMessage }}</p>
              </div>
              <div class="search-section-wrapper blink_me" v-if="loading && !this.carrierHistory?.length > 0">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel :disabled="!isDeliveringTabClickEnabled">
              <template #header>
                <div @click="carrierDeliveryOnSearch('tab')">Delivering</div>
              </template>
              <div class="tab-upper-right-section">
                <div class="flex gap-2 justify-end">
                  <div class="field-wrapper w-3">
                    <label>Date</label>
                    <Calendar id="icon" v-model="deliveringdate" :showIcon="true" class="date-icon"
                      placeholder="MM/DD/YYYY" :showOnFocus="false" @keypress="isNumberSlash($event)"
                      @blur="handleDateFormat($event)" />
                  </div>

                  <div class="field-wrapper w-2">
                    <label>Radius</label>
                    <Dropdown v-model="selectedRadius" :options="deliveringradius" optionLabel="radius"
                      optionValue="value" />
                  </div>
                  <div class="field-wrapper">
                    <button class="mt-4" @click="carrierDeliveryOnSearch('search')"
                      v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div class="table-responsive">
                <div v-if="this.carrierDelivery && this.carrierDelivery.length > 0">
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="carrierDelivery" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}" v-model:filters="filters"
                    ref="cdt" :totalRecords="totalRecordsCarrierDomicile" @page="onPageLoad($event)"
                    @sort="onDeliveringSort($event)" @filter="onFilter($event)"
                    v-model:selection="selectedCarrierDelivery" :selectAll="selectAllCarrier"
                    @select-all-change="onCarrierSelectAllChange" @row-select="onCarrierDeilveryRowSelect"
                    @row-unselect="onCarrierDeilveryRowUnSelect" :rowClass="rowClassDelivery" :scrollable="true"
                    :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>
                    <Column class="wrk" header="WRK" field="wrk">
                      <template #body="{ data }">
                        <div>
                          <InputSwitch @click="UpdateDeliveryWRKModel(data)" v-model="data.wrk" />
                        </div>
                      </template>
                    </Column>

                    <Column field="CarrierMCNum" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.CarrierMCNum, class: 'tooltip-features' }">
                            {{
                              data.CarrierMCNum
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumber, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="EquipmentTypeName" header="EQP" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>
                    <Column class="location" field="Location" header="Location" filterMatchMode="startsWith"
                      ref="name" :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Location, class: 'tooltip-features' }">
                          {{
                            data.Location
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="OriginRadius_DH" header="DH" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="DateAvailable" header="Available" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="ContactName" header="Contact" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.ContactName, class: 'tooltip-features' }">
                          {{
                            data.ContactName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column class="phone" field="Phone" header="Phone" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="email">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Email, class: 'tooltip-features' }">
                          {{
                            data.Email
                          }}
                        </span>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
              <div class="search-section-wrapper" v-if="this.carrierDelivery &&
                  this.carrierDelivery.length == 0 &&
                  !loading
                  ">
                <p>{{ searchMessage }}</p>
              </div>
              <div class="search-section-wrapper blink_me" v-if="loading && !this.carrierDelivery?.length > 0">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel :disabled="!isLoadBoardTabClickEnabled">
              <template #header>
                <div @click="carrierLoadBoardOnSearch('tab')">Load Boards</div>
              </template>

              <div class="tab-upper-right-section">
                <div class="flex gap-2 justify-end">
                  <div class="flex gap-2 mt-4">
                    <div class="flex items-center">
                      <Checkbox name="boards" v-model="expedited" :binary="true" />
                      <label>Expedited</label>
                    </div>
                    <div class="flex items-center">
                      <Checkbox name="boards" v-model="hazardous" :binary="true" />
                      <label>Hazardous</label>
                    </div>
                    <div class="flex items-center">
                      <Checkbox name="boards" v-model="pallet" :binary="true" />
                      <label>Pallet Exchange</label>
                    </div>
                    <div class="flex items-center">
                      <Checkbox name="boards" v-model="tarps" :binary="true" />
                      <label>Tarps</label>
                    </div>
                    <div class="flex items-center">
                      <Checkbox name="boards" v-model="teams" :binary="true" />
                      <label>Teams</label>
                    </div>
                  </div>
                  <div class="field-wrapper w-80px mr-2">
                    <label>Age(Min)</label>
                    <Dropdown v-model="selectedAge" :options="loadboardsAge" optionLabel="age" optionValue="age" />
                  </div>

                  <div class="field-wrapper w-2">
                    <label>Radius</label>
                    <Dropdown v-model="selectedLoadBoardRadius" :options="deliveringradius" optionLabel="radius"
                      optionValue="value" />
                  </div>
                  <div class="field-wrapper">
                    <button class="mt-4" @click="carrierLoadBoardOnSearch('search')"
                      v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive">
                <div v-if="this.carrierLoadBoard &&
                  this.carrierLoadBoard.length >
                  0">
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="carrierLoadBoard" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}" v-model:filters="filters"
                    ref="ldt" :totalRecords="totalRecordsLoadBoards" @page="onPageLoadBoard($event)"
                    @sort="onLoadBoardSort($event)" @filter="onLoadBoardFilter($event)"
                    v-model:selection="selectedLoadBoards" :selectAll="selectAllLoadBoards"
                    @select-all-change="onCarrierLoadBoardSelectAllChange" @row-select="onCarrierLoadBoardRowSelect"
                    @row-unselect="onCarrierLoadBoardRowUnSelect" :rowClass="rowClassLoadBoard" :scrollable="true"
                    :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>
                    <Column class="wrk" header="WRK" field="wrk">
                      <template #body="{ data }">
                        <div>
                          <InputSwitch @click="UpdateLoadBoardWRKModel(data)" v-model="data.wrk" />
                        </div>
                      </template>
                    </Column>
                    <Column header="Source" field="Source" ref="name" class="source"
                      :sortable="true">
                    </Column>
                    <Column header="Age" field="Age" ref="name" class="age" :sortable="true">
                    </Column>
                    <Column field="MC" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link v-if="data.CarrierSetup == 'Y'" :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.MC, class: 'tooltip-features' }">
                            {{
                              data.MC
                            }}
                          </span>
                        </custom-router-link>
                        <span v-if="data.CarrierSetup == 'N'"
                          v-tooltip.top="{ value: data.MC, class: 'tooltip-features' }">
                          {{
                            data.MC
                          }}
                        </span>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumber, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="EQP" header="EQP" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.EQP, class: 'tooltip-features' }">
                          {{
                            data.EQP
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="CarrierSetup" header="Carrier Setup?" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                      <template #body="{ data }">
                        <span :class="data.CarrierSetup">
                          {{
                            data.CarrierSetup
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>


                    <Column field="Location" class="location" header="Location" filterMatchMode="startsWith"
                      ref="name" :sortable="true">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Location, class: 'tooltip-features' }">
                          {{
                            data.Location
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="DH" header="DH" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>

                    <Column class="location" field="Available" header="Available" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Contact" header="Contact" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="loadBoardContact">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Contact, class: 'tooltip-features' }">
                          {{
                            data.Contact
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="Phone" header="Phone" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="loadBoardEmail">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.Email, class: 'tooltip-features' }">
                          {{
                            data.Email
                          }}
                        </span>
                      </template>
                    </Column>



                  </DataTable>
                </div>
              </div>

              <div class="search-section-wrapper" v-if="this.carrierLoadBoard &&
                  this.carrierLoadBoard.length == 0 &&
                  !loading
                  ">
                <p>{{ searchMessage }}</p>
              </div>
              <div class="search-section-wrapper blink_me" v-if="loading && !this.carrierLoadBoard?.length > 0">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel :disabled="!isHeadquarterTabClickEnabled">
              <template #header>
                <div @click="carrierHeadquartersOnSearch('tab')">
                  Headquarters
                </div>
              </template>
              <div class="tab-upper-right-section">
                <div class="flex gap-2 justify-end">
                  <div class="field-wrapper mt-4">
                    <div class="custom-switch-select">
                      <SelectButton v-model="hbselectedSwitch" :options="switchoptions" />
                    </div>
                  </div>

                  <div class="field-wrapper w-2">
                    <label>Radius</label>
                    <Dropdown v-model="selectedRadiusHeadquarters" :options="deliveringradius" optionLabel="radius"
                      optionValue="value" />
                  </div>
                  <div class="field-wrapper">
                    <button class="mt-4" @click="carrierHeadquartersOnSearch('search')"
                      v-tooltip.bottom="{ value: 'Filter Data', class: 'tooltip-features' }">
                      <svg class="icon">
                        <use href="/svg/icons.svg#Go Icon" xlink:href="/svg/icons.svg#Go Icon"></use>
                        <!-- <title>Filter Data</title> -->
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive" v-if="isShowHeadquartersGrid">
                <div>
                  <DataTable class="p-datatable-sm p-datatable-header sourceloadchildgrid" tableClass="sourceload-table" removableSort
                    :value="carrierHeadquarters" :lazy="true" :paginator="true" :rows="10"
                    paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink "
                    :rowsPerPageOptions="[10, 25, 50]" responsiveLayout="scroll"
                    currentPageReportTemplate="Showing {first} to {last} out of {totalRecords}" v-model:filters="filters"
                    ref="hdt" :totalRecords="totalRecordsCarrierHeadquarters" @page="onPageLoadCarrierHeadquaters($event)"
                    @sort="onCarrierHeadquatersSort($event)" @filter="onFilter($event)"
                    v-model:selection="selectedCarrierHeadquarters" :selectAll="selectAllCarrierHeadquarters"
                    @select-all-change="onCarrierHeadquartersSelectAllChange" @row-select="onCarrierHeadquartersRowSelect"
                    @row-unselect="onCarrierHeadquartersRowUnSelect" :rowClass="rowClassHead" :scrollable="true"
                    :loading="loading">
                    <Column header="SEL" field="IsLoad" selectionMode="multiple" class="sel">
                    </Column>

                    <Column field="CarrierMCNum" header="MC#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="mccol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.CarrierMCNum, class: 'tooltip-features' }">
                            {{
                              data.CarrierMCNum
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="USDOTNumber" header="USDOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.USDOTNumber, class: 'tooltip-features' }">
                            {{
                              data.USDOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="DOTNumber" header="DOT#" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="usdotcol">
                      <template #body="{ data }">
                        <custom-router-link :to="{
                          path: '/carriers-profile/' + data.CarrierID,
                        }" newTab>
                          <span v-tooltip.top="{ value: data.DOTNumbe, class: 'tooltip-features' }">
                            {{
                              data.DOTNumber
                            }}
                          </span>
                        </custom-router-link>
                        <span class="image-text"></span>
                      </template>
                    </Column>
                    <Column field="CarrierName" header="Carrier Name" filterMatchMode="startsWith" ref="name"
                      :sortable="true" class="carriercol">
                      <template #body="{ data }">
                        <span v-tooltip.top="{ value: data.CarrierName, class: 'tooltip-features' }">
                          {{
                            data.CarrierName
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column field="IsLoad" header="Load?" filterMatchMode="startsWith" ref="name" :sortable="false">
                      <template #body="{ data }">
                        <span :class="[data.IsLoad == 'N' ? 'N' : data.ErrorMessage == '' ? 'Y' : 'Yellow']"
                          v-tooltip="{ value: data.ErrorMessage, class: 'tooltip-features', disabled: (data.ErrorMessage == '') }">
                          {{
                            data.IsLoad
                          }}
                        </span>
                      </template>
                    </Column>
                    <Column class="location" field="Location" header="Location" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="DH" :header="DH" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>

                    <Column field="Rating" header="Rating" filterMatchMode="startsWith" ref="name" :sortable="true">
                      <template #body="{ data }">
                        <Rating v-model="data.Rating" :cancel="false" readonly="true" />
                      </template>
                    </Column>

                    <Column field="ELD" header="ELD?" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>

                    <Column field="Contact" header="Contact" filterMatchMode="startsWith" ref="name" :sortable="true">
                    </Column>
                    <Column class="phone" field="Phone" header="Phone" filterMatchMode="startsWith" ref="name"
                      :sortable="true">
                    </Column>
                    <Column field="Email" header="Email" filterMatchMode="startsWith" ref="name" :sortable="true"
                      class="email" :style="emailWidthWhenMaximized">
                    </Column>
                  </DataTable>
                </div>
              </div>

              <div class="search-section-wrapper blink_me" v-if="loading && !isShowHeadquartersGrid">
                <div role="status">
                  <svg aria-hidden="true" class="mr-2 w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                      fill="currentColor" />
                    <path
                      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                      fill="currentFill" />
                  </svg>
                  <span class="sr-only">Loading...</span>
                </div>
              </div>

              <div class="search-section-wrapper" v-if="this.carrierHeadquarters &&
                this.carrierHeadquarters.length == 0 &&
                !loading
                ">
                <p>{{ searchMessage }}</p>
              </div>
            </TabPanel>
          </TabView>
        </div>
      </div>
    </div>
    <template #footer>
      <aside class="usps-only" v-if="this.uspsCarriersOnly">Only showing USPS Carriers</aside>
      <button class="theme-btn" @click="exportExcel()" :disabled="!isDataAvailable || exportInProgress">
        Export
      </button>
      <button class="theme-btn-secondary" :disabled="!isDataAvailable" @click="triggerEmail()">
        Email
      </button>
      <button v-if="!isCarrierSmartSearch && isLoadLocked" :disabled="selectDisabled" class="theme-btn-border" @click="toggleLockedDialog()">
        Select
      </button>
      <button v-if="!isCarrierSmartSearch && !isLoadLocked" class="theme-btn-border" :disabled="selectDisabled" @click="triggerSelect()">
        Select
      </button>
      <button class="cancel-btn" @click="close('cancel')">{{isCarrierSmartSearch ? 'Exit' : 'Cancel'}}</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="lockedDialog" :style="{ width: '450px' }" header="Error" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>This Load is currently locked for editing by {{ lockedByUserName }}</span>
    </div>
    <template #footer>
      <button class="theme-btn-border" @click="toggleLockedDialog()">Ok</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="cancelDialog" :style="{ width: '450px' }" header="Confirm" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>Are you sure you want to {{ popupclosetext }}?</span>
    </div>
    <template #footer>
      <button class="theme-btn" @click="closePopups">Yes</button>
      <button class="btn-outline" @click="closeCancelDialog">No</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="carrierDialog" :style="{ width: '450px' }" header="Confirm" :modal="true"
    @hide="closeCarrierDialog()" class="theme-dialog">
    <div v-if="carrierDOTCondition" class="confirmation-content">
      <span>
        Have you confirmed the carrier has the permits required to run in the
        selected states?
      </span>
      <div v-if="carrierDOTCondition" class="flex mt-1">
        <div class="field-radiobutton mr-3">
          <RadioButton value="Yes" v-model="DOTPermit" @change="DOTPermitChange" />
          <label>Yes</label>
        </div>
        <div class="field-radiobutton">
          <RadioButton value="No" v-model="DOTPermit" aria-checked="true" @change="DOTPermitChange" />
          <label>No</label>
        </div>
      </div>
    </div>

    <div v-if="carrierInsuranceCondition" class="confirmation-content">
      <span>Does this carrier offer the insurance coverage required by your
        customer on this shipment?</span>
    </div>
    <div v-if="carrierInsuranceCondition" class="flex mt-1">
      <div class="field-radiobutton mr-3">
        <RadioButton value="Yes" v-model="CarrierInsurance" @change="InsuranceChange" />
        <label>Yes</label>
      </div>
      <div class="field-radiobutton">
        <RadioButton value="No" v-model="CarrierInsurance" @change="InsuranceChange" />
        <label>No</label>
      </div>
    </div>
    <template #footer>
      <button class="theme-btn" :disabled="disableSaveCarrier && CarrierInsurance == 'No'" @click="saveCarrier()">
        Save
      </button>
      <button class="btn-outline" @click="closeCarrierDialog()">Cancel</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="dotStateDialog" :style="{ width: '450px' }" header="Alert" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>{{ carrierMsgContent }}</span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="closeDOTStateDialog">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="EmailValidationDialog" :style="{ width: '450px' }" header="Alert" :modal="true"
    class="theme-dialog">
    <div class="confirmation-content">
      <span>{{ emailMsgContent }}</span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="closeEmailvalidationDialog">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="isCarrierSelectedDialog" :style="{ width: '450px' }" header="Alert" :modal="true"
    class="theme-dialog" @hide="removeOverflow">
    <div class="confirmation-content">
      <span>Please select atleast 1 carrier to proceed.</span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="closeDOTStateDialog">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="isMaximumCarrierSelectedDialog" :style="{ width: '450px' }" header="Alert" :modal="true"
    class="theme-dialog" @hide="removeOverflow">
    <div class="confirmation-content">
      <span>Please select less than 50 carriers to proceed.</span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="closeDOTStateDialog">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="sureDialog" :style="{ width: '450px' }" header="Confirm" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>Are you sure you want to change the Primary Carrier for this
        load?</span>
    </div>
    <template #footer>
      <button class="theme-btn" @click="closeSureDialog('ok')">OK</button>
      <button class="btn-outline" @click="closeSureDialog('cancel')">
        Cancel
      </button>
    </template>
  </Dialog>
  <Dialog v-model:visible="sureNoDialog" :style="{ width: '450px' }" header="Confirm" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>{{ sureNoDialogMessage }}</span>
    </div>
    <template #footer>
      <button class="theme-btn" @click="closeSureNoDialog()">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="selectAlert" :style="{ width: '450px' }" header="Alert" :modal="true" class="theme-dialog">
    <div class="confirmation-content">
      <span>Carrier cannot be added to a load in Covered or later statuses. If you would like to select a new carrier,
        please remove the current carrier from the load.</span>
    </div>
    <template #footer>
      <button class="theme-btn" @click="closeSelectAlertDialog()">OK</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="saveLoadConfirmationpopupdialog" :style="{ width: '450px' }" header="Confirm" :modal="true"
    class="theme-dialog">
    <div class="confirmation-content">
      <span>Please save load before start Tracking.</span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="closeSaveLoadConfirmationpopupdialog()">Close</button>
    </template>
  </Dialog>
  <Dialog v-model:visible="dateConfirmationDialog"
            :style="{ width: '450px' }"
            header="Confirm"
            :modal="true"
            class="theme-dialog">
        <div class="confirmation-content">
            <span>Pickup Date is in the past. Are you sure you want to proceed?</span>
        </div>
        <template #footer>
            <button class="theme-btn" @click="dateConfirmationDialog=false">Yes</button>
            <button class="btn-outline" @click="resetCurrentDateTime()">No</button>
        </template>
    </Dialog>
  <document-email ref="emailDoc"></document-email>
</template>
<script>
import DateHelper from "@/assets/datehelpers";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import Rating from "primevue/rating";
import { dataProvider } from "@/api/dataProvider.js";
import useVuelidate from "@vuelidate/core";
import CustomerService from "@/service/CustomerService";
import _ from "lodash";
import { json2excel } from "js2excel";
import InputSwitch from "primevue/inputswitch";
import DocumentEmail from '../documentEmail.vue';
import AutoComplete from 'primevue/autocomplete';
import CustomRouterLink from "@/components/common/CustomRouterLink.vue";
import MultiSelect from 'primevue/multiselect';
import { parseLdiStartAndEndTime } from "@/assets/timehelpers.js";

    

    export default {
  data() {
    return {
      isSourceLoadPopupMaximized:false,
      Reefers:false,
      Specialized:false,
      Vans:false,
      Flatbeds:false,
      dateConfirmationDialog:false,
      carrierSmartSearchValidationFlag:false,
      weightError:false,
      isMaxWeight:false,
      isSizeError:false,
      equipmentList:[],
      pickupCities:[],
      pickupCityStateLoading:true,
      destinationCities:[],
      destinationstatesList:[],
      destinationCityStateLoading:true,
      isCarrierSmartSearch:false,
      saveLoadConfirmationpopupdialog: false,
      lockedDialog: false,
      selectAlert: false,
      exportInProgress: false,
      v$: useVuelidate(),
      activeIndex: 0,
      loadID: 0,
      carrierCondition: false,
      carrierDialog: false,
      selectDisabled: true,
      searchLen: "",
      searchLenFav: "",
      disableSaveCarrier: true,
      carrierDOTCondition: false,
      carrierInsuranceCondition: false,
      dotStateDialog: false,
      EmailValidationDialog: false,
      isCarrierSelectedDialog: false,
      isMaximumCarrierSelectedDialog: false,
      DOTPermit: "No",
      CarrierInsurance: "No",
      sourceloadpopup: false,
      cancelDialog: false,
      carrierMsgContent: "",
      date: "",
      starttime: "",
      endtime: "",
      checked: true,
      deliveringdate: "",
      carrierNameMcNum: "",
      selectedRadius: 100,
      selectedLoadBoardRadius: 100,
      selectedRadiusHeadquarters: 100,
      selectedRadiusHistory: 100,
      selectedAge: 60,
      searchMessage: "Add search criteria to get the results",
      uspsCarriersOnly: false,
      deliveringradius: [
        {
          radius: 25,
          value: 25,
        },
        {
          radius: 50,
          value: 50,
        },
        {
          radius: 75,
          value: 75,
        },
        {
          radius: 100,
          value: 100,
        },
        {
          radius: 150,
          value: 150,
        },
        {
          radius: 250,
          value: 250,
        },
        {
          radius: 500,
          value: 500,
        },
      ],
      loadboardsAge: [
        {
          age: 60,
        },
        {
          age: 120,
        },
        {
          age: 180,
        },
        {
          age: 240,
        },
        {
          age: 300,
        },
      ],
      switchoptions: ["Headhaul", "Backhaul"],
      hbselectedSwitch: "Headhaul",
      popupclosetext: "",
      sourceLoadData: {
        AssetId: "",
        CustomerID: 0,
        DestinationCity: "",
        DestinationState: "",
        DropDate: "",
        EquipMinSize: "",
        EquipTypeID: 0,
        EquipmentTypeIDList:[],
        EquipmentType: "",
        LoadStatus: "",
        LoadType: 0,
        Mileage: "0",
        NetworkLoad: false,
        PartialLoad: false,
        PickupCity:"",
        PickupDate: "",
        PickupEndTime: "",
        PickupStartAndEndTime: "",
        PickupStartTime: "",
        PickupState:"",
        TeamService: false,
        FTLLoad:true,
        TruckstopLoadID: null,
        USPSCarriersOnly: false,
        Weight: "",
        lockedByID:null
      },
      marketRate: "",
      increasedMarketRate: "",
      marketCost: "",
      increasedMarketCost: "",
      portalRate: "",
      increasedPortalRate: "",
      portalCost: "",
      increasedPortalCost: "",
      ldiRate: "",
      increasedLdiRate: "",
      ldiCost: "",
      increasedLdiCost: "",
      portalMargin: "",
      increasedPortalMargin: "",
      ldiMargin: "",
      increasedLdiMargin: "",
      selectedSourceLoadData: {},
      historyRadio: "All",
      GroupTypeRadio:'Type',
      isCustomerFlag: false,
      customerService: null,
      showIcon: false,
      filter_options_origins: [],
      filter_options_destinations: [],
      filter_options_carriers: [],
      columns: null,
      loading: false,
      deliveryLoading: false,
      totalRecords: 0,
      totalRecordsCarrierDomicile: 0,
      totalCarrierDomicile: 0,
      totalRecordsHistory: 0,
      totalRecordsLoadBoards: 0,
      totalRecordsFav: 0,
      totalRecordsCarrierHeadquarters: 0,
      activeTab: "carrierdomicile",
      customers: null,
      customersFav: null,
      carrierDelivery: null,
      carrierHistory: null,
      selectedCustomers: null,
      selcetedCustomersFav: null,
      carrierHeadquarters: null,
      selectedCarrierHeadquarters: null,
      selectAll: false,
      selectAllFav: false,
      selectAllCarrier: false,
      selectAllCarrierHistory: false,
      selectAllLoadBoards: false,
      selectAllCarrierHeadquarters: false,
      selectedCarrierDelivery: null,
      selectedLoadBoards: null,
      selectedCarrierHistory: null,
      selectedCustomersFav: null,
      isDataAvailable: false,
      isCarrierTabClickEnabled: true,
      isFavoriteTabClickEnabled: true,
      isHistoryTabClickEnabled: true,
      isDeliveringTabClickEnabled: true,
      isLoadBoardTabClickEnabled: true,
      isHeadquarterTabClickEnabled: true,
      isDataNotFound: false,
      isFavoriteDataNotFound: false,
      filters: {
        CarrierName: { value: "", MatchMode: "contains" },
        CarrierMCNum: { value: 0, MatchMode: "contains" },
        DOTNumber: { value: 0, MatchMode: "contains" },
        USDOTNumber: { value: 0, MatchMode: "contains" },
      },
      filtersFav: {
        CarrierNameFav: { value: "", MatchMode: "contains" },
        CarrierMCNumFav: { value: 0, MatchMode: "contains" },
        DOTNumberFav: { value: 0, MatchMode: "contains" },
        USDOTNumberFav: { value: 0, MatchMode: "contains" },
      },
      carrierFilters: {
        PickupCity: { value: "", MatchMode: "contains" },
        PickupState: { value: "", MatchMode: "contains" },
        PickupDate: { value: "", MatchMode: "contains" },
        Radius: { value: 0, MatchMode: "contains" },
      },
      carrierHeadquartersFilters: {
        City: { value: "", MatchMode: "contains" },
        State: { value: "", MatchMode: "contains" },
        Radius: { value: 100, MatchMode: "contains" },
      },
      lazyParams: {},
      carrierName: "",
      carrierNameFav: "",
      serachCarrierNameMessage: "",
      selectedMC: "",
      selectedMCFav: "",
      selectedDot: "",
      selectedDotFav: "",
      selectedUSDot: "",
      selectedUSDotFav: "",
      selectedCarrier: "",
      selectedCarrierFav: "",
      sourceLoadId: "",
      sourceLoadType: null,
      isShowGrid: true,
      isShowCarrierGrid: false,
      isShowLoadBoardGrid: false,
      saveCarrierLoadData: {},
      cities: null,
      months: 12,
      expedited: false,
      hazardous: false,
      pallet: false,
      tarps: false,
      teams: false,
      LoadStatus: "",
      sureDialog: false,
      sureNoDialog: false,
      sureNoDialogMessage: "",
      isShowHeadquartersGrid: false,
      isShowHistoryGrid: true,
      DH: "DH",
      sortOrderFlag: false,
      carrierLoadBoard: null,
      historyWRKData: [],
      deliveryWRKData: [],
      loadboardWRKData: [],
        fromBookEdit: false,
        carrierNames: [],
        carrierList: [],
        suggestions:[]
    };
  },
  props: ["carriers"],
  components: {
    DataTable,
    Column,
    Rating,
    InputSwitch,
    DocumentEmail,
    AutoComplete,
    CustomRouterLink,
    MultiSelect
  },
 async created() {
      this.getEquipments();
      this.customerService = new CustomerService();
  },
  mounted() {
    this.sortOrderFlag = false;
    this.loading = false;
    // To do - Temparay commented this as bookedit page is not working ..
    // var first = document.querySelector(".pi-angle-double-left");
    // first.setAttribute("title", "First");
    // var last = document.querySelector(".pi-angle-double-right");
    // last.setAttribute("title", "Last");
  },
  validations() {
    return {};
  },
        methods: {
          resetCurrentDateTime() {
      this.sourceLoadData.PickupDate = new Date(
        new Date().getTime()
      );
      this.dateConfirmationDialog = false;
    },
          handlePickupDateFormat(evt)
    {     
        this.sourceLoadData.PickupDate = DateHelper.formatDate(evt);
        if(!evt.originalEvent.relatedTarget)
          this.onDateSelect();
    },
    onDateSelect() {
      if (this.sourceLoadData.PickupDate&&
        this.sourceLoadData.PickupDate != null &&
        this.sourceLoadData.PickupDate != "" 
      ) {
        let DateArray=new Date(this.sourceLoadData.PickupDate).toLocaleDateString().split('/');
        let currentDateArray=new Date().toLocaleDateString().split('/')
        DateArray=DateArray.map(i=>Number(i));
        currentDateArray=currentDateArray.map(i=>Number(i));
       if (currentDateArray[2] > DateArray[2] ||
       (currentDateArray[2] == DateArray[2] && currentDateArray[0] > DateArray[0])||
       (currentDateArray[2] == DateArray[2] &&
        currentDateArray[0] == DateArray[0] &&
         currentDateArray[1] > DateArray[1])){
       this.dateConfirmationDialog = true;
         }
      }
    },
          smartSearchCarriers(){
            if(!this.isMaxWeight && this.sourceLoadData.PickupState &&((this.sourceLoadData.EquipmentTypeIDList.length>0 && this.GroupTypeRadio=='Type')||(this.GroupTypeRadio!='Type' && !this.isCheckedAnyGroup)))
            {
              this.carrierSmartSearchValidationFlag = false;
              
              if(this.activeTab == "history")
                 this.carrierHistoryOnSearch('tab');
              else if(this.activeTab == "carrierdelivery")
                 this.carrierDeliveryOnSearch('tab');
              else if(this.activeTab == "loadboards")
                 this.carrierLoadBoardOnSearch('tab');
              else if(this.activeTab == "carrierheadquarters")
                 this.carrierHeadquartersOnSearch('tab');
            }
            else
            {
              this.carrierSmartSearchValidationFlag = true;
              this.carrierMsgContent = "State and Equipment Type are required for Search";
            }
          },
          isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && ( charCode < 48 || charCode > 57)
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ValidationCheck(type) {
      this.zeroClear(type)
      if (type == "weight") {
        if (this.sourceLoadData.Weight > 199999) {
          this.isMaxWeight = true;
        } else {
          this.isMaxWeight = false;
        }       
      }
      if(type == "size") {
          if(this.sourceLoadData.EquipMinSize >149){
            this.isSizeError = true;
          }
          else{
            this.isSizeError = false;
          }
      }
    },

    zeroClear(type) {
      if (this.sourceLoadData.Weight == 0 && type == "weight") {
        this.sourceLoadData.Weight = "";
      }
      if (this.sourceLoadData.EquipMinSize == 0 && type == "size") {
        this.sourceLoadData.EquipMinSize = "";
      }
      },
      clearFilterSearch(ref) {
      if ((this.$refs.cityFilter.filterValue) && (ref == 2)) {
        this.$refs.cityFilter.filterValue = "";
      }

      if ((this.$refs.dropCityFilter?.filterValue) && (ref == 3)) {
        this.$refs.dropCityFilter.filterValue = "";
      }

      if ((this.$refs.equipmentFilter.filterValue != null) && (ref == 4)) {
        this.$refs.equipmentFilter.filterValue = "";
      }

      if ((this.$refs.pickstatefilter?.filterValue) && (ref == 5)) {
        this.$refs.pickstatefilter.filterValue = "";
      }
      if ((this.$refs.dropstatefilter?.filterValue) && (ref == 6)) {
        this.$refs.dropstatefilter.filterValue = "";
      }
    },
    clearTypeData(){
      this.clearFilterSearch(4);
      this.sourceLoadData.EquipmentTypeIDList=[1];
      
    },
    clearGroupData(){
      this.Vans=0;
      this.Specialized=0;
      this.Reefers=0;
      this.Flatbeds=0;
    },
 async changePickupCity(CityState) {
  this.sourceLoadData.Mileage=this.sourceLoadData.Mileage?this.sourceLoadData.Mileage:"0";
  this.pickupCityStateLoading = true;

  const pickupCity = CityState.value || null;
  const selectedCustomer = this.selectedCustomer ? this.selectedCustomer.CustomerID : 0;

  const res = await dataProvider.getCityState(
    selectedCustomer,
    pickupCity,
    this.sourceLoadData.PickupState,
    1
  );

  this.pickupCities = res.cities;
  this.pickupstatesList = res.states;

  if (
    (!this.sourceLoadData.PickupState || this.sourceLoadData.PickupState === "") &&
    res.states.length === 1
  ) {
    this.sourceLoadData.PickupState = res.states[0].State;
  }

  this.pickupCityStateLoading = false;
  this.getPCMiler();
},
   async changePickupState(State) {
  this.sourceLoadData.Mileage=this.sourceLoadData.Mileage?this.sourceLoadData.Mileage:"0";
  this.pickupCityStateLoading = true;

  const pickupState = State.value || null;
  const selectedCustomer = this.selectedCustomer ? this.selectedCustomer.CustomerID : 0;

  if (selectedCustomer && !pickupState) {
    const res = await dataProvider.getCityState(selectedCustomer, null, null, 1);
    this.pickupCities = res.cities;
    this.pickupstatesList = res.states;
    this.sourceLoadData.PickupCity = "";
  } else {
    const res = await dataProvider.getCityState(selectedCustomer, null, pickupState, 1);
    this.pickupCities = res.cities;
  }

  this.pickupCityStateLoading = false;
  this.getPCMiler();
},
async changeDestinationCity(CityState) {
  this.sourceLoadData.Mileage=this.sourceLoadData.Mileage?this.sourceLoadData.Mileage:"0";
  this.destinationCityStateLoading = true;

  const destinationCity = CityState.value || null;
  const selectedCustomer = this.selectedCustomer ? this.selectedCustomer.CustomerID : 0;

  if (!destinationCity) {
    const res = await dataProvider.getCityState(selectedCustomer, null, this.sourceLoadData.DestinationState, 2);
    this.destinationCities = res.cities;
    this.destinationstatesList = res.states;
  } else {
    const res = await dataProvider.getCityState(selectedCustomer, destinationCity, this.sourceLoadData.DestinationState, 2);
    this.destinationCities = res.cities;
    this.destinationstatesList = res.states;
    if ((!this.sourceLoadData.DestinationState || this.sourceLoadData.DestinationState === "") && res.states.length === 1) {
      this.sourceLoadData.DestinationState = res.states[0].State;
    }
  }

  this.destinationCityStateLoading = false;
  this.getPCMiler();
},
    async changeDestinationState(State) {
  this.sourceLoadData.Mileage=this.sourceLoadData.Mileage?this.sourceLoadData.Mileage:"0";
  this.destinationCityStateLoading = true;

  const destinationState = State.value || null;
  const selectedCustomer = this.selectedCustomer ? this.selectedCustomer.CustomerID : 0;

  if (selectedCustomer && !destinationState) {
    const res = await dataProvider.getCityState(selectedCustomer, null, null, 2);
    this.destinationCities = res.cities;
    this.destinationstatesList = res.states;
    this.sourceLoadData.DestinationCity = "";
  }else {
    const res = await dataProvider.getCityState(selectedCustomer, null, destinationState, 2);
    this.destinationCities = res.cities;
  }

  this.destinationCityStateLoading = false;
  this.getPCMiler();
},
    async getPCMiler() {
      if (
        this.sourceLoadData.PickupCity &&
        this.sourceLoadData.PickupState &&
        this.sourceLoadData.DestinationCity &&
        this.sourceLoadData.DestinationState
      ) {
        await dataProvider
          .getTrackLoadMilege(
            this.sourceLoadData.PickupCity,
            this.sourceLoadData.PickupState,
            this.sourceLoadData.DestinationCity,
            this.sourceLoadData.DestinationState
          )
          .then((res) => {
            if (!isNaN(parseFloat(res)) && isFinite(res)) {
              const result = Math.round(res);
              this.sourceLoadData.Mileage = ""+result;}
            // } else {
            //   this.milegeDialog = true;
            // }
          });
        // this.showSaveMessage = false;
        // this.isPostEnabled = false;
      }
      else{
        this.sourceLoadData.Mileage=this.sourceLoadData.Mileage?this.sourceLoadData.Mileage:"0";
      }
    },
            async handleShow() {
              this.loading = true;
              await this.getCarriers();
              this.loading = false;
              this.getCityStates()
            },
            async getCityStates() {
      await dataProvider.getCityState(0, null, null, 0).then((res) => {
        // this.$store.commit("login/setCities", res.cities);
        // this.$store.commit("login/setStates", res.states);
        this.pickupCities = res.cities;
        this.destinationCities = res.cities;
        this.pickupstatesList = res.states;
        this.destinationstatesList = res.states;
        this.pickupCityStateLoading = false;
        this.destinationCityStateLoading = false;
      });
    },
            async getCarriers() {
                let filterOnHold = true;
                if((!this.carriers || this.carriers.length === 0) && this.carrierList.length === 0) {
                  await dataProvider.getCarrierNames(filterOnHold, this.uspsCarriersOnly).then((res) => {
                    this.carrierList = res;
                    this.$emit('carrierList', this.carrierList);
                  });
                } else if (this.carriers?.length > 0 && this.carrierList?.length === 0) {
                  this.carrierList = this.carriers;
                }
                if (this.carrierNames.length === 0) {
                  this.carrierNames = this.carrierList.map(car => car.CarrierName);
                }
            },
            search(event) {
                this.suggestions = this.carrierNames.filter(word => word.toLowerCase().startsWith(event.query.toLowerCase()));
            },
    handleDateFormat(evt) {

      this.deliveringdate = DateHelper.formatDate(evt);
    },
    isNumberSlash(evt) {
      evt = evt ? evt : window.event;
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
      var textInField = evt.key;

      if (textInField.length == 1) {
        if (!keysAllowed.includes(textInField)) {
          evt.preventDefault();
        }

      }

      if (textInField !== '/') {
        DateHelper.AutoAddSlashes(evt)
      }
    },
    toggleLockedDialog() {
      this.lockedDialog = !this.lockedDialog;
    },
    setTitleAttribute() {
      setTimeout(function () {
        var first = document.querySelector(".sourceloadchildgrid .pi-angle-double-left");
        if (first != null)
          first.title = "First";
        var last = document.querySelector(".sourceloadchildgrid .pi-angle-double-right");
        if (last != null)
          last.title = "Last";
      }
        , 500);
    },
    setDefaultLazyParams(id) //common lazy params
    {
      let defaultLazyParams = {
        PortalId: this.getPortalID,
        LoadID: id,
        CustomerID: !this.sourceLoadData.CustomerID ? 0 : this.sourceLoadData.CustomerID,
        PickupDate: this.sourceLoadData.PickupDate==(null||"")?new Date():this.sourceLoadData.PickupDate,
        DropDate: this.sourceLoadData.DropDate==(null||"")?new Date():this.sourceLoadData.DropDate,
        first: 10,
        page: 0,
        rows: 10,
        sortOrder: 1,
        sortField: "",
        CarrierSmartSearch:{
          isCarrierSmartSearch:this.isCarrierSmartSearch,
          isGroup: this.GroupTypeRadio=='Group'?true:false,
          equipmentTypeList:[],
          groupList:[],
          weight:0,
          miles:'',
          size:''
        },
        pageCount: 0,
        multiSortMeta: {},
      };
      return defaultLazyParams;
    },
    setAllTabsDefaultLazyParams(id) {
      /*lazy params section start*/
      this.lazyParams = this.setDefaultLazyParams(id);
      this.lazyParams.filters=this.filters;
      /*lazy params section end*/

      /*Carrier tab params section start*/
      this.lazyParamsForCarrier = this.setDefaultLazyParams(id);
      this.lazyParamsForCarrier.filters=this.carrierFilters;

      this.carrierFiltered = {
        CarrierMCNum: "",
        CarrierName: "",
        DOTNumber: "",
        LoadType: 1,
        USDOTNumber: "",
      };
      /*Carrier tab params section end*/

       /*Favorite tab params section start*/
      this.lazyParamsFav = this.setDefaultLazyParams(id);
      this.lazyParamsFav.filters=this.filtersFav;

      this.carrierFavFiltered = {
        CarrierMCNumFave: "",
        CarrierNameFave: "",
        DOTNumberFave: "",
        LoadTypeFave: 1,
        USDOTNumberFave: "",
      };
      /*Favorite tab params section end*/

       /* History section start */
       this.lazyParamsForHistory = this.setDefaultLazyParams(id);
      this.lazyParamsForHistory.filters=this.carrierHistoryFilters;

      this.historyFiltered = {
        PortalId: this.getPortalID,
        CustomerID: this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID,
        ShowAll: "All",
        Months: 12,
        Radius: 100,
        SelectedHistorySwitch: "Headhaul"
      };
      /* History section end */

      /* Delivering section start */
      this.deliveringFiltered = {
        Radius: 100,
        Date: ""
      };
      /* Delivering section end */

       /* Loadboards Tab section start */
       this.lazyParamsForCarrierLoadBoards = this.setDefaultLazyParams(id);
      this.lazyParamsForHistory.filters=this.carrierLoadBoardsFilters;

      this.loadboardsFiltered = {
        Age: 60,
        Radius: 100,
        Expedited: false,
        Hazardous: false,
        PalletExchange: false,
        Tarps: false,
        Teams: false
      };
      /* Loadboards Tab section end */

       /* Headquarters Tab section start */
      this.lazyParamsForCarrierHeadquarters = this.setDefaultLazyParams(id);
      this.lazyParamsForHistory.filters=this.carrierHeadquartersFilters;

      this.headquarterFiltered = {
        Radius: 100,
        SelectedHeadquarterSwitch: "Headhaul"
      };
      /* Headquarters Tab section end */
      
     
      // this.lazyParamsForCarrierLoadBoards = {
      //   PortalId: this.getPortalID,
      //   LoadID: id,
      //   CustomerID: 0,
      //   PickupDate: "",
      //   DropDate: "",
      //   PickupCity: "",
      //   PickupState: "",
      //   DestinationCity: "",
      //   DestinationState: "",
      //   EquipmentID: "",
      //   first: 10,
      //   page: 0,
      //   // rows: this.$refs.dt.rows,
      //   rows: 10,
      //   sortOrder: 1,
      //   sortField: "",
      //   filters: this.carrierLoadBoardsFilters,
      //   pageCount: 0,
      //   multiSortMeta: {},
      // };
      
    },
    clearSearchMC() {
      this.selectedMC = "";
    },
    clearSearchDot() {
      this.selectedDot = "";
    },
    clearSearchUSDot() {
      this.selectedUSDot = "";
    },
    clearSearchCarrier() {
      this.selectedCarrier = "";
    },
    clearSearchFavMC() {
      this.selectedMCFav = "";
    },
    clearSearchFavDot() {
      this.selectedDotFav = "";
    },
    clearSearchFavUSDot() {
      this.selectedUSDotFav = "";
    },
    clearSearchFavCarrier() {
      this.selectedCarrierFav = "";
    },
    clearVselectCarrier() {
      if (this.selectedCarrier == "" || this.selectedCarrier == null)
        this.carrierName = "";
    },
    clearVselectCarrierFav() {
      if (this.selectedCarrierFav == "" || this.selectedCarrierFav == null)
        this.carrierNameFav = "";
    },
    async openSourceLoad(id) {

      this.loadID = id;
      this.sourceloadpopup = true;
      this.$nextTick(function () {
        this.$refs.MCSelectCarrier.$el.focus();
      });
      this.deliveringdate = "";
      this.selectedRadius = 100;
      this.selectedLoadBoardRadius = 100;
      this.selectedMC = "";
      this.selectedMCFav = "";
      this.selectedDot = "";
      (this.selectedDotFav = ""), (this.selectedUSDot = "");
      (this.selectedUSDotFav = ""),
        (this.selectedCarrier = ""),
        (this.selectedCarrierFav = ""),
        (this.expedited = false),
        (this.hazardous = false),
        (this.pallet = false),
        (this.tarps = false),
        (this.teams = false),
        (this.activeIndex = 0);

      this.customers = [];
      this.customersFav = [];
      this.carrierDelivery = [];
      this.carrierHistory = [];
      this.carrierLoadBoard = [];
      this.carrierHeadquarters = [];
      this.isShowGrid = true;
      this.isDataNotFound = false;
      this.isFavoriteDataNotFound = false;
      this.isShowCarrierGrid = true;
      this.isShowHistoryGrid = true;
      this.isShowLoadBoardGrid = true;
      this.loadID = id;
      this.selectedCarrier = "";
      this.selectedCarrierFav = "";
      this.selectDisabled = true;
      this.marketRate = "";
      this.marketCost = "";
      this.portalRate = "";
      this.portalCost = "";
      this.ldiRate = "";
      this.ldiCost = "";
      this.portalMargin = "";
      this.ldiMargin = "";
      this.increasedLdiCost = false;
      this.increasedLdiRate = false;
      this.increasedMarketCost = false;
      this.increasedMarketRate = false;
      this.increasedPortalRate = false;
      this.increasedPortalCost = false;
      this.increasedPortalMargin = false;
      this.increasedLdiMargin = false;
      this.isMaxWeight = false;
      this.isSizeError = false

      await dataProvider.getSourceLoadData(id).then((res) => {
        if (res) {
          this.sourceLoadType = res.LoadType;
          this.sourceLoadId = id;
          this.sourceLoadData = res;
          this.isCustomerFlag = this.sourceLoadData.CustomerID ? false : true;
          // this.historyRadio = this.sourceLoadData.CustomerID
          //   ? "Customer"
          //   : "All";
          this.LoadStatus = res.LoadStatus;
          this.callGetSourceLoadRateCost(
            this.sourceLoadData.PickupCity,
            this.sourceLoadData.PickupState,
            this.sourceLoadData.DestinationCity,
            this.sourceLoadData.DestinationState,
            this.sourceLoadData.EquipTypeID
          );
          this.uspsCarriersOnly = res.USPSCarriersOnly === true;
          this.deliveringdate = "";
          this.sourceLoadData.PickupDate = this.customerService.DateToYYMMDD(
            new Date(this.sourceLoadData.PickupDate)
          );

          [this.sourceLoadData.PickupStartTime, this.sourceLoadData.PickupEndTime] = parseLdiStartAndEndTime(this.sourceLoadData.PickupStartAndEndTime);

          this.setAllTabsDefaultLazyParams(id);
        }
      });
    },

    async openSourceLoadfromProvider(obj, fromProviderFlag) {
      console.log("openSourceLoadfromProvider", obj);
      this.fromBookEdit = fromProviderFlag;
      this.sourceloadpopup = true;
       this.$nextTick(function () {
                  this.$refs.MCSelectCarrier.$el.focus();
              });
      this.deliveringdate = "";
      this.selectedRadius = 100;
      this.selectedLoadBoardRadius = 100;
      this.selectedMC = "";
      this.selectedMCFav = "";
      this.selectedDot = "";
      (this.selectedDotFav = ""), (this.selectedUSDot = "");
      (this.selectedUSDotFav = ""),
        (this.selectedCarrier = ""),
        (this.selectedCarrierFav = ""),
        (this.expedited = false),
        (this.hazardous = false),
        (this.pallet = false),
        (this.tarps = false),
        (this.teams = false),
        (this.activeIndex = 0);

      this.customers = [];
      this.customersFav = [];
      this.carrierDelivery = [];
      this.carrierHistory = [];
      this.carrierLoadBoard = [];
      this.carrierHeadquarters = [];
      this.isShowGrid = true;
      this.isDataNotFound = false;
      this.isFavoriteDataNotFound = false;
      this.isShowCarrierGrid = true;
      this.isShowHistoryGrid = true;
      this.isShowLoadBoardGrid = true;
      this.selectedCarrier = "";
      this.selectedCarrierFav = "";
      this.selectDisabled = true;
      this.marketRate = "";
      this.marketCost = "";
      this.portalRate = "";
      this.portalCost = "";
      this.ldiRate = "";
      this.ldiCost = "";
      this.portalMargin = "";
      this.ldiMargin = "";
      this.increasedLdiCost = false;
      this.increasedLdiRate = false;
      this.increasedMarketCost = false;
      this.increasedMarketRate = false;
      this.increasedPortalRate = false;
      this.increasedPortalCost = false;
      this.increasedPortalMargin = false;
      this.increasedLdiMargin = false;
      this.uspsCarriersOnly = false;

      if (obj) {
        this.loadID = obj.LoadID;
        this.sourceLoadType = obj.LoadType;
        this.sourceLoadData = obj;
        this.LoadStatus = obj.LoadStatus;
        this.uspsCarriersOnly = obj.USPSCarriersOnly === true;
        this.callGetSourceLoadRateCost(
          this.sourceLoadData.PickupCity,
          this.sourceLoadData.PickupState,
          this.sourceLoadData.DestinationCity,
          this.sourceLoadData.DestinationState,
          this.sourceLoadData.EquipTypeID
        );
        this.deliveringdate = "";
        if (obj.LoadID != 0) {
          this.sourceLoadData.PickupDate = this.customerService.DateToYYMMDD(
            new Date(this.sourceLoadData.PickupDate)
          );

          this.sourceLoadData.DropDate = this.customerService.DateToYYMMDD(
            new Date(this.sourceLoadData.DropDate)
          );
        }
        
        [this.sourceLoadData.PickupStartTime, this.sourceLoadData.PickupEndTime] = parseLdiStartAndEndTime(this.sourceLoadData.PickupStartAndEndTime);

        this.setAllTabsDefaultLazyParams(0);
      }
    },

    async openSourceLoadfromMenu() {
      this.isCarrierSmartSearch=true;
      this.sourceloadpopup = true;
      // Default History Tab 
      this.activeTab="history";
      this.sourceLoadData.PartialLoad = this.isCarrierSmartSearch ? true : false;
      this.sourceLoadData.EquipmentTypeIDList = [1];
      this.setAllTabsDefaultLazyParams(0);
      this.activeIndex=0;
      this.carrierHistoryOnSearch('tab');
    },

    validateCheckboxes(changedCheckbox) {
                    if (this.isCarrierSmartSearch && !this.sourceLoadData.PartialLoad && !this.sourceLoadData.FTLLoad) {
                        this.sourceLoadData[changedCheckbox] = true;
                    }
                },

    async callGetSourceLoadRateCost(
      pickupcity,
      pickupstate,
      destinationcity,
      destinationstate,
      equipementid
    ) {
      await dataProvider
        .getSourceLoadRateCost(
          pickupcity,
          pickupstate,
          destinationcity,
          destinationstate,
          equipementid
        )
        .then((res) => {
          const format = v => v == null ? 'N/A' : parseFloat(v).toFixed(2);

          this.marketRate = format(res.MarketRateCost.RatePerMile);
          this.marketCost = format(res.MarketRateCost.LaneCost);
          if (res.PortalRateCost !== null) {
            this.portalRate = format(res.PortalRateCost.RatePerMile);
            this.portalCost = format(res.PortalRateCost.LaneCost);
            this.portalMargin = format(res.PortalRateCost.Margin);
          } else {
            this.portalRate = this.portalCost = this.portalMargin = null;
          }
          if(res.LDIRateCost !== null) {
            this.ldiRate = format(res.LDIRateCost.RatePerMile);
            this.ldiCost = format(res.LDIRateCost.LaneCost);
            this.ldiMargin = format(res.LDIRateCost.Margin);
          } else {
            this.ldiRate = this.ldiCost = this.ldiMargin = null;
          }
          this.increasedLdiCost = true;
          this.increasedLdiMargin = true;
          this.increasedLdiRate = true;
          this.increasedMarketCost = true;
          this.increasedMarketRate = true;
          this.increasedPortalMargin = true;
          this.increasedPortalRate = true;
          this.increasedPortalCost = true;
        });
    },
    concatCarrierMCNumber(Name, Num) {
      this.carrierNameMcNum = Num ? Name + " - MC" + Num : Name;
    },
    onRadiusChange() {
      if (this.months < 0) this.months = -this.months;
    },
    validateMonths() {
      if (this.months < 0) this.months = -this.months;
      if (this.months > 24) this.months = 24;
      if(this.isCarrierSmartSearch && this.months > 12) this.months = 12;
    },
    postClose() {
      this.sourceloadpopup = false;
    },
    close(closetext) {
        this.popupclosetext = closetext;
        this.carrierDelivery == [];
        this.carrierHistory == [];
        this.carrierLoadBoard == [];
  
        this.cancelDialog = true;
    },
    closeCancelDialog() {
      document.body.classList.add("scroll-hidden");
      this.cancelDialog = false;
    },
    closeSureDialog(action) {
      document.body.classList.add("scroll-hidden");
      if (action == "ok") {
        this.checkCarrierConditions();
        this.sureDialog = false;
      } else {
        this.sureDialog = false;
      }
    },
    closeSureNoDialog() {
      document.body.classList.add("scroll-hidden");
      this.sureNoDialog = false;
    },
    closeSelectAlertDialog() {
      this.selectAlert = false;
    },
    closePopups() {
      this.isDataAvailable = false;
      document.body.classList.remove("scroll-hidden");
      this.sourceloadpopup = false;
      this.cancelDialog = false;
      this.historyRadio = "All";
      this.historyWRKData = [];
      this.deliveryWRKData = [];
      this.loadboardWRKData = [];
      this.carrierDelivery = [];
      this.carrierHistory = [];
      this.carrierLoadBoard = [];
      this.carrierHeadquarters = [];
      this.defaultValuesToControls();
      this.emptySelection();
      this.sourceLoadData={
        AssetId: "",
        CustomerID: 0,
        DestinationCity: "",
        DestinationState: "",
        DropDate: "",
        EquipMinSize: "",
        EquipTypeID: 0,
        EquipmentTypeIDList:[],
        EquipmentType: "",
        LoadStatus: "",
        LoadType: 0,
        Mileage: "0",
        NetworkLoad: false,
        PartialLoad: false,
        PickupCity:"",
        PickupDate: "",
        PickupEndTime: "",
        PickupStartAndEndTime: "",
        PickupStartTime: "",
        PickupState:"",
        TeamService: false,
        FTLLoad:true,
        TruckstopLoadID: null,
        USPSCarriersOnly: false,
        Weight: "",
        lockedByID:null
      };

      if(this.isCarrierSmartSearch)
      {
        this.activeTab="history";
        this.Vans=0;
        this.Specialized=0;
        this.Reefers=0;
        this.Flatbeds=0;
      }

    },
    defaultValuesToControls() {
      this.selectedRadiusHistory = 100;
      this.selectedRadius = 100;
      this.selectedLoadBoardRadius = 100;
      this.selectedRadiusHeadquarters = 100;
      this.months = 12;
      this.selectedAge = 60;
      this.hbselectedSwitch = "Headhaul";
    },
    onRowSelect() {
      this.selectAll =
        this.selectedCustomers.length === this.totalCarrierDomicile;
    },
    onRowUnselect() {
      this.selectAll = false;
    },

    onCarrierDeilveryRowSelect() {
      this.selectAllCarrier =
        this.selectedCarrierDelivery.length ===
        this.totalRecordsCarrierDomicile;
    },
    onCarrierDeilveryRowUnselect() {
      this.selectAllCarrier = false;
    },
    onCarrierHistoryRowSelect() {
      this.selectAllCarrierHistory =
        this.selectedCarrierHistory.length ===
        this.totalRecordsHistory;
    },
    onCarrierHistoryRowUnSelect() {
      this.selectAllCarrierHistory = false;
    },
    onCarrierLoadBoardRowSelect() {
      this.selectAllLoadBoards =
        this.selectedLoadBoards.length ===
        this.totalRecordsLoadBoards;
    },
    onCarrierLoadBoardRowUnSelect() {
      this.selectAllLoadBoards = false;
    },


    onRowSelectFav() {
      this.selectAllFav =
        this.selectedCustomersFav.length === this.totalRecordsFav;
    },
    onRowUnselectFav() {
      this.selectAllFav = false;
    },
    loadLazyDataPageLoad() {
      this.customerServices();
    },
    onPage(event) {
      this.lazyParams = {...this.lazyParams,...event};
      this.lazyParams.PortalId = this.getPortalID;
      this.lazyParams.LoadID = this.loadID;
      this.lazyParams.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParams.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParams.DropDate = this.sourceLoadData.DropDate;
      this.customerServicesOnSearch("pagination");
    },
    onPageFav(event) {
      this.lazyParamsFav = {...this.lazyParamsFav,...event};
      this.lazyParamsFav.PortalId = this.getPortalID;
      this.lazyParamsFav.LoadID = this.loadID;
      this.lazyParamsFav.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsFav.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsFav.DropDate = this.sourceLoadData.DropDate;
      this.customerServicesOnSearchFav("pagination");
    },
    onPageLoadBoard(event) {
      this.lazyParamsForCarrierLoadBoards = {...this.lazyParamsForCarrierLoadBoards,...event};
      this.lazyParamsForCarrierLoadBoards.PortalId = this.getPortalID;
      this.lazyParamsForCarrierLoadBoards.LoadID = this.loadID;
      this.lazyParamsForCarrierLoadBoards.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrierLoadBoards.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrierLoadBoards.DropDate = this.sourceLoadData.DropDate;
      this.carrierLoadBoardOnSearch("pagination");
    },
    onLoadBoardSort(event) {
      this.lazyParamsForCarrierLoadBoards = {...this.lazyParamsForCarrierLoadBoards,...event};
      this.lazyParamsForCarrierLoadBoards.PortalId = this.getPortalID;
      this.lazyParamsForCarrierLoadBoards.LoadID = this.loadID;
      this.lazyParamsForCarrierLoadBoards.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrierLoadBoards.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrierLoadBoards.DropDate = this.sourceLoadData.DropDate;
      this.carrierLoadBoardOnSearch("sort");
    },
    onPageLoad(event) {
      this.lazyParamsForCarrier = {...this.lazyParamsForCarrier,...event};
      this.lazyParamsForCarrier.PortalId = this.getPortalID;
      this.lazyParamsForCarrier.LoadID = this.loadID;
      this.lazyParamsForCarrier.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrier.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrier.DropDate = this.sourceLoadData.DropDate;
      this.carrierDeliveryOnSearch("pagination");
    },
    onSort(event) {
      this.lazyParams = {...this.lazyParams,...event};
      this.lazyParams.PortalId = this.getPortalID;
      this.lazyParams.LoadID = this.loadID;
      this.lazyParams.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParams.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParams.DropDate = this.sourceLoadData.DropDate;
      this.customerServicesOnSearch("sort");
    },
    onSortFav(event) {
      this.lazyParamsFav = {...this.lazyParamsFav,...event};
      this.lazyParamsFav.PortalId = this.getPortalID;
      this.lazyParamsFav.LoadID = this.loadID;
      this.lazyParamsFav.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsFav.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsFav.DropDate = this.sourceLoadData.DropDate;
      this.customerServicesOnSearchFav("sort");
    },
    onFilterFav() {
      this.lazyParamsFav.filters = this.filtersFav;
      this.customerServicesOnSearchFav();
    },
    onDeliveringSort(event) {
      this.lazyParamsForCarrier = {...this.lazyParamsForCarrier,...event};
      this.lazyParamsForCarrier.PortalId = this.getPortalID;
      this.lazyParamsForCarrier.LoadID = this.loadID;
      this.lazyParamsForCarrier.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrier.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrier.DropDate = this.sourceLoadData.DropDate;
      this.carrierDeliveryOnSearch("sort");
    },

    onPageLoadCarrierHeadquaters(event) {

      this.lazyParamsForCarrierHeadquarters = {...this.lazyParamsForCarrierHeadquarters,...event};
      this.lazyParamsForCarrierHeadquarters.PortalId = this.getPortalID;
      this.lazyParamsForCarrierHeadquarters.LoadID = this.loadID;
      this.lazyParamsForCarrierHeadquarters.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrierHeadquarters.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrierHeadquarters.DropDate = this.sourceLoadData.DropDate;
      this.carrierHeadquartersOnSearch("pagination");
    },

    onCarrierHeadquatersSort(event) {

      this.lazyParamsForCarrierHeadquarters = {...this.lazyParamsForCarrierHeadquarters,...event};
      this.lazyParamsForCarrierHeadquarters.PortalId = this.getPortalID;
      this.lazyParamsForCarrierHeadquarters.LoadID = this.loadID;
      this.lazyParamsForCarrierHeadquarters.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForCarrierHeadquarters.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrierHeadquarters.DropDate = this.sourceLoadData.DropDate;
      this.carrierHeadquartersOnSearch("sort");
    },
    onCarrierHeadquartersRowSelect() {
      this.selectAllCarrierHeadquarters =
        this.selectedCarrierHeadquarters.length ===
        this.totalRecordsCarrierHeadquarters;
    },
    onCarrierHeadquartersRowUnSelect() {
      this.selectAllCarrierHeadquarters = false;
    },
    onCarrierHeadquartersSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.customerService
          .GetMySourceLoadCarrierHeadquarter({ ...this.lazyParams, USPSCarriersOnly: this.uspsCarriersOnly })
          .then((data) => {
            this.selectAllCarrierHeadquarters = true;
            this.carrierHeadquarters = data.carrierHeadquarterData;
          });
      } else {
        this.selectAllCarrierHeadquarters = false;
        this.carrierHeadquarters = [];
      }
    },
    onHistoryPageLoad(event) {
      
      this.lazyParamsForHistory = {...this.lazyParamsForHistory,...event};
      this.lazyParamsForHistory.PortalId = this.getPortalID;
      this.lazyParamsForHistory.LoadID = this.loadID;
      this.lazyParamsForHistory.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForHistory.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForHistory.DropDate = this.sourceLoadData.DropDate;
      this.lazyParamsForHistory.EquipmentTypeID = this.sourceLoadData.EquipTypeID;
      this.carrierHistoryOnSearch("pagination");
    },
    onSortHistory(event) {
      this.lazyParamsForHistory = {...this.lazyParamsForHistory,...event};
      this.lazyParamsForHistory.PortalId = this.getPortalID;
      this.lazyParamsForHistory.LoadID = this.loadID;
      this.lazyParamsForHistory.CustomerID = this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID;
      this.lazyParamsForHistory.PickupDate = this.sourceLoadData.PickupDate;
      this.lazyParamsForHistory.DropDate = this.sourceLoadData.DropDate;
      this.lazyParamsForHistory.EquipmentTypeID = this.sourceLoadData.EquipTypeID;
      this.carrierHistoryOnSearch("sort");
    },

    customerServices() {
      let filteredData = {
        CarrierName: {
          value: "",
          MatchMode: "contains",
        },
        CarrierMCNum: {
          value: 0,
          MatchMode: "contains",
        },
        DOTNumber: {
          value: 0,
          MatchMode: "contains",
        },
        USDOTNumber: {
          value: 0,
          MatchMode: "contains",
        },
        LoadType: { value: this.sourceLoadType, MatchMode: "contains" },
      };

      this.lazyParams.filters = filteredData;
      this.lazyParams.LoadID = this.loadID;
      this.customerService
        .GetMySourceLoads({
          ...this.lazyParams,
          USPSCarriersOnly: this.uspsCarriersOnly
        }).then((data) => {
          this.customers = data.carrierDomicileData;
          this.totalCarrierDomicile = data.TotalCount;
          this.loading = false;
          this.selectDisabled = false;
          this.filters = {
            CarrierName: { value: "", MatchMode: "contains" },
            CarrierMCNum: { value: 0, MatchMode: "contains" },
            DOTNumber: { value: 0, MatchMode: "contains" },
            USDOTNumber: { value: 0, MatchMode: "contains" },
            LoadType: { value: this.sourceLoadType, MatchMode: "contains" },
          };
        });
    },
    // Added for persist the Carrier fiters
    setCarrierFilter() {
      this.carrierFiltered.CarrierName = this.selectedCarrier;
      this.carrierFiltered.CarrierMCNum = this.selectedMC;
      this.carrierFiltered.DOTNumber = this.selectedDot;
      this.carrierFiltered.USDOTNumber = this.selectedUSDot;
      this.carrierFiltered.LoadType = this.sourceLoadType;
    },
    persistCarrierFilter() {
      this.selectedCarrier = this.carrierFiltered.CarrierName;
      this.selectedMC = this.carrierFiltered.CarrierMCNum;
      this.selectedDot = this.carrierFiltered.DOTNumber;
      this.selectedUSDot = this.carrierFiltered.USDOTNumber;
      this.sourceLoadType = this.carrierFiltered.LoadType;
    },
    customerServicesOnSearch(clickEventName) {
      if (clickEventName == "search") {
        this.setCarrierFilter();
        if (this.$refs.sdt != null) {
          if (this.$refs.sdt.selection) {
            this.$refs.sdt.selection.length = 0;
          }
        }
      }
      this.persistCarrierFilter();
      this.isDataAvailable = false;
      this.loading = true;
      if (
        this.customers &&
        this.customers.length != 0 &&
        clickEventName != "search" &&
        clickEventName != "sort" &&
        clickEventName != "pagination"
      ) {
        this.isDataAvailable = false;
        this.loading = false;
        setTimeout(() => {
          return;
        }, 0);
      }
      let filteredData = {
        CarrierName: {
          value: this.selectedCarrier,
          MatchMode: "contains",
        },
        CarrierMCNum: {
          value: this.selectedMC,
          MatchMode: "contains",
        },
        DOTNumber: {
          value: this.selectedDot,
          MatchMode: "contains",
        },
        USDOTNumber: {
          value: this.selectedUSDot,
          MatchMode: "contains",
        },
        LoadType: { value: this.sourceLoadData.LoadType, MatchMode: "contains" },
      };
      if (clickEventName == 'search')
        this.lazyParams.page = 0
      this.lazyParams.filters = filteredData;
      this.tabEnableDisabled('carrier', true);
      this.customerService
        .GetMySourceLoads({
          ...this.lazyParams,
          USPSCarriersOnly: this.uspsCarriersOnly
        }).then((data) => {
          this.customers = data.carrierDomicileData;
          if (this.customers.length == 0) {
            this.isDataNotFound = true;
            this.isDataAvailable = false;
          } else {
            this.isDataNotFound = false;
            this.isDataAvailable = true;
          }
          this.customers.forEach((element) => {
            if (element.IsLoad == 1) {
              element.IsLoad = "Y";
            } else {
              element.IsLoad = "N";
            }
            if (element.ELDEnabled == 0) {
              element.ELDEnabled = "No";
            } else {
              element.ELDEnabled = "Yes";
            }
          });
          this.loading = false;
          this.totalCarrierDomicile = data.TotalCount;
          this.isShowGrid = false;
          this.selectDisabled = false;
          this.tabEnableDisabled('carrier', false);
          this.setTitleAttribute();
        });
    },
    setCarrierFavFilter() {
      this.carrierFavFiltered.CarrierNameFave = this.selectedCarrierFav;
      this.carrierFavFiltered.CarrierMCNumFave = this.selectedMCFav;
      this.carrierFavFiltered.DOTNumberFave = this.selectedDotFav;
      this.carrierFavFiltered.USDOTNumberFave = this.selectedUSDotFav;
      this.carrierFavFiltered.LoadTypeFave = this.sourceLoadType;
    },
    persistCarrierFavFilter() {
      this.selectedCarrierFav = this.carrierFavFiltered.CarrierNameFave;
      this.selectedMCFav = this.carrierFavFiltered.CarrierMCNumFave;
      this.selectedDotFav = this.carrierFavFiltered.DOTNumberFave;
      this.selectedUSDotFav = this.carrierFavFiltered.USDOTNumberFave;
      this.sourceLoadType = this.carrierFavFiltered.LoadTypeFave;
    },
    customerServicesOnSearchFav(fromWhere) {
      if(fromWhere == "tab"){
        this.activeTab="isfavorite"
      }
      this.selectedCustomersFav = null;
      if (fromWhere == "search") {
        this.setCarrierFavFilter();
        if (this.$refs.fdt != null) {
          if (this.$refs.fdt.selection) {
            this.$refs.fdt.selection.length = 0;
          }
        }
      }
      this.persistCarrierFavFilter();
      this.loading = true;
      this.activeTab = "";
      this.activeTab = "isfavorite";
      if (this.customersFav && this.customersFav.length > 0) {
        this.isDataAvailable = false;
      } else {
        this.isDataAvailable = true;
      }
      let filteredData = {
        CarrierName: {
          value: this.selectedCarrierFav,
          MatchMode: "contains",
        },
        CarrierMCNum: {
          value: this.selectedMCFav,
          MatchMode: "contains",
        },
        DOTNumber: {
          value: this.selectedDotFav,
          MatchMode: "contains",
        },
        USDOTNumber: {
          value: this.selectedUSDotFav,
          MatchMode: "contains",
        },
        LoadType: { value: this.sourceLoadData.LoadType, MatchMode: "contains" },
      };
      if (fromWhere == 'search') {
        this.lazyParamsFav.page = 0
      }
      this.lazyParamsFav.filters = filteredData;
      this.tabEnableDisabled('favorite', true);
      this.customerService
        .GetMyFavoriteCarriers({
          ...this.lazyParamsFav,
          USPSCarriersOnly: this.uspsCarriersOnly
        }).then((data) => {
          this.customersFav = data.carrierDomicileData;
          if (this.customersFav.length == 0) {
            this.isFavoriteDataNotFound = true;
            this.isDataAvailable = false;
          } else {
            this.isFavoriteDataNotFound = false;
            this.isDataAvailable = true;
          }
          this.customersFav.forEach((element) => {
            if (element.IsLoad == 1) {
              element.IsLoad = "Y";
            } else {
              element.IsLoad = "N";
            }
            if (element.ELDEnabled == 0) {
              element.ELDEnabled = "No";
            } else {
              element.ELDEnabled = "Yes";
            }
          });
          this.loading = false;
          this.totalRecordsFav = data.TotalCount;
          this.isShowGrid = false;
          this.selectDisabled = false;
          this.tabEnableDisabled('favorite', false);
          this.setTitleAttribute();
        });
    },
    // Added for persist the delivering fiters
    setDeliveringFilter() {
      this.deliveringFiltered.Radius = this.selectedRadius;
      this.deliveringFiltered.Date = this.deliveringdate;
    },
    persistDeliveringFilter() {
      this.selectedRadius = this.deliveringFiltered?.Radius;
      this.deliveringdate = this.deliveringFiltered?.Date;
    },
    closeSaveLoadConfirmationpopupdialog() {
      this.saveLoadConfirmationpopupdialog = false;
    },
    carrierDeliveryOnSearch(requestFrom) {
      this.loading = true;
      if(requestFrom == "tab"){
        this.activeTab="carrierdelivery"
      }
      if (!this.sourceLoadData.PickupState || (this.isCarrierSmartSearch && (this.deliveringFiltered == null || this.lazyParamsForCarrier ==null))) {
            this.carrierDelivery = [];
            this.isShowCarrierGrid = false;
            this.isDataAvailable = false;
            this.searchMessage = "No search criteria applied.";
            this.loading = false;
          return;
          } 

      if (requestFrom == "search") {
        this.setDeliveringFilter();
        if (this.$refs.cdt != null) {
          if (this.$refs.cdt.selection) {
            this.$refs.cdt.selection.length = 0;
          }
        }
      }
      if(this.isCarrierSmartSearch && requestFrom=="tab"){
        this.setDeliveringFilter();
      }
      this.persistDeliveringFilter();
      this.isDataAvailable = false;
      this.activeTab = "";
      this.activeTab = "carrierdelivery";
      if (this.carrierDelivery && this.carrierDelivery.length > 0) {
        this.isDataAvailable = true;
      } else {
        this.isDataAvailable = false;
      }
      if (
        this.carrierDelivery &&
        this.carrierDelivery.length > 0 &&
        requestFrom == "tab"
      ) {
        this.selectedCarrierDelivery = null;
        this.loading = false;
        this.isDataAvailable = true;
        this.carrierDelivery = this.carrierDelivery.map((obj) => {
          let dataClone = JSON.parse(JSON.stringify(obj));
          if (dataClone != null) {
            delete dataClone.wrk;
          }
          if (this.deliveryWRKData != undefined && this.deliveryWRKData != null
            && this.checkContainsWRKObject(this.deliveryWRKData, dataClone))
            obj.wrk = true;
          else
            obj.wrk = false;

          return obj;
        });
        setTimeout(() => {
          return;
        }, 0);
      }
      if (this.deliveringdate != null && this.deliveringdate.length == undefined) {
        new Date(
          this.deliveringdate.setMinutes(
            this.deliveringdate.getTimezoneOffset() * -1
          )
        );
      }
      let filteredData = {
        PickupCity: {
          value: this.sourceLoadData.PickupCity?this.sourceLoadData.PickupCity:null,
          MatchMode: "contains",
        },
        PickupState: {
          value: this.sourceLoadData.PickupState?this.sourceLoadData.PickupState:null,
          MatchMode: "contains",
        },
        PickupDate: {
          value: this.deliveringdate == null || this.deliveringdate == ""? this.sourceLoadData.PickupDate : this.deliveringdate,
          MatchMode: "contains",
        },
        Radius: {
          value: this.selectedRadius,
          MatchMode: "contains",
        },
        LoadType: { value: this.getLoadType(), MatchMode: "contains" },
      };

      if(this.lazyParamsForCarrier)
      {
            this.lazyParamsForCarrier.filters = filteredData;
            this.lazyParamsForCarrier.EquipmentTypeID = this.sourceLoadData.EquipTypeID;
            
      this.lazyParamsForCarrier.CarrierSmartSearch.equipmentTypeList=this.sourceLoadData.EquipmentTypeIDList;
      this.lazyParamsForCarrier.CarrierSmartSearch.isGroup=this.GroupTypeRadio=='Group'?true:false;
      this.lazyParamsForCarrier.CarrierSmartSearch.groupList=this.consolidateGroupValues();
      this.lazyParamsForCarrier.CarrierSmartSearch.weight=!this.sourceLoadData.Weight ? "0" : ""+this.sourceLoadData.Weight;
      this.lazyParamsForCarrier.CarrierSmartSearch.size=!this.sourceLoadData.EquipMinSize ? "0" : ""+this.sourceLoadData.EquipMinSize;
      this.lazyParamsForCarrier.CarrierSmartSearch.miles=""+this.sourceLoadData.Mileage;
      this.lazyParamsForCarrier.PickupDate = !this.sourceLoadData.PickupDate?new Date(null):this.sourceLoadData.PickupDate; 
      this.lazyParamsForCarrier.DropDate = !this.sourceLoadData.DropDate?new Date(null):this.sourceLoadData.DropDate; 
      }

      this.tabEnableDisabled('delivering', true);
      this.customerService
        .GetMySourceLoadCarrierDelivery({
          ...this.lazyParamsForCarrier,
          USPSCarriersOnly: this.uspsCarriersOnly
        })
        .then((data) => {
          this.carrierDelivery = data.carrierDeliveryData;
          if (this.carrierDelivery && this.carrierDelivery.length > 0) {
            this.isDataAvailable = true;
          } else {
            this.isDataAvailable = false;
          }
          this.totalRecordsCarrierDomicile = data.TotalCount;
          this.selectDisabled = false;
          this.carrierDelivery.forEach((element) => {
            if (element.DateAvailable) {
              element.DateAvailable = this.customerService.DateToYYMMDD(
                new Date(element.DateAvailable)
              );
            }
            if (element.OriginRadius_DH) {
              element.OriginRadius_DH = Math.round(element.OriginRadius_DH);
            }
          });
          if (this.carrierDelivery.length == 0) {
            this.isShowCarrierGrid = false;
            this.isDataAvailable = false;
            this.searchMessage = "No records available.";
          } else {
            this.isShowCarrierGrid = true;
            this.isDataAvailable = true;
          }
          this.carrierDelivery.forEach((element) => {
            if (element.IsLoad == 1) {
              element.IsLoad = "Y";
            } else {
              element.IsLoad = "N";
            }
          });
          this.carrierDelivery = this.carrierDelivery.map((obj) => {
            let dataClone = JSON.parse(JSON.stringify(obj));
            if (dataClone != null) {
              delete dataClone.wrk;
            }
            if (this.deliveryWRKData != undefined && this.deliveryWRKData != null
              && this.checkContainsWRKObject(this.deliveryWRKData, dataClone))
              obj.wrk = true;
            else
              obj.wrk = false;

            return obj;
          });
          this.totalRecordsCarrierDomicile = data.TotalCount;
          this.loading = false;
          this.tabEnableDisabled('delivering', false);
          this.setTitleAttribute();
        });
    },
    // Added for persist the Loadboards fiters
    setLoadboardsFilter() {
      this.loadboardsFiltered.Age = this.selectedAge;
      this.loadboardsFiltered.Radius = this.selectedLoadBoardRadius;
      this.loadboardsFiltered.Expedited = this.expedited;
      this.loadboardsFiltered.Hazardous = this.hazardous;
      this.loadboardsFiltered.Tarps = this.tarps;
      this.loadboardsFiltered.PalletExchange = this.pallet;
      this.loadboardsFiltered.Team = this.teams;
      this.loadboardsFiltered.LoadType = this.sourceLoadType;
    },
    persistLoadboardsFilter() {
      this.selectedAge = this.loadboardsFiltered.Age;
      this.selectedLoadBoardRadius = this.loadboardsFiltered.Radius;
      this.expedited = this.loadboardsFiltered.Expedited;
      this.hazardous = this.loadboardsFiltered.Hazardous;
      this.tarps = this.loadboardsFiltered.Tarps;
      this.pallet = this.loadboardsFiltered.PalletExchange;
      this.teams = this.loadboardsFiltered.Team;
      this.sourceLoadType = this.getLoadType();
    },
    carrierLoadBoardOnSearch(f) {
      this.loading = true;
      if(f == "tab"){
        this.activeTab="loadboards"
      }
      // check if search filters are applied in carrier smart search
      if (!this.sourceLoadData.PickupState || (this.isCarrierSmartSearch && (this.loadboardsFiltered == null || this.lazyParamsForCarrierLoadBoards ==null))) {
            this.carrierLoadBoard = [];
            this.isShowLoadBoardGrid = false;
            this.isDataAvailable = false;
            this.selectDisabled = true;
            this.searchMessage = "No search criteria applied.";
            this.loading = false;
          return;
          } 

      if (f == "search") {
        this.setLoadboardsFilter();
        if (this.$refs.ldt != null) {
          if (this.$refs.ldt.selection) {
            this.$refs.ldt.selection.length = 0;
          }
        }
      }
      if(this.isCarrierSmartSearch && f=="tab"){
        this.setLoadboardsFilter();
      }
      this.persistLoadboardsFilter();
      this.isDataAvailable = false;
      this.selectDisabled = true;
      this.activeTab = "";
      this.activeTab = "loadboards";
      if (this.carrierLoadBoard && this.carrierLoadBoard.length > 0) {
        this.isDataAvailable = true;
      } else {
        this.isDataAvailable = false;
      }
      if (
        this.carrierLoadBoard &&
        this.carrierLoadBoard.length > 0 &&
        f == "tab"
      ) {
        this.selectedLoadBoards = null;
        this.loading = false;
        this.isDataAvailable = true;
        this.carrierLoadBoard = this.carrierLoadBoard.map((obj) => {
          let dataClone = JSON.parse(JSON.stringify(obj));
          if (dataClone != null) {
            delete dataClone.wrk;
          }
          if (this.loadboardWRKData != undefined && this.loadboardWRKData != null
            && this.checkContainsWRKObject(this.loadboardWRKData, dataClone))
            obj.wrk = true;
          else
            obj.wrk = false;

          return obj;
        });
        setTimeout(() => {
          return;
        }, 0);
      }
      let filteredData = {
        Expedited: { value: this.expedited, MatchMode: "contains" },
        Hazardous: { value: this.hazardous, MatchMode: "contains" },
        PalletExchange: { value: this.pallet, MatchMode: "contains" },
        Tarps: { value: this.tarps, MatchMode: "contains" },
        Team: { value: this.teams, MatchMode: "contains" },
        Age: { value: this.selectedAge, MatchMode: "contains" },
        Radius: { value: this.selectedLoadBoardRadius, MatchMode: "contains" },
        LoadType: { value: this.getLoadType(), MatchMode: "contains" },
      };

      (this.lazyParamsForCarrierLoadBoards.CustomerID =
        this.sourceLoadData.CustomerID == null
          ? 0
          : this.sourceLoadData.CustomerID),
        (this.lazyParamsForCarrierLoadBoards.PickupDate = this.sourceLoadData.PickupDate == (null || "") ? new Date() : this.sourceLoadData.PickupDate);
        (this.lazyParamsForCarrierLoadBoards.DropDate = this.sourceLoadData.DropDate == (null || "") ? new Date() : this.sourceLoadData.DropDate);
        (this.lazyParamsForCarrierLoadBoards.EquipmentID =
          this.sourceLoadData.EquipTypeID);
          
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.equipmentTypeList=this.sourceLoadData.EquipmentTypeIDList;
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.isGroup=this.GroupTypeRadio=='Group'?true:false;
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.groupList=this.consolidateGroupValues();
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.weight=!this.sourceLoadData.Weight ? "0" : ""+this.sourceLoadData.Weight;
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.size=!this.sourceLoadData.EquipMinSize ? "0" : ""+this.sourceLoadData.EquipMinSize;
      this.lazyParamsForCarrierLoadBoards.CarrierSmartSearch.miles=""+this.sourceLoadData.Mileage;
      this.lazyParamsForCarrierLoadBoards.filters = filteredData;
      this.lazyParamsForCarrierLoadBoards.PickupCity =
        this.sourceLoadData.PickupCity?this.sourceLoadData.PickupCity:'';
      this.lazyParamsForCarrierLoadBoards.PickupState =
        this.sourceLoadData.PickupState?this.sourceLoadData.PickupState:'';
      this.lazyParamsForCarrierLoadBoards.DestinationCity =
        this.sourceLoadData.DestinationCity?this.sourceLoadData.DestinationCity:'';
      this.lazyParamsForCarrierLoadBoards.DestinationState =
        this.sourceLoadData.DestinationState?this.sourceLoadData.DestinationState:'';

      this.tabEnableDisabled('loadboard', true);
      this.customerService
        .GetMySourceLoadCarrierLoadBoard({
          ...this.lazyParamsForCarrierLoadBoards,
          USPSCarriersOnly: this.uspsCarriersOnly
        })
        .then((data) => {
          this.carrierLoadBoard = data.carrierLoadBoardData;
          if (this.carrierLoadBoard && this.carrierLoadBoard.length > 0) {
            this.isDataAvailable = true;
          } else {
            this.isDataAvailable = false;
          }
          this.totalRecordsLoadBoards = data.TotalCount;
          this.carrierLoadBoard.forEach((element) => {
            if (element.Available) {
              element.Available = this.customerService.DateToYYMMDD(
                new Date(element.Available)
              );
            }
            if (element.IsLoad == 1) {
              element.IsLoad = "Y";
            } else {
              element.IsLoad = "N";
            }

            if (element.DH) {
              element.DH = Math.round(element.DH);
            }

            if (element.CarrierSetup == 1) {
              element.CarrierSetup = "Y";
            } else {
              element.CarrierSetup = "N";
            }
          });
          if (this.carrierLoadBoard.length == 0) {
            this.isShowLoadBoardGrid = false;
            this.isDataAvailable = false;
            this.selectDisabled = true;
            this.searchMessage = "No records available.";
          } else {
            this.isShowLoadBoardGrid = true;
            this.isDataAvailable = true;
            this.selectDisabled = false;
          }
          this.carrierLoadBoard = this.carrierLoadBoard.map((obj) => {
            let dataClone = JSON.parse(JSON.stringify(obj));
            if (dataClone != null) {
              delete dataClone.wrk;
            }
            if (this.loadboardWRKData != undefined && this.loadboardWRKData != null
              && this.checkContainsWRKObject(this.loadboardWRKData, dataClone))
              obj.wrk = true;
            else
              obj.wrk = false;

            return obj;
          });
          this.totalRecordsLoadBoards = data.TotalCount;

          this.loading = false;
          this.tabEnableDisabled('loadboard', false);
          this.setTitleAttribute();
        });
    },
    // Added for persist the Headquarter fiters
    setHeadquarterFilter() {
      this.headquarterFiltered.Radius = this.selectedRadiusHeadquarters;
      this.headquarterFiltered.SelectedHeadquarterSwitch = this.hbselectedSwitch;
    },
    persistHeadquarterFilter() {
      this.selectedRadiusHeadquarters = this.headquarterFiltered.Radius;
      this.hbselectedSwitch = this.headquarterFiltered.SelectedHeadquarterSwitch;
    },

    carrierHeadquartersOnSearch(requestFrom) {
      this.loading = true;
      if(requestFrom == "tab"){
        this.activeTab="carrierheadquarters"
      }
      //check if search filters are applied in carrier smart search
      if (!this.sourceLoadData.PickupState || (this.isCarrierSmartSearch && (this.headquarterFiltered == null || this.lazyParamsForCarrierHeadquarters ==null))) {
            this.carrierHeadquarters = [];
            this.isShowHeadquartersGrid = false;
            this.isDataAvailable = false;
            this.selectDisabled = true;
            this.searchMessage = "No search criteria applied.";
            this.loading = false;
          return;
          } 

      if (requestFrom == "search") {
        this.setHeadquarterFilter();
        if (this.$refs.hdt != null) {
          if (this.$refs.hdt.selection) {
            this.$refs.hdt.selection.length = 0;
          }
        }
      }
      if(this.isCarrierSmartSearch && requestFrom=="tab"){
        this.setHeadquarterFilter(); // to set headQuarter Filters on Tab change
      }
      this.persistHeadquarterFilter();
      this.isDataAvailable = false;
      this.activeTab = "";
      this.activeTab = "carrierheadquarters";
      if (this.carrierHeadquarters && this.carrierHeadquarters.length > 0) {
        this.isDataAvailable = true;
        this.searchMessage = "No records available.";
      } else {
        this.isDataAvailable = false;
      }

      // if (this.$refs.hdt != null) {
      //   this.$refs.hdt.selection.length = 0;
      // }
      if (
        this.carrierHeadquarters &&
        this.carrierHeadquarters.length > 0 &&
        requestFrom == "tab"
      ) {
        this.selectedCarrierHeadquarters = null;
        this.loading = false;
        this.isDataAvailable = true;
        setTimeout(() => {
          return;
        }, 0);
      }
      let filteredData = {};
      if (this.hbselectedSwitch == "Headhaul") {
        this.DH = "DH";
        filteredData = {
          City: {
            value: this.sourceLoadData.PickupCity?this.sourceLoadData.PickupCity:'',
            MatchMode: "contains",
          },
          State: {
            value: this.sourceLoadData.PickupState?this.sourceLoadData.PickupState:'',
            MatchMode: "contains",
          },
          Radius: {
            value: this.selectedRadiusHeadquarters,
            MatchMode: "contains",
          },
          LoadType: { value: this.getLoadType(), MatchMode: "contains" },
        };
      } else {
        this.DH = "DH / D";
        filteredData = {
          City: {
            value: this.sourceLoadData.DestinationCity?this.sourceLoadData.DestinationCity:'',
            MatchMode: "contains",
          },
          State: {
            value: this.sourceLoadData.DestinationState?this.sourceLoadData.DestinationState:'',
            MatchMode: "contains",
          },
          Radius: {
            value: this.selectedRadiusHeadquarters,
            MatchMode: "contains",
          },
          LoadType: { value: this.getLoadType(), MatchMode: "contains" },
        };
      }

      if (this.lazyParamsForCarrierHeadquarters.CustomerID == null) {
        this.lazyParamsForCarrierHeadquarters.CustomerID = 0;
      }

      this.lazyParamsForCarrierHeadquarters.filters = filteredData;
      this.lazyParamsForCarrierHeadquarters.EquipmentTypeID =
        this.sourceLoadData.EquipTypeID; 
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.equipmentTypeList=this.sourceLoadData.EquipmentTypeIDList;
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.isGroup=this.GroupTypeRadio=='Group'?true:false;
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.groupList=this.consolidateGroupValues();
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.weight=!this.sourceLoadData.Weight ? "0" : ""+this.sourceLoadData.Weight;
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.size=!this.sourceLoadData.EquipMinSize ? "0" : ""+this.sourceLoadData.EquipMinSize;
      this.lazyParamsForCarrierHeadquarters.CarrierSmartSearch.miles=""+this.sourceLoadData.Mileage;
      this.lazyParamsForCarrierHeadquarters.PickupDate = !this.sourceLoadData.PickupDate?new Date(null):this.sourceLoadData.PickupDate;
      this.lazyParamsForCarrierHeadquarters.DropDate = !this.sourceLoadData.DropDate?new Date(null):this.sourceLoadData.DropDate; 

      this.loading = true;
      this.tabEnableDisabled('headquarter', true);

      this.customerService
        .GetMySourceLoadCarrierHeadquarter({
          ...this.lazyParamsForCarrierHeadquarters,
          USPSCarriersOnly: this.uspsCarriersOnly
        })
        .then((data) => {

          this.carrierHeadquarters = data.carrierHeadquarterData;

          if (this.carrierHeadquarters && this.carrierHeadquarters.length > 0) {
            this.isShowCarrierGrid = true;
            this.isDataAvailable = true;

            this.totalRecordsCarrierHeadquarters = data.TotalCount;
            this.selectDisabled = false;

            this.carrierHeadquarters.forEach((element) => {
              if (element.DateAvailable) {
                element.DateAvailable = this.customerService.DateToYYMMDD(
                  new Date(element.DateAvailable)
                );
              }

              if (element.IsLoad == 1) {
                element.IsLoad = "Y";
              } else {
                element.IsLoad = "N";
              }
            });
            this.carrierHeadquarters = this.carrierHeadquarters.map((obj) => {
              obj.wrk = false;
              return obj;
            });
            this.totalRecordsCarrierHeadquarters = data.TotalCount;
            this.loading = false;
            this.isShowHeadquartersGrid = true;
          }
          else {
            this.isShowHeadquartersGrid = false;
            this.isShowCarrierGrid = false;
            this.isDataAvailable = false;
            this.searchMessage = "No records available.";
            this.loading = false;
          }
          this.tabEnableDisabled('headquarter', false);
          this.setTitleAttribute();
        });
    },
    // Added for persist the history fiters
    setHistorryFilter() {
      this.historyFiltered.Radius = this.selectedRadiusHistory;
      this.historyFiltered.ShowAll = this.historyRadio;
      this.historyFiltered.Months = this.months;
    },
    persistHistoryFilter() {
      this.selectedRadiusHistory = this.historyFiltered.Radius;
      this.historyRadio = this.historyFiltered.ShowAll;
      this.months = this.historyFiltered.Months;
    },
    carrierHistoryOnSearch(requestFrom) {
      this.loading = true;
      if(requestFrom == "tab"){
        this.activeTab="history"
      }
      // check if search filters are applied in carrier smart search
      if (!this.sourceLoadData.PickupState || (this.isCarrierSmartSearch && (this.historyFiltered == null || this.lazyParamsForHistory ==null))) {
            this.carrierHistory = [];
            this.isShowHistoryGrid = false;
            this.isDataAvailable = false;
            this.selectDisabled = true;
            this.searchMessage = "No search criteria applied.";
            this.loading = false;
          return;
          } 
      
      if (requestFrom == "search") {
        this.setHistorryFilter();
        if (this.$refs?.hhdt?.selection != null) {
          this.$refs.hhdt.selection.length = 0;
        }
        //this.$refs.hhdt.selection.length = 0;
      }
      if(this.isCarrierSmartSearch && requestFrom=="tab"){
        this.setHistorryFilter();
      }
      this.persistHistoryFilter();
      this.isDataAvailable = false;
      this.activeTab = "";
      this.activeTab = "history";
      if (this.carrierHistory && this.carrierHistory.length > 0) {
        this.isDataAvailable = false;
      } else {
        this.isDataAvailable = true;
      }
      // if (this.$refs?.hhdt?.selection != null) {
      //   this.$refs.hhdt.selection.length = 0;
      // }
      if (
        this.carrierHistory &&
        this.carrierHistory.length > 0 &&
        requestFrom == "tab"
      ) {
        this.selectedCarrierHistory = null;
        this.loading = false;
        this.isDataAvailable = false;
        this.carrierHistory.map((obj) => {
          let dataClone = JSON.parse(JSON.stringify(obj));
          if (dataClone != null) {
            delete dataClone.wrk;
          }
          if (this.historyWRKData != undefined && this.historyWRKData != null
            && this.checkContainsWRKObject(this.historyWRKData, dataClone))
            obj.wrk = true;
          else
            obj.wrk = false;

          return obj;
        });
        setTimeout(() => {
          return;
        }, 0);
      }
      if (!this.months) {
        this.months = 12;
      }
      let filteredDataforHistory = "";
      filteredDataforHistory = {
        SearchMonths: { value: this.months, MatchMode: "contains" },
        OriginCity: {
          value: this.sourceLoadData.PickupCity==''?null:this.sourceLoadData.PickupCity,
          MatchMode: "contains",
        },
        OriginState: {
          value: this.sourceLoadData.PickupState==''?null:this.sourceLoadData.PickupState,
          MatchMode: "contains",
        },
        OriginRadius: {
          value: (!this.sourceLoadData.PickupCity && !this.sourceLoadData.PickupState)?null:this.selectedRadiusHistory,
          MatchMode: "contains",
        },
        DestinationCity: {
          value: this.sourceLoadData.DestinationCity==''?null:this.sourceLoadData.DestinationCity,
          MatchMode: "contains",
        },
        DestinationState: {
          value: this.sourceLoadData.DestinationState==''?null:this.sourceLoadData.DestinationState,
          MatchMode: "contains",
        },
        DestinationRadius: {
          value: (!this.sourceLoadData.DestinationCity && !this.sourceLoadData.DestinationState)?null:this.selectedRadiusHistory,
          MatchMode: "contains",
        },
        ShowAll: {
          value: this.historyRadio == "All" ? 0 : this.historyRadio == "Portal" ? 1 : 2,
          MatchMode: "contains",
        },
        FullTruckload: { value: this.isFTLLoad(), MatchMode: "contains" },
        LTLPartial: { value: this.isPartialLoad(), MatchMode: "contains" },
        CustomerID: {
          value: this.sourceLoadData.CustomerID == null ? 0 : this.sourceLoadData.CustomerID,
          MatchMode: "contains",
        },
        PortalId: { value: this.getPortalID, MatchMode: "contains" },
        LoadType: { value: this.getLoadType(), MatchMode: "contains" },
      };  
      this.lazyParamsForHistory.PickupDate = this.sourceLoadData.PickupDate==(null||"")?new Date(null):this.sourceLoadData.PickupDate;
      this.lazyParamsForHistory.DropDate = this.sourceLoadData.DropDate==(null||"")?new Date(null):this.sourceLoadData.DropDate; 
      this.lazyParamsForHistory.EquipmentTypeID = this.sourceLoadData.EquipTypeID;
      this.lazyParamsForHistory.CarrierSmartSearch.isGroup=this.GroupTypeRadio=='Group'?true:false;
      this.lazyParamsForHistory.CarrierSmartSearch.equipmentTypeList=this.sourceLoadData.EquipmentTypeIDList;
      this.lazyParamsForHistory.CarrierSmartSearch.groupList=this.consolidateGroupValues();
      this.lazyParamsForHistory.CarrierSmartSearch.weight=!this.sourceLoadData.Weight ? "0" : ""+this.sourceLoadData.Weight;
      this.lazyParamsForHistory.CarrierSmartSearch.size=!this.sourceLoadData.EquipMinSize ? "0" : ""+this.sourceLoadData.EquipMinSize;
      this.lazyParamsForHistory.CarrierSmartSearch.miles=""+this.sourceLoadData.Mileage;

      this.lazyParamsForHistory.filters = filteredDataforHistory;
      this.loading = true;
      this.tabEnableDisabled('history', true);
      this.customerService
        .GetMySourceLoadHistory({
          ...this.lazyParamsForHistory,
          USPSCarriersOnly: this.uspsCarriersOnly
        })
        .then((data) => {
          this.carrierHistory = data.carrierHistoryData;
          if (this.carrierHistory && this.carrierHistory.length > 0) {
            this.isDataAvailable = true;
          } else {
            this.isDataAvailable = false;
          }
          this.totalRecordsHistory = data.TotalCount;
          this.selectDisabled = false;
          this.carrierHistory.forEach((element) => {
            if (element.PickupDate) {
              element.PickupDate = this.customerService.DateToYYMMDD(
                new Date(element.PickupDate)
              );
            }
            if (element.IsLoad == 1) {
              element.IsLoad = "Y";
            } else {
              element.IsLoad = "N";
            }
            if (element.DistanceFromOrigin) {
              element.DistanceFromOrigin = Math.round(
                element.DistanceFromOrigin
              );
            }
            if (element.DistanceFromDestination) {
              element.DistanceFromDestination = Math.round(
                element.DistanceFromDestination
              );
            }
          });
          if (this.carrierHistory.length == 0) {
            this.isShowHistoryGrid = false;
            this.isDataAvailable = false;
            this.searchMessage = "No records available.";
          } else {
            this.isShowHistoryGrid = true;
            this.isDataAvailable = true;
          }
          this.carrierHistory = this.carrierHistory.map((obj) => {
            let dataClone = JSON.parse(JSON.stringify(obj));
            if (dataClone != null) {
              delete dataClone.wrk;
            }
            if (this.historyWRKData != undefined && this.historyWRKData != null
              && this.checkContainsWRKObject(this.historyWRKData, dataClone))
              obj.wrk = true;
            else
              obj.wrk = false;

            return obj;
          });
          this.totalRecordsHistory = data.TotalCount;
          this.loading = false;
          this.isShowHistoryGrid = true;
          this.tabEnableDisabled('history', false);
          this.setTitleAttribute();
        });
    },
    getLoadType(){
      if(this.isCarrierSmartSearch){
         return Number(this.sourceLoadData.FTLLoad)
      }
      return this.sourceLoadData.LoadType;
    },
    isFTLLoad(){
      if(this.isCarrierSmartSearch){
         return Number(this.sourceLoadData.FTLLoad)
      }
      return 1;
    },
    isPartialLoad(){
      if(this.isCarrierSmartSearch){
         return Number(this.sourceLoadData.PartialLoad)
      }
      return 0;
    },
    getActiveTabName(activeTabname) {

      this.selectedCustomers = null;
      this.activeTab = "";
      this.activeTab = activeTabname;
      if (activeTabname == "carrierdomicile" && this.customers && this.customers.length > 0) {
        this.isDataAvailable = true;
      }
      else if (activeTabname == "carrierdomicile") {
        this.isDataAvailable = false;
      }
      else {
        this.isDataAvailable = false;
      }
    },
    tabEnableDisabled(tabName, isDisabled) {
      switch (tabName) {
        case 'carrier':
          this.isCarrierTabClickEnabled = true;
          this.isFavoriteTabClickEnabled = isDisabled ? false : true;
          this.isHistoryTabClickEnabled = isDisabled ? false : true;
          this.isDeliveringTabClickEnabled = isDisabled ? false : true;
          this.isLoadBoardTabClickEnabled = isDisabled ? false : true;
          this.isHeadquarterTabClickEnabled = isDisabled ? false : true;
          break;
        case 'favorite':
          this.isCarrierTabClickEnabled = isDisabled ? false : true;
          this.isFavoriteTabClickEnabled = true
          this.isHistoryTabClickEnabled = isDisabled ? false : true;
          this.isDeliveringTabClickEnabled = isDisabled ? false : true;
          this.isLoadBoardTabClickEnabled = isDisabled ? false : true;
          this.isHeadquarterTabClickEnabled = isDisabled ? false : true;
          break;
        case 'history':
          this.isCarrierTabClickEnabled = isDisabled ? false : true;
          this.isFavoriteTabClickEnabled = isDisabled ? false : true;
          this.isHistoryTabClickEnabled = true;
          this.isDeliveringTabClickEnabled = isDisabled ? false : true;
          this.isLoadBoardTabClickEnabled = isDisabled ? false : true;
          this.isHeadquarterTabClickEnabled = isDisabled ? false : true;
          break;
        case 'loadboard':
          this.isCarrierTabClickEnabled = isDisabled ? false : true;
          this.isFavoriteTabClickEnabled = isDisabled ? false : true;
          this.isHistoryTabClickEnabled = isDisabled ? false : true;
          this.isDeliveringTabClickEnabled = isDisabled ? false : true;
          this.isLoadBoardTabClickEnabled = true;
          this.isHeadquarterTabClickEnabled = isDisabled ? false : true;
          break;
        case 'delivering':
          this.isCarrierTabClickEnabled = isDisabled ? false : true;
          this.isFavoriteTabClickEnabled = isDisabled ? false : true;
          this.isHistoryTabClickEnabled = isDisabled ? false : true;
          this.isDeliveringTabClickEnabled = true;
          this.isLoadBoardTabClickEnabled = isDisabled ? false : true;
          this.isHeadquarterTabClickEnabled = isDisabled ? false : true;
          break;
        case 'headquarter':
          this.isCarrierTabClickEnabled = isDisabled ? false : true;
          this.isFavoriteTabClickEnabled = isDisabled ? false : true;
          this.isHistoryTabClickEnabled = isDisabled ? false : true;
          this.isDeliveringTabClickEnabled = isDisabled ? false : true;
          this.isLoadBoardTabClickEnabled = isDisabled ? false : true;
          this.isHeadquarterTabClickEnabled = true;
          break;
        default:
          this.isCarrierTabClickEnabled = true;
          this.isFavoriteTabClickEnabled = true;
          this.isHistoryTabClickEnabled = true;
          this.isDeliveringTabClickEnabled = true;
          this.isLoadBoardTabClickEnabled = true;
          this.isHeadquarterTabClickEnabled = true;
      }
    },
    closeDOTStateDialog() {
      this.dotStateDialog = false;
      this.isCarrierSelectedDialog = false;
      this.isMaximumCarrierSelectedDialog = false;
    },
    closeEmailvalidationDialog() {
      this.EmailValidationDialog = false;
    },
    closesingleSelectDialog() {
      this.singleSelectDialog = false;
    },
    closeCarrierDialog() {
      this.carrierDialog = false;
      //      this.CarrierInsurance='No';
    },
    DOTPermitChange() {
      if (this.DOTPermit == "No") {
        this.carrierMsgContent =
          "The selected carrier cannot be loaded without required permits";
        this.dotStateDialog = true;
        this.disableSaveCarrier = true;
      }
      if (this.DOTPermit == "No" || this.CarrierInsurance == "No") {
        this.disableSaveCarrier = true;
      } else {
        this.disableSaveCarrier = false;
      }
    },
    InsuranceChange() {
      if (this.DOTPermit == "No" || this.CarrierInsurance == "No") {
        this.disableSaveCarrier = true;
      } else {
        this.disableSaveCarrier = false;
      }
    },
    checkDOTState() {
      if (this.selectedSourceLoadData[0].DOTOnly) {
        var usedStates = [];
        usedStates.push(this.sourceLoadData.PickupState);
        usedStates.push(this.sourceLoadData.DestinationState);

        if (usedStates.filter(state => state !== this.selectedSourceLoadData[0].DOTState).length > 0) {
          this.carrierCondition = true;
          this.carrierDOTCondition = true;
        } else {
          this.carrierDOTCondition = false;
          this.DOTPermit = "Yes";
        }
      }
      else {
        this.DOTPermit = "Yes";
      }
      return true;
    },
    networkLoad() {
      localStorage.setItem('fromSource', true);
      if (this.sourceLoadData.NetworkLoad) {
        localStorage.setItem('NetworkLoad', true);
      }
      //      this.$router.push({ path: `/book-edit/${this.loadID}`, params: "_blank"});   
      let route = this.$router.resolve({ path: `/book-edit/${this.loadID}/0` });
      window.open(route.href, '_blank');

    },
    triggerSelect() {
      if (this.LoadStatus != "" && this.LoadStatus != "Available" && this.LoadStatus != "Posting") {
        this.selectAlert = true;
        return;
      }
      this.sureNoDialog = false;
      switch (this.activeTab) {
        case "carrierdomicile":
          if (
            this.$refs.sdt.selection != null &&
            this.$refs.sdt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.sdt.selection;
            if (this.$refs.sdt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.sdt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.sdt.selection != null &&
            this.$refs.sdt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
        case "carrierdelivery":
          if (
            this.$refs.cdt.selection != null &&
            this.$refs.cdt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.cdt.selection;
            if (this.$refs.cdt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.cdt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.cdt.selection != null &&
            this.$refs.cdt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
        case "isfavorite":
          if (
            this.$refs.fdt.selection != null &&
            this.$refs.fdt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.fdt.selection;
            if (this.$refs.fdt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.fdt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.fdt.selection != null &&
            this.$refs.fdt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
        case "history":
          if (
            this.$refs.hhdt.selection != null &&
            this.$refs.hhdt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.hhdt.selection;
            if (this.$refs.hhdt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.hhdt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.hhdt.selection != null &&
            this.$refs.hhdt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
        case "loadboards":
          if (
            this.$refs.ldt.selection != null &&
            this.$refs.ldt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.ldt.selection;
            if (this.$refs.ldt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.ldt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.ldt.selection != null &&
            this.$refs.ldt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
        case "carrierheadquarters":
          if (
            this.$refs.hdt.selection != null &&
            this.$refs.hdt.selection.length == 1
          ) {
            this.selectedSourceLoadData = this.$refs.hdt.selection;
            if (this.$refs.hdt.selection[0].IsLoad == "N") {
              this.sureNoDialog = true;
              this.sureNoDialogMessage = this.$refs.hdt.selection[0].ErrorMessage;
            }
            else {
              this.PerformInsurenceCheck();
            }
          } else if (
            this.$refs.hdt.selection != null &&
            this.$refs.hdt.selection.length > 1
          ) {
            this.carrierMsgContent =
              "Please select single carrier to proceed.";
            this.dotStateDialog = true;
          } else {
            this.carrierMsgContent =
              "Please select atleast 1 carrier to proceed.";
            this.dotStateDialog = true;
          }
          break;
      }
    },
    PerformInsurenceCheck() {
      if (this.sourceLoadData.PartialLoad == true) {
        this.saveCarrierLoadData = {
          CarrierPickID: 0,
          CarrierID: this.selectedSourceLoadData[0].CarrierID,
          LoadID: this.loadID,
          Primary: true,
          Dispatcher: "",
          ELDEnabled: 1,
          AssistingPortalID: 0,
          AssistingEmployeeID: 0,
        };
        this.carrierDialog = true;
        this.carrierInsuranceCondition = true;
      }
      if (this.sourceLoadData.PartialLoad != true) {
        this.sureNoDialog = false;
        this.openSureDialog();
      }
    },
    checkCarrierConditions() {

      if (this.fromBookEdit) {
        this.emitCarrierData();
      }
      else {
        this.saveCarrierLoadData = {
          CarrierPickID: 0,
          CarrierID: this.selectedSourceLoadData[0].CarrierID,
          LoadID: this.loadID,
          Primary: true,
          Dispatcher: "",
          ELDEnabled: 1,
          AssistingPortalID: 0,
          AssistingEmployeeID: 0,
        };

        // check DOTState
        var dotState = true;
        dotState = this.checkDOTState();

        if (dotState) {
          //check carrier insurance
          if (this.sourceLoadData.PartialLoad == true) {
            this.carrierCondition = true;
            this.carrierInsuranceCondition = true;
          }
          else {
            this.CarrierInsurance = "Yes";
          }

          //show popup
          if (this.carrierCondition == true) {
            this.carrierDialog = true;
          } else {
            this.saveCarrier();
          }
        }
      }
    },

    async emitCarrierData() {
      await dataProvider
        .SelectCarrier(this.selectedSourceLoadData[0].CarrierID)
        .then((res) => {
          if (res) {
            let selectCarrierObject = {};
            selectCarrierObject.CarrierID = res.CarrierID;
            selectCarrierObject.carrierName = res.CarrierName;
            if (res.CarrierMCNum) {
              selectCarrierObject.mcDot = res.CarrierMCNum;
            }
            else if (res.DOTNumber) {
              selectCarrierObject.mcDot = res.DOTNumber;
            }
            else {
              selectCarrierObject.mcDot = "";
            }
            selectCarrierObject.insurance = res.InsNum;
            selectCarrierObject.expires = this.customerService.DateToYYMMDD(
              new Date(res.InsExpOn)
            );
            selectCarrierObject.additional = this.customerService.DateToYYMMDD(
              new Date(res.AddIns)
            );
            selectCarrierObject.phoneNo = res.Phone;
            if (this.fromBookEdit)
              this.$emit("carrierData", selectCarrierObject);
            else {
              localStorage.setItem('CarrierDetailsFromSource', JSON.stringify(selectCarrierObject));
            }
            this.selectedCarrierDelivery = null,
              this.selectedLoadBoards = null,
              this.selectedCarrierHistory = null,
              this.selectedCustomersFav = null,
              this.selectedCarrierHeadquarters = null,
              this.sourceloadpopup = false;

          }
        });

    },
    openSureDialog() {
      if (
        this.LoadStatus == "Covered" ||
        this.LoadStatus == "Dispatched" ||
        this.LoadStatus == "In-Transit"
      ) {
        this.sureDialog = true;
      } else {
        this.checkCarrierConditions();
      }
    },

    async saveCarrier() {
      // if (this.sourceLoadData.NetworkLoad == true) {
      //   this.closeCarrierDialog();

      //   return;
      // }
      if (this.fromBookEdit || this.LoadStatus == "Posting" || this.LoadStatus == "Available") {
        this.carrierInsuranceCondition = false;
        this.carrierDialog = false;
        this.emitCarrierData();
        if (!this.fromBookEdit) {
          this.networkLoad();
        }
        return;
      }
      this.sureNoDialog = false;
      // this.openSureDialog();
      this.carrierDialog = false;
      this.selectDisabled = true;
      await dataProvider
        .saveCarrierLoad(this.saveCarrierLoadData)
        .then((res) => {
          if (res > 0) {
            this.$toast.add({
              severity: "success",
              summary: "Load Saved",
              detail: "Carrier added to the load successfully.",
              life: 5000,
            });
            this.showSaveMessage = false;
            this.networkLoad()
          }
        }
        );

      this.selectDisabled = false;

      //empty selection
      if (this.activeTab == "carrierdomicile")
        this.$refs.sdt.selection.length = 0;
      else if (this.activeTab == "isfavorite")
        this.$refs.fdt.selection.length = 0;
      else if (this.activeTab == "history")
        this.$refs.hhdt.selection.length = 0;
      else if (this.activeTab == "carrierdelivery")
        this.$refs.cdt.selection.length = 0;
      else if (this.activeTab == "loadboards")
        this.$refs.ldt.selection.length = 0;
      else if (this.activeTab == "carrierheadquarters")
        this.$refs.hdt.selection.length = 0;
    },


    emptySelection() {
      if (this.$refs.hdt != null) {
        if (this.$refs.hdt.selection) {
          this.$refs.hdt.selection.length = 0;
        }
      }
      if (this.$refs.cdt != null) {
        if (this.$refs.cdt.selection) {
          this.$refs.cdt.selection.length = 0;
        }
      }
      if (this.$refs.hhdt != null) {
        if (this.$refs.hhdt.selection) {
          this.$refs.hhdt.selection.length = 0;
        }
      }
      if (this.$refs.ldt != null) {
        if (this.$refs.ldt.selection) {
          this.$refs.ldt.selection.length = 0;
        }
      }
      if (this.$refs.sdt != null) {
        if (this.$refs.sdt.selection) {
          this.$refs.sdt.selection.length = 0;
        }
      }
      if (this.$refs.fdt != null) {
        if (this.$refs.fdt.selection) {
          this.$refs.fdt.selection.length = 0;
        }
      }
    },
    onSearchCarrier(search, loading) {
      this.selectDisabled = true;
      this.searchLen = search.length;
      if (search.length >= 3) {
        loading(false);
        this.searchCarrier(loading, search, this);
      }
    },
    searchCarrier: _.debounce(async function (loading, search) {
      this.selectDisabled = true;
      await dataProvider.getCarrierFilter(search, "CarrierName", this.sourceLoadData.LoadType).then((res) => {
        this.carrierName = res;
        loading(false);
      });
    }, 350),

    onSearchCarrierFav(search, loading) {
      this.selectDisabled = true;
      this.searchLenFav = search.length;
      if (search.length >= 3) {
        loading(false);
        this.searchCarrierFav(loading, search, this);
      }
    },
    searchCarrierFav: _.debounce(async function (loading, search) {
      this.selectDisabled = true;
      await dataProvider.getFavoriteCarrierFilter(search, this.sourceLoadData.LoadType).then((res) => {
        this.carrierNameFav = res;
        this.serachCarrierNameMessage = this.carrierNameFav
          ? "No search found."
          : "type to search Carrier Name.";
        loading(false);
      });
    }, 350),

    searchCarrierDomicileGrid() {
      this.selectDisabled = false;
      this.customerServicesOnSearch("search");
    },
    searchCarrierDomicileGridFav() {
      this.selectDisabled = false;
      this.customerServicesOnSearchFav("search");
    },
    onSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.customerService
          .GetMySourceLoads({
            ...this.lazyParams,
            USPSCarriersOnly: this.uspsCarriersOnly
          }).then((data) => {
            this.selectAll = true;
            this.customers = data.customers;
          });
      } else {
        this.selectAll = false;
        this.customers = [];
      }
    },
    onSelectAllChangeFav(event) {
      const selectAll = event.checked;

      if (selectAll) {
        this.customerService
          .GetMyFavoriteCarriers({
            ...this.lazyParamsFav,
            USPSCarriersOnly: this.uspsCarriersOnly
          }).then((data) => {
            this.selectAllFav = true;
            this.customersFav = data.customers;
          });
      } else {
        this.selectAllFav = false;
        this.customersFav = [];
      }
    },
    onCarrierSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.customerService
          .GetMySourceLoadCarrierDelivery({
            ...this.lazyParamsForCarrier,
            USPSCarriersOnly: this.uspsCarriersOnly
          })
          .then((data) => {
            this.selectAllCarrier = true;
            this.selectedCarrierDelivery = data.carrierDeliveryData;
            this.carrierDelivery = data.carrierDeliveryData;
          });
      } else {
        this.selectAllCarrier = false;
        this.carrierDelivery = [];
      }
    },
    onCarrierLoadBoardSelectAllChange(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.customerService
          .GetMySourceLoadCarrierLoadBoard({
            ...this.lazyParamsForCarrierLoadBoards,
            USPSCarriersOnly: this.uspsCarriersOnly
          })
          .then((data) => {
            this.selectAllLoadBoards = true;
            this.carrierLoadBoard = data.carrierLoadBoardData;
          });
      } else {
        this.selectAllLoadBoards = false;
        this.carrierLoadBoard = [];
      }
    },
    onCarrierSelectAllChangeHistory(event) {
      const selectAll = event.checked;
      if (selectAll) {
        this.customerService
          .GetMySourceLoadHistory({
            ...this.lazyParamsForHistory,
            USPSCarriersOnly: this.uspsCarriersOnly
          }).then((data) => {
            this.selectAllCarrierHistory = true;
            this.carrierHistory = data.carrierHistoryData;
          });
      } else {
        this.selectAllCarrierHistory = false;
        this.carrierHistory = [];
      }
    },
    removeOverflow() {
      document.body.classList.add("scroll-hidden");
    },
    async exportExcel() {
      this.loading = true;
      this.exportInProgress = true;
      var loadDataResult = null;
      var myloadFileName =
        this.activeTab.toUpperCase() + "_" + this.sourceLoadId;
      var carrierData = "";

      switch (this.activeTab) {
        case "carrierdomicile":
          if (
            this.$refs.sdt.selection != null &&
            this.$refs.sdt.selection.length > 0
          ) {
            carrierData = this.$refs.sdt.selection;
          } else {
            this.selectDisabled = false;
            let updatedParams = this.lazyParams;
            updatedParams.rows = this.totalCarrierDomicile;
            updatedParams.page = 0;
            loadDataResult = await this.customerService.GetMySourceLoads({
              ...updatedParams,
              USPSCarriersOnly: this.uspsCarriersOnly
            });
            carrierData = loadDataResult.carrierDomicileData;
          }
          var carrierDomicileClone = JSON.parse(JSON.stringify(carrierData));
          if (carrierDomicileClone != null) {
            carrierDomicileClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
              delete item.CarrierID;
              delete item.StateCoverage;
            });
          }
          var headerForCarrierDomicile = [
            "MC#",
            "USDOT#",
            "DOT#",
            "Carrier Name",
            "State",
            "Rating",
            "Load?",
            "ELD?",
            "Contact",
            "Phone",
            "Email",
          ];

          var dataForCarrierDomacile = carrierDomicileClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              const keys = Object.keys(obj);

              for (let a in arr) {
                res[arr[a]] = obj[keys[a]];
                obj[arr[a]] = obj[keys[a]];
                delete obj[keys[a]];
              }
            };
            replaceKeys(headerForCarrierDomicile, item);
            return res;
          });
          json2excel({
            data: dataForCarrierDomacile,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });

          break;
        case "isfavorite":
          if (
            this.$refs.fdt.selection != null &&
            this.$refs.fdt.selection.length > 0
          ) {
            carrierData = this.$refs.fdt.selection;
          } else {
            this.selectDisabled = false;
            let updatedParamsFav = this.lazyParamsFav;
            updatedParamsFav.rows = this.totalRecordsFav;
            updatedParamsFav.page = 0;
            loadDataResult = await this.customerService.GetMyFavoriteCarriers({
              ...updatedParamsFav,
              USPSCarriersOnly: this.uspsCarriersOnly
            });
            carrierData = loadDataResult.carrierDomicileData;
          }
          var carrierFavoriteClone = JSON.parse(JSON.stringify(carrierData));
          if (carrierFavoriteClone != null) {
            carrierFavoriteClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
              delete item.CarrierID;
              delete item.StateCoverage;
            });
          }

          var objectOrder = [
            "CarrierMCNum",
            "USDOTNumber",
            "DOTNumber",
            "CarrierName",
            "State",
            "Rating",
            "IsLoad",
            "ELDEnabled",
            "ContactName",
            "Phone",
            "Email"
          ];

          var headerForCarrierFavorite = [
            "MC#",
            "USDOT#",
            "DOT#",
            "Carrier Name",
            "State",
            "Rating",
            "Load?",
            "ELD?",
            "Contact",
            "Phone",
            "Email"
          ];

          var dataForCarrierFavorite = carrierFavoriteClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              for (let a in arr) {
                res[arr[a]] = obj[objectOrder[a]];
                obj[arr[a]] = obj[objectOrder[a]];
                delete obj[objectOrder[a]];
              }
            };
            replaceKeys(headerForCarrierFavorite, item);
            return res;
          });
          json2excel({
            data: dataForCarrierFavorite,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });

          break;
        case "carrierdelivery":
          if (
            this.$refs.cdt.selection != null &&
            this.$refs.cdt.selection.length > 0
          ) {
            carrierData = this.$refs.cdt.selection;
            carrierData.forEach((element) => {
              if (element) {
                if (element.DateAvailable) {
                  element.DateAvailable = this.customerService.DateToYYMMDD(
                    new Date(element.DateAvailable)
                  );
                }
                if (element.OriginRadius_DH) {
                  element.OriginRadius_DH = Math.round(element.OriginRadius_DH);
                }
              }
            });
          } else {
            let updatedParamsCarrier = this.lazyParamsForCarrier;
            updatedParamsCarrier.rows = this.totalRecordsCarrierDomicile;
            updatedParamsCarrier.page = 0;
            loadDataResult =
              await this.customerService.GetMySourceLoadCarrierDelivery({
                ...updatedParamsCarrier,
                USPSCarriersOnly: this.uspsCarriersOnly
              });
            carrierData = loadDataResult.carrierDeliveryData;
            carrierData.forEach((element) => {
              if (element) {
                if (element.DateAvailable) {
                  element.DateAvailable = this.customerService.DateToYYMMDD(
                    new Date(element.DateAvailable)
                  );
                }
                if (element.OriginRadius_DH) {
                  element.OriginRadius_DH = Math.round(element.OriginRadius_DH);
                }
              }
            });
          }
          var carrierDeliveryClone = JSON.parse(JSON.stringify(carrierData));

          if (carrierDeliveryClone != null) {
            carrierDeliveryClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
              delete item.LoadID;
              delete item.CarrierID;
              delete item.EquipGroupName;
              delete item.DOTState;
              delete item.DOTOnly;
              delete item.StateCoverage;
            });
          }
          var headerForCarrierDelivery = [
            "MC#",
            "USDOT#",
            "DOT#",
            "Carrier Name",
            "EQP",
            "Rating",
            "Load?",
            "Location",
            "DH",
            "Available",
            "Contact",
            "Phone",
            "Email",
          ];

          var dataForCarrierDelivery = carrierDeliveryClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              const keys = Object.keys(obj);

              for (let a in arr) {
                res[arr[a]] = obj[keys[a]];
                obj[arr[a]] = obj[keys[a]];
                delete obj[keys[a]];
              }
            };
            replaceKeys(headerForCarrierDelivery, item);
            return res;
          });
          json2excel({
            data: dataForCarrierDelivery,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });
          break;
        case "history":
          var historycarrierData;
          if (
            this.$refs.hhdt.selection != null &&
            this.$refs.hhdt.selection.length > 0
          ) {
            historycarrierData = this.$refs.hhdt.selection;
          } else {
            this.selectDisabled = false;
            let updatedHistoryParams = this.lazyParamsForHistory;
            updatedHistoryParams.rows = this.totalRecordsHistory;
            updatedHistoryParams.page = 0;
            loadDataResult = await this.customerService
              .GetMySourceLoadHistory({
                ...updatedHistoryParams,
                USPSCarriersOnly: this.uspsCarriersOnly
              });
            historycarrierData = loadDataResult.carrierHistoryData;
          }
          var carrierHistoryClone = JSON.parse(JSON.stringify(historycarrierData));
          if (carrierHistoryClone != null) {
            carrierHistoryClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
              delete item.CarrierID;
              delete item.StateCoverage;
              delete item.DropDate;
              delete item.USDOTNumber;
              delete item.DOTOnly;
              delete item.PickupRegion;
              delete item.DropRegion;
              delete item.WRK;
            });
          }
          var headerForHistoryCarrier = [
            "MC#",
            "Carrier Name",
            "EPQ",
            "Rating",
            "Load?",
            "PU Date",
            "Origin",
            "DH/O",
            "Destination",
            "DH/D",
            "Weight",
            "Amount",
            "Contact",
            "Phone",
            "Email"
          ];

          var dataForCarrierHistory = carrierHistoryClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              const keys = Object.keys(obj);

              for (let a in arr) {
                res[arr[a]] = obj[keys[a]];
                obj[arr[a]] = obj[keys[a]];
                delete obj[keys[a]];
              }
            };
            replaceKeys(headerForHistoryCarrier, item);
            return res;
          });
          json2excel({
            data: dataForCarrierHistory,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });

          break;
        case "loadboards":
          if (
            this.$refs.ldt.selection != null &&
            this.$refs.ldt.selection.length > 0
          ) {
            carrierData = this.$refs.ldt.selection;
          } else {
            let updatedLoadboardParams = this.lazyParamsForCarrierLoadBoards;
            updatedLoadboardParams.rows = this.totalRecordsLoadBoards;
            updatedLoadboardParams.page = 0;
            loadDataResult =
              await this.customerService.GetMySourceLoadCarrierLoadBoard({
                ...updatedLoadboardParams,
                USPSCarriersOnly: this.uspsCarriersOnly
              });
            //this line added for refresh grid after export click.
            this.carrierLoadBoard = loadDataResult.carrierLoadBoardData;
            carrierData = loadDataResult.carrierLoadBoardData;
          }
          var carrierLoadBoardClone = JSON.parse(JSON.stringify(carrierData));

          if (carrierLoadBoardClone != null) {
            carrierLoadBoardClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
            });
          }
          var headerForLoadBoard = [
            "Load?",
            "Source",
            "Age",
            "MC",
            "Carrier Name",
            "EQP",
            "Rating",
            "Carrier Setup",
            "Location",
            "DH",
            "Available",
            "Contact",
            "Phone",
            "Email",
          ];

          var dataForLoadBoard = carrierLoadBoardClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              const keys = Object.keys(obj);

              for (let a in arr) {
                res[arr[a]] = obj[keys[a]];
                obj[arr[a]] = obj[keys[a]];
                delete obj[keys[a]];
              }
            };
            replaceKeys(headerForLoadBoard, item);
            return res;
          });
          json2excel({
            data: dataForLoadBoard,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });
          break;
        case "carrierheadquarters":
          if (
            this.$refs.hdt.selection != null &&
            this.$refs.hdt.selection.length > 0
          ) {
            carrierData = this.$refs.hdt.selection;
          } else {
            this.selectDisabled = false;
            let updatedHqParams = this.lazyParamsForCarrierHeadquarters;
            updatedHqParams.rows = this.totalRecordsCarrierHeadquarters;
            updatedHqParams.page = 0;
            loadDataResult = await this.customerService
              .GetMySourceLoadCarrierHeadquarter({
                ...updatedHqParams,
                USPSCarriersOnly: this.uspsCarriersOnly
              })
            carrierData = loadDataResult.carrierHeadquarterData;
          }
          var carrierHqClone = JSON.parse(JSON.stringify(carrierData));
          if (carrierHqClone != null) {
            carrierHqClone.forEach((item) => {
              delete item.ErrorCount;
              delete item.ErrorMessage;
              delete item.CarrierID;
              delete item.StateCoverage;
              delete item.DOTOnly;
              delete item.DOTState;
              delete item.WRK;
            });
          }
          var headerForHqCarrier = [
            "MC#",
            "USDOT#",
            "DOT#",
            "Carrier Name",
            "Location",
            "DH",
            "Rating",
            "Load?",
            "ELD",
            "Contact",
            "Phone",
            "Email"
          ];


          var dataForCarrierHq = carrierHqClone.map((item) => {
            var res = {};
            const replaceKeys = (arr, obj) => {
              const keys = Object.keys(obj);

              for (let a in arr) {
                res[arr[a]] = obj[keys[a]];
                obj[arr[a]] = obj[keys[a]];
                delete obj[keys[a]];
              }
            };
            replaceKeys(headerForHqCarrier, item);
            return res;
          });
          json2excel({
            data: dataForCarrierHq,
            name: myloadFileName,
            formateDate: "dd/mm/yyyy",
          });

          break;
      }
      this.exportInProgress = false;
      this.loading = false;
    },
    rowClassCarrier(data) {
      var status = "";
      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    rowClassFav(data) {
      var status = "";
      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    checkContainsWRKObject(list, obj) {
      let i;
      for (i = 0; i < list.length; i++) {
        if (JSON.stringify(list[i]) == JSON.stringify(obj)) {
          return true;
        }
      }
      return false;
    },
    UpdateHistoryWRKModel(data) {
      let dataClone = JSON.parse(JSON.stringify(data));
      if (dataClone != null) {
        delete dataClone.wrk;
      }
      if (data.wrk == false && this.historyWRKData != undefined && this.historyWRKData != null
        && !this.checkContainsWRKObject(this.historyWRKData, dataClone)) {
        this.historyWRKData.push(dataClone);
      }
      else if (data.wrk == true && this.historyWRKData != undefined && this.historyWRKData != null
        && this.checkContainsWRKObject(this.historyWRKData, dataClone)) {
        this.historyWRKData.splice(this.historyWRKData.findIndex(a => a.CarrierID === dataClone.CarrierID), 1);
      }
    },
    rowClassHistory(data) {
      var status = "";
      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    UpdateDeliveryWRKModel(data) {
      let dataClone = JSON.parse(JSON.stringify(data));
      if (dataClone != null) {
        delete dataClone.wrk;
      }
      if (data.wrk == false && this.deliveryWRKData != undefined && this.deliveryWRKData != null
        && !this.checkContainsWRKObject(this.deliveryWRKData, dataClone)) {
        this.deliveryWRKData.push(dataClone);
      }
      else if (data.wrk == true && this.deliveryWRKData != undefined && this.deliveryWRKData != null
        && this.checkContainsWRKObject(this.deliveryWRKData, dataClone)) {
        this.deliveryWRKData.splice(this.deliveryWRKData.findIndex(a => a.CarrierID === dataClone.CarrierID), 1);
      }
    },
    rowClassDelivery(data) {
      var status = "";
      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    UpdateLoadBoardWRKModel(data) {
      let dataClone = JSON.parse(JSON.stringify(data));
      if (dataClone != null) {
        delete dataClone.wrk;
      }
      if (data.wrk == false && this.loadboardWRKData != undefined && this.loadboardWRKData != null
        && !this.checkContainsWRKObject(this.loadboardWRKData, dataClone)) {
        this.loadboardWRKData.push(dataClone);
      }
      else if (data.wrk == true && this.loadboardWRKData != undefined && this.loadboardWRKData != null
        && this.checkContainsWRKObject(this.loadboardWRKData, dataClone)) {
        this.loadboardWRKData.splice(this.loadboardWRKData.findIndex(a => a.CarrierID === dataClone.CarrierID), 1);
      }
    },
    rowClassLoadBoard(data) {
      var status = "";

      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    consolidateGroupValues(){
      let groupArr=[];
      if (this.Reefers) {
          groupArr.push(2);
      }
      if (this.Specialized) {
          groupArr.push(4);
      }
      if (this.Flatbeds) {
          groupArr.push(3);
      }
      if (this.Vans) {
          groupArr.push(1);
      }
      return groupArr;
    },
    rowClassHead(data) {
      var status = "";
      if (
        this.LoadStatus == "Delivered" ||
        this.LoadStatus == "Cancelled" ||
        this.LoadStatus == "On Hold" ||
        this.LoadStatus == "Claims"
      ) {
        this.selectDisabled = true;
      }
      if (data.IsLoad === "N") {
        if (data.wrk === true) {
          status = "strikethrough";
        }
      } else if (data.wrk === true) {
        status = "strikethrough";
      }
      return status;
    },
    async triggerEmail() {

      let emailArray = "";
      switch (this.activeTab) {
        case "carrierdomicile":
          if (this.$refs.sdt.selection != null) {
            emailArray = this.$refs.sdt.selection.map((item) => item.Email);
          }
          break;
        case "carrierdelivery":
          if (this.$refs.cdt.selection != null) {
            emailArray = this.$refs.cdt.selection.map((item) => item.Email);
          }
          break;
        case "isfavorite":
          if (this.$refs.fdt.selection != null) {
            emailArray = this.$refs.fdt.selection.map((item) => item.Email);
          }
          break;
        case "history":
          if (this.$refs.hhdt.selection != null) {
            emailArray = this.$refs.hhdt.selection.map((item) => item.Email);
          }
          break;
        case "loadboards":
          if (this.$refs.ldt.selection != null) {
            emailArray = this.$refs.ldt.selection.map((item) => item.Email);
          }
          break;
        case "carrierheadquarters":
          if (this.$refs.hdt.selection != null) {
            emailArray = this.$refs.hdt.selection.map((item) => item.Email);
          }
          break;
      }
      if (emailArray.length == 0) {
        this.EmailValidationDialog = true;
        this.emailMsgContent = "Please select atleast 1 carrier to proceed.";
        return false;
      }
      emailArray = this.mapValidEmails(emailArray);

      if (emailArray?.length > 0 && emailArray?.length <= 50) {
        if (this.loadID === 0 || this.loadID === '') {
          this.saveLoadConfirmationpopupdialog = true;
        }
        else {
          let emailBody = ''
          let emailRequest = {
            OwnerID: this.loadID,
            EmployeeID: this.$store.state.login.employeeID
          }
          await dataProvider.sendEmailDetail(emailRequest.OwnerID, emailRequest.EmployeeID).then((res) => {
            if (res) {
              emailBody = res.EmailBody;
            }
          });

          let DraftEmail = {
            SenderAccount: "noreply@shipldi.com",
            Subject: 'Available load from Logistic Dynamics',
            Body: emailBody,
            IsHtml: true,
            FromAccount: "noreply@shipldi.com",
            EmailTo: [],
            EmailCC: [],
            EmailBCC: emailArray
          }

          this.$refs.emailDoc.openEmailfromSourceLoad(DraftEmail);
        }
      } else if (emailArray?.length > 50) {
        this.isMaximumCarrierSelectedDialog = true;
      } else {
        this.isCarrierSelectedDialog = true;
      }
    },
    isValidEmail(email) {
      // Regular expression for validating an email address
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    mapValidEmails(emailList) {
      let emailists = [];
      emailList.forEach((item) => {
        if (item != null && item != "") {
          if (this.isValidEmail(item)) {
            emailists.push(item);
          }
        }
      }
      )
      if (emailists.length == 0) {
        emailists.push('')
      }
      return emailists;
    },
    async getEquipments() {
      await dataProvider.getEquipmentTypes().then((res) => {
        this.equipmentList = res;
      });
    },
  },
  computed: {
    emailWidthWhenMaximized(){
      if(this.isSourceLoadPopupMaximized){
        return 'min-width: 20vw !important';
      }
      return ''
    },
    conditionalStateDropDownClass(){
      if(!this.sourceLoadData.PickupState && this.carrierSmartSearchValidationFlag){
        return 'error'
      }
      if(!this.isSourceLoadPopupMaximized){
        return 'carriersmartsrchstatedropdownMinimized'
      }
      return ''
    },
    conditionalDateWidthClass(){
      if(this.isSourceLoadPopupMaximized && this.isCarrierSmartSearch){
        return 'w-[31%]'
      }
      else if(this.isSourceLoadPopupMaximized){
        return 'w-[21%]'
      }
      else if(this.isCarrierSmartSearch){
        return 'w-640'
      }
      return 'w-400'
    },
    isCheckedAnyGroup(){
      let arr=[this.Vans,this.Specialized,this.Reefers,this.Flatbeds]
      if(arr.some(flag => flag)){
        return false
      }
      return true
    },
    computedClass(){
      if(!this.sourceLoadData.EquipmentTypeIDList.length>0 && this.GroupTypeRadio=='Type' && this.carrierSmartSearchValidationFlag){
        return 'error'
      }
      else if(this.GroupTypeRadio!='Type'){
        return 'disabled'
      }
      return ''
    },
    lockedByUserName() {
      return this.$parent.$refs.myload.customers.find(customer => customer.lockedByID === this.sourceLoadData.lockedByID).LockedByUsername
    },
    isLoadLocked() {
      if (this.sourceLoadData.lockedByID !== '' && this.sourceLoadData.lockedByID != null && this.sourceLoadData.lockedByID != 0) {
        return true;
      } else {
        return false;
      }
    },
    getPortalID() {
      return this.$store.state.login.portalID;
    },
    getHourFormat() {
      return this.$store.state.login.timeFormat;
    },
  },
  watch: {
    "$store.state.login.sourceLoadId"(id) {
      this.openSourceLoad(id);
    },
  },
};
</script>
<style lang="scss">
    .filter-field {
        width: 165px;
        height: 30px;
        border-radius: 3px;
        display: inline-flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        border: 1px solid #d3d3d3;
        padding: 2px;
        padding-right: 5px
    }

    .filter-field .icon {
        width: 14px;
        height: 14px;
        stroke: #1d4859;
        cursor: pointer
    }

    .filter-field input {
        padding: 0 .3rem;
        color: #014c5b;
        width: 130px
    }

    .filter-field input:focus {
        border: none;
        outline: none
    }

    .filter-field img {
        width: 20px;
        height: 20px;
        cursor: pointer
    }
    .carrier-name-autocomplete input {
      width:185px!important;
      border:none!important;
      margin:1px!important
    }
.card-sourceload {
  height: 280px;
  max-height: 2800px;
}

.tooltip-features .p-tooltip-text {
  color: rgb(73, 80, 87);
  background-color: whitesmoke;
}

.tooltip-features.p-tooltip-top .p-tooltip-arrow {
  border-top-color: whitesmoke;
}

.disabledField {
  cursor: default;
}
.disabled {
  opacity: 0.5 !important;
  pointer-events: none;
}
.enabledField {
  cursor: pointer;
}

.txtWidth {
  height: 37px !important;
  width: auto !important;
}

.vsWidth {
  width: 180px;
}

.bordernone {
  border: none !important;
}

.p-dialog-header {
  position:relative;
}

.p-dialog-header-close-icons {
  position:absolute;
  top:20%;
  right:1%;
}

.p-component,
.p-component * {
  font-size: 14px;
}

.source-icon {
  width: 30px;
  height: 30px;
  /*  Hidden for now until indicators are addressed*/
  display: none;
}

.w-300 {
  width: 320px;
}
.w-400 {
  width: 445px;
}
.w-640 {
  width: 640px;
}

.w-40 {
  width: 40% !important;
}

.w-20 {
  width: 20%;
}

.sourceload-body {
  .equip-width{
  width : 105px;
  }

  .dest-width{
    width :99.7px;
  }

  .city-width-minimized{
    width : 103.344px
  }
  .city-width-maximized{
    width : 180px
  }
  .state-width-minimized{
    width : 88px
  }
  .state-width-maximized{
    width : 157px
  }
 
  @mixin col($width) {
    width: $width !important;
    max-width: $width !important;
    min-width: $width !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap !important;
    cursor: text !important;
  }

  table.sourceload-table { // scope by class because of collisions with other tables

    td,
    th {
      @include col(125px);
    }

    th.sel,
    td.sel {
      @include col(40px);
    }

    th.wrk,
    td.wrk {
      @include col(70px);
    }

    th.rating,
    td.rating {
      @include col(140px);
      cursor: pointer !important;
    }

    th.email,
    td.email {
      @include col(250px);
    }

    th.phone,
    td.phone {
      @include col(100px);
    }

    th.location,
    td.location {
      @include col(180px);
    }

    th.contact,
    td.contact {
      @include col(150px);
    }

    th.mccol,
    td.mccol {
      @include col(70px);
    }

    th.usdotcol,
    td.usdotcol {
      @include col(90px);
    }

    th.loadBoardContact,
    td.loadBoardContact {
      @include col(200px);
    }

    th.loadBoardEmail,
    td.loadBoardEmail {
      @include col(250px);
    }

    th.carriercol,
    td.carriercol {
      @include col(300px);
    }

    th.email,
    td.email {
      @include col(300px);
    }

    th.source,
    td.source {
      @include col(60px);
    }

    th.age,
    td.age {
     @include col(80px);
    }

    th.carriernamecol,
    td.carriernamecol {
      @include col(200px);
    }
  }

  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
  }

  input:disabled,
  input[disabled],
  .p-button.p-disabled {
    background: #f7f7f7;
    color: #afafaf;
  }

  .p-disabled,
  .p-component:disabled {
    opacity: 0.6;
  }

  .mt-21-px {
    margin-top: 21px;
  }

  .p-dropdown {
    width: 100%;
    display: inline-flex;
    align-items: center;

    &.p-paginator-rpp-options {
      width: 70px;
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    span {
      font-size: 14px;
      color: #1d4859;
      font-weight: 400;
      font-family: "Roboto", sans-serif;
    }
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    span {
      font-weight: 500;
    }
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    padding: 0;

    div {
      padding: 15px 8px;
    }
  }

  .p-tabview .p-tabview-nav li:first-child .p-tabview-nav-link {
    padding-left: 0px;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #014c5b;
    font-weight: 500;
    border-color: #e9664d;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link:not(.p-disabled):focus {
    box-shadow: none;
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }

    .p-checkbox {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }



  .p-checkbox~label {
    font-size: 12px;
  }

  .field-wrapper {
    .blue-text-input {
      input {
        color: #65b0ba !important;
        font-weight: 500;
      }
    }

    .vs__actions {
      .vs__open-indicator {
        display: none;
      }
    }
  }

  .p-tabview-panel {
    position: relative;
  }

  .tab-upper-right-section {
    position: absolute;
    width: 55%;
    top: -75px;
    right: 0;

    .field-wrapper {
      display: flex;
      flex-direction: column;

      label {
        display: block;
      }
    }
  }

  .custom-switch-select {
    width: 150px;
    position: relative;

    .p-button {
      background: #e2e6ea;
      border: 1px solid #e2e6ea;
      padding: 0.5rem 1rem;
      height: 30px;

      span {
        font-size: 12px;
      }

      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
        position: absolute;
        right: -3px;
      }
    }

    .p-selectbutton .p-button.p-highlight {
      background: #1d4859;
      border-color: #1d4859;
      color: #ffffff;
      z-index: 1;
    }

    .p-datatable .p-datatable-tbody>tr {
      border-bottom: 1px solid #e9ecef;
    }
  }

  .filter-field {
    height: 35px;

    input {
      width: 75px;
    }

    .icon {
      stroke: #ef4d43;
      fill: #ef4d43;
      width: 18px !important;
      height: 18px !important;
    }
  }

  .mt-20 {
    margin-top: 20px;
  }

  .p-tabview .p-tabview-panels {
    padding: 0.5rem 0;
    border-top: none;
    min-height: 140px;

    .search-section-wrapper {
      // min-height: 140px; 
      height: 237px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b0b6ba;

      p {
        font-size: 20px;
        color: #707070;
      }
    }
  }

  .field-checkbox,
  .field-radiobutton {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .field-checkbox>label,
  .field-radiobutton>label {
    margin: 0;
  }

  label {
    display: inline-block;
    margin-bottom: 3px;
  }

  .required {
    color: red;
  }

  .p-button {
    background: transparent;
    border: 1px solid #cbd2d9;

    .pi {
      // color: #ef4d43;
      color: #afafaf;
    }

    &:hover {
      background: transparent;
    }
  }

  .mt-20p {
    margin-top: 19px;
  }

  .carriersmartsrchstatedropdown {
    &.p-dropdown {
      width: 150px !important;
    }
  }

.carriersmartsrchstatedropdownMinimized {
    &.p-dropdown {
      width: 111px !important;
    }
  }
  .carriersmartsrchcitydropdown {
    &.p-dropdown {
      width: 180px !important;
    }
  }
.carriersmartsrchcitydropdownMinimized {
    &.p-dropdown {
      width: 150px !important;
    }
  }
  .w-16-5{
    width:16.5% !important;
  }
  .w-13-8{
    width:13.8% !important;
  }
  .p-datatable table {
    border: 1px solid #b0b6ba;
  }

  .table-responsive .p-datatable .p-datatable-tbody>tr>td {
    border-bottom: 1px solid #e9ecef;
  }

  .p-inputswitch {
    width: 30px;
    height: 15px;

    .p-inputswitch-slider {
      border-radius: 0;
      background: #cbd2d9;
    }
  }

  .p-inputswitch {
    .p-inputswitch-slider:before {
      width: 10px;
      height: 10px;
      border-radius: 0;
      left: 4px;
      margin-top: -5px;
    }

    .p-inputswitch-slider:after {
      content: "\e90b";
      font-size: 7px;
      font-family: "primeicons";
      position: absolute;
      color: #181818;
      left: 5px;
      top: 3px;
    }

    &.p-inputswitch-checked {
      .p-inputswitch-slider:before {
        left: -4px;
        background: #014c5b;
      }

      .p-inputswitch-slider:after {
        content: "\e909";
        font-size: 5px;
        font-family: "primeicons";
        position: absolute;
        color: #fff;
        left: 18px;
        top: 3px;
      }
    }

    .p-inputswitch.p-focus,
    .p-inputswitch-slider {
      box-shadow: none;
    }
  }

  .p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #cbd2d9;
  }
}

.cancel-btn {
  color: #046a7e;
  padding: 0 10px;
}
.group-error-icon{
  width: 17px !important;
    height: 17px !important;
}
.error {
    border: 1px solid #ef4d43 !important;

  .p-dropdown,
  .p-calendar {
    border: 1px solid #ef4d43 !important;

    .p-button {
      border: none;
      border-left: 1px solid #ef4d43;
    }

    .p-inputtext {
      border: none;
    }
  }

  .p-calendar {
    border-radius: 3px;
  }
}

.theme-dark {
  .vs__dropdown-menu {
    background: #2e2f3c !important;
  }

  .Partial_checkbox {
    .p-checkbox-box {
      border: 1px solid #7a7d90 !important;
      background: #7a7d90 !important;
      pointer-events: visible !important;
    }
  }

  .disabledLabel {
    color: #7a7d90 !important;
    ;
  }


  .p-tabview .p-tabview-nav .p-tabview-nav-link {
    color: #fff !important;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    color: #fff !important;
    font-weight: 500;
    border-color: #e9664d;
  }

  .filter-field {

    background: #2e2f3c;
    border: 1px solid #2e2f3c;



    .icon {
      stroke: #ef4d43;
      fill: #ef4d43;
      width: 18px !important;
      height: 18px !important;
    }
  }

  .p-tabview .p-tabview-panels {
    padding: 0.5rem 0;
    border-top: none;
    min-height: 140px;

    .search-section-wrapper {
      min-height: 140px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #b0b6ba;

      p {
        font-size: 20px;
        color: #fff !important;
      }
    }
  }

  .theme-dark .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    color: #fff !important;
  }

  .sourceload-body .custom-switch-select .p-button span {
    color: #fff !important;
  }

  .vs__dropdown-menu li {
    color: #fff !important;
    border-bottom: 1px solid #fff !important;
    background-color: #2e2f3c !important;
  }

  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #9ca3af;
  }

  .vs__dropdown-toggle {
    background: #2e2f3c;
  }

  .cancel-btn {
    color: #fff;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: 0;
  }

  .p-dropdown-panel .p-dropdown-header {
    background: #292c2f;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    background: #292c2f;
    color: #fff;
  }

  .p-dropdown {
    border: 1px solid transparent;
  }

  .form-control {
    border: none;
    color: #fff !important;
  }

  .createload-body .p-calendar .p-button {
    background: #2e2f3c;
    border: none;
    border-left: 1px solid #5f6272;
  }

  .p-inputtext {
    background: #2e2f3c;
    border: none;
  }

  .p-tabview .p-tabview-nav {
    background: transparent;
  }

  .p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link {
    background: transparent;
  }

  .p-tabview .p-tabview-nav li .p-tabview-nav-link {
    background: transparent;

    span {
      color: #fff;
    }
  }

  .p-tabview .p-tabview-panels {
    background: transparent;
  }

  .sourceload-body .filter-field input {
    background: transparent;
    color: #fff;
  }

  .sourceload-body .filter-field .icon {
    stroke: #ef4d43;
    fill: #ef4d43;
    width: 18px !important;
    height: 18px !important;
  }

  .txtWidth {
    height: 37px;
    width: auto !important;
  }

  .carrier-name-autocomplete-responsive{
    width:100% !important;
    input {
      width:100% !important;
    }
  }

  .theme-white .filter-field .txtWidth {
    height: 37px;
    width: auto !important;
  }


  .p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link {
    background: transparent;
    // border-color: #6c757d;
    color: #fff !important;
  }

  .sourceload-body label {
    display: inline-block;
    color: #fff;
  }

  .p-calendar {
    .p-button {
      background: #2e2f3c;
      border: none;
    }
  }

  input[type="text"]:disabled {
    background: #2e2f3c;
    opacity: 0.6;
  }

  .p-dialog .p-dialog-content .form-control.border-control,
  .p-dialog .p-dialog-footer .form-control.border-control {
    border: 1px solid #2e2f3c;
    background: transparent;
  }

  .p-multiselect {
    background: #2e2f3c;
    border: none;
    width: 75%;
  }

  .vs__search,
  .vs__search:focus,
  .vs--single .vs__selected {
    color: #fff;
    padding: 0;
    margin: 0;
  }

  .select-control {
    .vs__dropdown-toggle {
      border: 1px solid #2e2f3c;
      padding: 0;
    }
  }

  tbody {
    a {
      color: #fff;
    }
  }

  .p-dropdown:not(.p-disabled):hover {
    border: 1px solid transparent;
  }

  .p-dropdown:not(.p-disabled).p-focus {
    outline: none;
    box-shadow: none;
    border: 1px solid transparent;
  }

  .custom-switch-select .p-button {
    background: #2e2f3c;
    border: 1px solid #2e2f3c;
    color: #65b0ba;
  }

  .p-selectbutton .p-button:not(.p-disabled):not(.p-highlight):hover {
    background: #2e2f3c;
    border: 1px solid #2e2f3c;
  }

  .custom-switch-select .p-selectbutton .p-button.p-highlight {
    background: #65b0ba;
    border-color: #65b0ba;
  }

  .table-responsive .p-datatable .p-datatable-wrapper>table {
    border: 1px solid #5f6272 !important;
  }
}

.table-responsive .p-datatable .p-datatable-wrapper {
  overflow: auto;
  max-height: 45vh;
}

.theme-white {
  .sourceload-body {
    .filter-field .icon {
      stroke: #ef4d43 !important;
      fill: #ef4d43 !important;
      width: 18px !important;
      height: 18px !important;
    }

    .table-responsive .p-datatable .p-datatable-thead>tr>th {
      text-align: left;
      background: #6C757D !important;
      color: #fff !important;
      border: 1px solid #6C757D !important;
    }

    .p-datatable.p-datatable-sm .p-datatable-thead tr {
      background: #6C757D !important;
    }

    .p-datatable-scrollable .p-datatable-thead>tr>th,
    .p-datatable-scrollable .p-datatable-tbody>tr>td,
    .p-datatable-scrollable .p-datatable-tfoot>tr>td {
      display: inline-flex;
      flex: none !important;
      width: 130px;
    }

  }
}

.vs--single .vs__selected {
  width: 100%;
}

.select-control {
  .vs__dropdown-toggle {
    display: flex;
    justify-content: space-between;
  }

  .vs__selected-options {
    width: 100%;
    height: 33px;
  }

  img {
    height: auto;
    max-width: 2.5rem;
    margin-right: 1rem;
  }

  .d-center {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .v-select .dropdown li {
    border-bottom: 1px solid rgba(112, 128, 144, 0.1);
  }

  .v-select .dropdown li:last-child {
    border-bottom: none;
  }

  .v-select .dropdown li a {
    padding: 10px 20px;
    width: 100%;
    font-size: 1.25em;
    color: #3c3c3c;
  }

  .v-select .dropdown-menu .active>a {
    color: #fff;
  }

  .vs__spinner,
  .vs__spinner:after {
    width: 15px;
    height: 15px;
  }

  .vs__spinner {
    border: 2px solid #ccc;
    border-left-color: #464646;
  }

  .vs__dropdown-toggle {
    padding: 0;
    border: none;
  }
}

.w-80px {
  width: 80px;
}

.p-rating .p-rating-icon {
  margin-left: 4px !important;
  font-size: 14px;
  margin-right: 4px;
}

.p-rating .p-rating-icon.pi-star-fill {
  color: #FFD75E;
  font-size: 14px;
  margin-left: 0;
}
.p-rating{
  gap: 0px !important;
}
.p-rating .p-rating-item .p-rating-icon {
    font-size: 0.85rem;
}
.N {
  color: #f0664e;
}

.Y {
  color: #62b86b;
}

.Yellow {
  color: #ffcc00;
}

.v-select.vs--single.select-control {
  position: relative;

  .pi {
    position: absolute;
    right: 7px;
    top: 10px;

    &.pi-search {
      color: #f0664e;
    }
  }

  .vs__clear {
    display: none;
  }

  &.vs--open,
  &.vs--searching {
    .pi {
      display: none;
    }
  }
}

.p-datatable-thead {
  .p-checkbox {
    display: none;
  }
}

body {
  .p-checkbox .p-checkbox-box {
    border: 1px solid #4b4b4b;
  }

  .strikethrough {
    position: relative;
    overflow: hidden;

    td {
      &:before {
        content: " ";
        position: absolute;
        top: 50%;
        left: 110px;
        border-bottom: 1px solid #000;
        width: 100%;
      }
    }
  }
}

.v-select.vs--single.select-control.vs--open .pi.pi-search {
  display: block;
  width: 180px;
}

.field-wrapper {

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 1;
  }
}

.p-input-icon-right>i:last-of-type {
  right: 0.5rem;

  color: #e96767 !important;
}

.scroll-hidden {
  overflow: hidden;
}

.sourceload-body .date-icon .p-button .pi {
  color: #ef4d43;
}

.vs__dropdown-menu {
  padding: 0;
}

.sourceload-body .p-component:disabled,
.sourceload-body .p-disabled {
  opacity: 1;
}

.blink_me {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.errorMonths {
  border: 1px solid #ef4d43;
}

.vs__dropdown-menu li {
  border-bottom: 1px solid rgb(167, 163, 163);
  width: 500px;
  border-collapse: collapse;
}

.carrierNameclass {
  .vs__dropdown-menu li {
    display: none;
  }
}

.Favclass {
  .vs__dropdown-menu li {
    display: none;
  }
}

.vs--loading {
  .pi.pi-search {
    display: none;
  }
}

.vs__dropdown-menu {
  li {
    text-align: left;
  }
}

.Partial_checkbox {
  .p-checkbox-box {
    border: 2px solid #ced4da !important;
    background: #f7f7f7 !important;
    pointer-events: visible !important;
  }

}

.team-wrapper {
  .p-checkbox .p-checkbox-box .p-checkbox-icon {
    color: #afafaf !important;
    font-size: 11px;
  }
}

.tooltip-souceLoadCarrier .p-tooltip-text {
  color: rgb(73, 80, 87);
  background-color: whitesmoke;
}

.tooltip-souceLoadCarrier.p-tooltip-left .p-tooltip-arrow {
  border-left-color: whitesmoke;
}

.tooltip-souceLoadCarrier.p-tooltip-right .p-tooltip-arrow {
  border-right-color: whitesmoke;
}

.tooltip-souceLoadCarrier.p-tooltip-right .p-tooltip-arrow {
  border-right-color: whitesmoke;
}

.errortext {
  color: #65b0ba;
  font-size: 10px !important;
  position: absolute;
  bottom: -25px !important;
  left: 0;
}

.pos-r {
  position: relative;
}

.tab-focus:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #a6d5fa;
}

.carrier-select-dialog {
  .p-dialog-footer {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;

    aside {
      margin-right: auto;
      margin-top: 7px;
    }
  }
}

.carrier-name-autocomplete-responsive{
  width:100% !important;
  input {
    width:100% !important;
  }
}

.p-calendar{
  width: 100%;
}

.sourceloadpopupResponsive{
  overflow: auto;
  .p-dialog-content{
    height:100%;
  }
  .p-dialog-header-close-icons {
  position:absolute;
  top:20%;
  right:10px;
 }
 .p-dialog-header-icons{
  position: absolute;
  right:50px;
 }
 }

.carrier-smart-typecheckbox{
  display: inline-flex;
  gap: 5%;
  padding-top: 3%;
}
</style>
