import axios from "axios";
import { store } from "@/store/store.js";
// Base axios config object
const axiosConfig = axios.create({
  // withCredentials: true,
  headers: {
    Accept: "application/json",
    credentials: "same-origin",
    "Content-Type": "application/json",
  },
});

// Promise is used when multiple reqeuests are sent out to stop multiple refresh token calls
// (this causes tokens to be immeaditly revoked on creation)
let refreshTokenPromise;
const getRefreshToken = () =>
  axiosConfig.post(`${process.env.VUE_APP_API_AUTH_URL}/WebAuth/refresh`, {
    code: `Bearer ${store.state.login.refreshToken}`,
  });
// Use request interceptor to attach access token from Vuex store
axiosConfig.interceptors.request.use(
  (config) => {
    if (store.state.login.authed === true) {
      config.headers["Authorization"] = `Bearer ${store.state.login.token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
// Use response interceptor for API calls to fetch new access token if existing is invalid
axiosConfig.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var errorMessage = "";
    if (error.message == "Network Error") {
      errorMessage = "API server not responding";
      alert(errorMessage);
    }
    if (error.response.status == 400) {
      return error.response;
    }
    //handle API error -Internal server error 500
    if (error.response.status == 500) {
      
      // if(process.env.NODE_ENV === 'development')
      // {
      //   console.log(error.response.data);
      // }
      //alert(error.response.data.ErrorMessage);
      errorMessage =
        "Internal server error on API: " +
        error.response.config.url +
        " \n\n error details: " +
        error.response.data;
        alert(errorMessage);
      
    }
    const originalRequest = error.config;
    // Check to prevent infinite loop of requests
    if (
      error.response.status === 401 &&
      originalRequest.url === `${process.env.VUE_APP_API_AUTH_URL}/WebAuth/refresh`
    ) {
      return Promise.reject(error);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // if the refreshTokenPromise obj is null, create a new instance.
      if (!refreshTokenPromise) {
        refreshTokenPromise = getRefreshToken().then((response) => {
          refreshTokenPromise = null;
          return response;
        });
      }

      // otherwise return the existing instance
      return refreshTokenPromise
        .then((response) => {
          store.commit("login", {
            username: response.data.username,
            firstName: response.data.firstName,
            lastName: response.data.lastName,
            portalID: response.data.portalID,
            portalOwner:response.data.portalOwner,
            employeeID: response.data.employeeID,
            emailID:response.data.emailID,
            token: response.data.token,
            authed: true,
          });
          return axiosConfig(originalRequest);
        })
        .catch((err) => {
          return Promise.reject(err);
        });
    }
    return Promise.reject(error);
  }
);

export default axiosConfig;
