import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import login from "@/store/modules/login";
import bookeditgeneral from "./modules/bookedit-general";

let modules = {
  login,
  bookeditgeneral,
};
// Only persist login state
let plugins = [
  createPersistedState({
    paths: [
      "login.role",
      "login.MenuList",
      "login.userName",
      "login.firstName",
      "login.lastName",
      "login.employeeID",
      "login.portalID",
      "login.portalOwner",
      "login.emailID",
      "login.authed",
      "login.token",
      "login.refreshToken",
      "login.refreshTokenAD",
      "login.theme",
      "login.stateHref",
      "login.timeFormat",
      "login.portalOwner",
    ],
  }),
];

let store = createStore({
  modules,
  plugins,
});

export { store, modules, plugins };
