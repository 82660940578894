import { dataProviderAAD } from "@/api/dataProviderAAD";

const login = {
  namespaced: true,
  state: () => ({
    role: null,
    MenuList: [],
    firstName: null,
    lastName: null,
    employeeID: null,
    emailID:null,
    portalID: null,
    portalOwner:false,
    authed: false,
    token: "",
    refreshTokenAD: "",
    theme: null,
    isSidebarVisible: false,
    isShowCreateLoad: false,
    sourceLoadId: "",
    isLoadRefresh: false,
    cities: [],
    states: [],
    timeFormat: null,
    isShowNewDriver:false,
    userName: null,
  }),

  mutations: {
    setLogin(state, payload) {
      (state.role = payload.role),
        (state.MenuList = payload.MenuList),
        (state.userName = payload.userName),
        (state.firstName = payload.firstName),
        (state.lastName = payload.lastName),
        (state.emailID = payload.emailID),
        (state.employeeID = payload.employeeID),
        (state.portalID = payload.portalID),
        (state.portalOwner= payload.portalOwner),
        (state.authed = payload.authed),
        (state.token = payload.token),
        (state.refreshTokenAD = payload.refreshTokenAD);
      state.theme = payload.themecolor;
      state.timeFormat = payload.timeFormat;
    },

    clearLogin(state) {
      state.role = "";
      state.MenuList = "";
      state.userName = "";
      state.firstName = "";
      state.lastName = "";
      state.emailID = "";
      state.employeeID = "";
      state.portalID = "";
      state.portalOwner = "";
      state.authed = false;
      state.token = "";
      state.refreshTokenAD = "";
      // state.theme = "";
    },
    setTheme(state, payload) {
      state.theme = payload.theme;
    },
    setTime(state, payload) {
      state.timeFormat = payload.timeFormat;
    },
    toggleSidebar(state, payload) {
      state.isSidebarVisible = payload.isSidebarVisible;
    },
    closeSidebar(state, payload) {
      state.isSidebarVisible = payload.isSidebarVisible;
    },
    openCreateLoad(state) {
      state.isShowCreateLoad = true;
    },
    closeCreateLoad(state, payload) {
      state.isShowCreateLoad = false;
      state.isLoadRefresh = payload;
    },
    openNewDriver(state) {
      state.isShowNewDriver = true;
    },
    closeNewDriver(state){
      state.isShowNewDriver = false;
    },
    openSourceLoadPopup(state, payload) {
      state.sourceLoadId = payload;
    },
    setCities(state, payload) {
      state.cities = payload;
    },
    setStates(state, payload) {
      state.states = payload;
    },
  },

  actions: {
    async TryLoginWithRefreshToken({ commit, state }) {
      //get access token using refresh token
      let refreshTokenAD = state.refreshTokenAD;
      if (refreshTokenAD == null || refreshTokenAD == "") {
        return false;
      }
      var reqBody = {
        code: refreshTokenAD,
      };
      var response = await dataProviderAAD.requestAccessToken(
        reqBody,
        "refresh_token"
      );

      refreshTokenAD = response.refreshTokenAD;

      if (response.token == null || response.token == "") {
        return false;
      }

      let payload = {
        role: response.role,
        MenuList: response.MenuList,
        userName: response.userName,
        firstName: response.firstName,
        lastName: response.lastName,
        portalID: response.portalID,
        portalOwner:response.portalOwner,
        emailID:response.emailID,        
        employeeID: response.employeeID,
        token: response.token,
        refreshTokenAD: refreshTokenAD,
        refreshToken: response.refreshToken,
        authed: true,
        themecolor: response.EmployeeSetting[0].SettingValue,
        timeFormat: response.EmployeeSetting[1].SettingValue,
      };

      commit("setLogin", payload);
      return true;
    },
    async TryLoginWithAuthCode({ commit, state }, authorizationCode) {
      var stateHref = "";
      stateHref = state.stateHref;
      if (authorizationCode == "") {
        let authorizationCodeUrl =
          await dataProviderAAD.getAuthorizationCodeUrl(stateHref);
        window.open(authorizationCodeUrl, "_self");
        return true;
      } else if (authorizationCode != null && authorizationCode != "") {
        //get access token
        var reqBody = {
          code: authorizationCode,
        };
        var response = await dataProviderAAD.requestAccessToken(
          reqBody,
          "authorization_code"
        );
        if (response.status == 400) {
          return response;
        }
        if (response.token == null || response.token == "") {
          return false;
        }

        const payload = {
          role: response.role,
          MenuList: response.MenuList,
          token: response.token,
          emailID:response.emailID,
          userName: response.userName,
          firstName: response.firstName,
          lastName: response.lastName,
          portalID: response.portalID,
          portalOwner:response.portalOwner,
          employeeID: response.employeeID,
          refreshToken: response.refreshToken,
          refreshTokenAD: response.refreshTokenAD,
          authed: true,
          themecolor: response.EmployeeSetting[0].SettingValue,
          timeFormat: response.EmployeeSetting[1].SettingValue,
        };

        commit("setLogin", payload);

        return true;
      } else {
        return false;
      }
    },
    updateTheme({ commit }, color) {
      let payload = {
        theme: color,
      };
      commit("setTheme", payload);
    },
    updateTime({ commit }, time) {
      let payload = {
        timeFormat: time,
      };
      commit("setTime", payload);
    },
    toggleSidebar({ commit }, isSidebarVisible) {
      let payload = {
        isSidebarVisible: isSidebarVisible,
      };
      commit("toggleSidebar", payload);
    },
    closeSidebar({ commit }, isSidebarVisible) {
      let payload = {
        isSidebarVisible: isSidebarVisible,
      };
      commit("closeSidebar", payload);
    },
  },
  getter: {
    isAuthed(state) {
      return state.authed == true ? true : false;
    },
    theme(state) {
      return state.theme;
    },
  },
};

export default login;
