import axiosConfig from "@/api/axiosConfig.js";
const dataProviderAAD = {
  async getAuthorizationCodeUrl(stateHref) {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_AUTH_URL}/WebAuth/authCodeUrl?redirectUri=${process.env.VUE_APP_REDIRECT_URI}&state=${stateHref}`)
      .then((res) => res.data);

    return result;
  },
  async logoutUrl() {
    let result = await axiosConfig
      .get(`${process.env.VUE_APP_API_AUTH_URL}/WebAuth/logoutUrl?redirectUri=${process.env.VUE_APP_REDIRECT_URI}`)
      .then((res) => res.data);
    return result;
  },
  async requestAccessToken(reqBody, grantType) {
    var result = "";
    await axiosConfig
      .post(
        `${process.env.VUE_APP_API_AUTH_URL}/WebAuth/token?grantType=${grantType}&redirectUri=${process.env.VUE_APP_REDIRECT_URI}`,                              
        reqBody
      )
      .then((res) => {
        if (res.status == 400) {
          result = res;
        } else {
          result = res.data;
        }
      });

    return result;
  },
};
export { dataProviderAAD };
