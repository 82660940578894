<!-- All router-link props are supported using passthrough -->
<template>
    <router-link
        v-bind="$props"
        custom
        v-slot="{ href }"
    >
        <a
            v-bind="$attrs"
            :href="href"
            @click="navigationHandler"
        >
            <slot />
        </a>
    </router-link>
</template>
<script>
import { RouterLink, useLink } from 'vue-router'

export default {
    name: 'CustomRouterLink',
    inheritAttrs: false,

    components: {
        RouterLink
    },

    props: {
        ...RouterLink.props,
        newTab: Boolean,
    },

    setup(props) {
        const { navigate, href } = useLink(props);

        function navigationHandler(event) {
            // In order to have a reference to the original window (window.opener), new tabs must be opened with window.open()
            // instead of just using <router-link target="_blank"> - #7387
            if (props.newTab) {
                event.preventDefault();
                window.open(href.value, "_blank");
            } else {
                navigate(event);
            }
        }

        return { navigationHandler }
    }
}
</script>