import moment from "moment";

//two methods one returns as a string version of the date and one as and actual date needed for create loads screen currently.
class DateHelpers {
    formatDate(dt) {
        this.type = 'text';
        var input = dt.value;

        var values = input.substring(0, 10).split('/').map(function (v) {
            return v.replace(/\D/g, '')
        });
        var output = '';
 
        if (values.length == 3) {
            var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
            var month = parseInt(values[0]) - 1;
            var day = parseInt(values[1]);
            var d = new Date(year, month, day);
            if (!isNaN(d)) {

                var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
                output = dates.map(function (v) {
                    v = v.toString();
                    return v.length == 1 ? '0' + v : v;
                }).join('/')
            }
        }

        return output;
     }
     formatDateAsDate(dt) {         
         this.type = 'text';
         var input = dt.value;
         if(input === '') {
            return;
         }

         var values = input.substring(0, 10).split('/').map(function (v) {
             return v.replace(/\D/g, '')
         });
         var output = '';

         if (values.length == 3) {
             var year = values[2].length !== 4 ? parseInt(values[2]) + 2000 : parseInt(values[2]);
             var month = parseInt(values[0]) - 1;
             var day = parseInt(values[1]);
             var d = new Date(year, month, day);
             if (!isNaN(d)) {

                 var dates = [d.getMonth() + 1, d.getDate(), d.getFullYear()];
                 output = dates.map(function (v) {
                     v = v.toString();
                     return v.length == 1 ? '0' + v : v;
                 }).join('/')
             }
         }
         //have to do this here if it isnt a date just an empty string return it if it is a date return it as with the time component because emtechs logic checks the time for validation and will kick an 
         //error on the create load section it is used in.
         if (output !== '') {
             return new Date(output);
         } else {
             return  output
         }
        
    }
    AutoAddSlashes(e) {
        if (e.which !== 8) {
            var numChars = e.target.value.length;

            if ((numChars === 2 || numChars === 5) && e.target.value[1] !== '/') {
                var thisVal = e.target.value;
                thisVal += '/';
                e.target.value = thisVal;
            }
        }
    }
    // Used for getting array of individual dates
    getDateForDelta(date, days) {
        if (days > 0) {
            return moment(date).add(Math.abs(days), 'days').toDate();
        } else {
            return moment(date).subtract(Math.abs(days), 'days').toDate();
        }
    }
    getAllDatesForDelta(date, days) {
        let dates = [];
        for (let i = 0; i < Math.abs(days); i++) {
            if (days > 0) {
                dates.push(moment(date).add(i, 'days').toDate());
            } else {
                dates.push(moment(date).subtract(i, 'days').toDate());
            }
        }
        return dates;
    }
    getAllDatesOf(unit) {
        let [start, end] = this.getStartEndDateOf(unit);
        start = moment(start);
        end = moment(end);
        let dates = [start];
        while(!moment(dates.slice(-1)[0]).add(1, 'days').isAfter(end)) {
            dates.push(moment(dates.slice(-1)[0]).add(1, 'days'));
        }
        return dates.map(d => d.toDate());
    }
    // Used for getting a start and end date (range)
    getStartEndDateOf(unit) {
        let start, end;
        if (unit === 'last week') {
            start = moment().subtract(1, 'weeks').startOf('week');
            end = moment().subtract(1, 'weeks').endOf('week');
        } else if (unit === 'next week') {
            start = moment().add(1, 'weeks').startOf('week');
            end = moment().add(1, 'weeks').endOf('week');
        } else if (unit === 'last month') {
            start = moment().subtract(1, 'months').startOf('month');
            end = moment().subtract(1, 'months').endOf('month');
        } else if (unit === 'next month') {
            start = moment().add(1, 'months').startOf('month');
            end = moment().add(1, 'months').endOf('month');
        } else if (unit === 'last year') {
            start = moment().subtract(1, 'years').startOf('year');
            end = moment().subtract(1, 'years').endOf('year');
        } else if (unit === 'next year') {
            start = moment().add(1, 'years').startOf('year');
            end = moment().add(1, 'year').endOf('year');
        } else {
            start = moment().startOf(unit);
            end = moment().endOf(unit);
        }
        return [start, end].map(d => d.toDate());
    }
    getNthWeekdayOfMonth(year, month, dayOfWeek, nth, offset = 0) {
        const date = new Date(year, month, 1);
        date.setDate((1 + (7 + dayOfWeek - date.getDay()) % 7) + (nth - 1) * 7 + offset);
        return date;
    }
    getLastDayOfMonth(year, month, day) {
        var lastDay = new Date(year, month + 1, 0);
        if (lastDay.getDay() < day) {
            lastDay.setDate(lastDay.getDate() - 7);
        }
        lastDay.setDate(lastDay.getDate() - (lastDay.getDay() - day));
        return lastDay;
    }
    getHolidayDates(year) {
        // calc base holidayDates
        let holidayDates = [
            // New Year's Day - Jan 1
            new Date(year, 0, 1),
            // Memorial Day - Last Monday in May
            this.getLastDayOfMonth(year, 4, 1),
            // ID4 - July 4
            new Date(year, 6, 4),
            // Labor Day - 1st Monday in Sept.
            this.getNthWeekdayOfMonth(year, 8, 1, 1),
            // Thanksgiving Day - 4th Thurs in Nov.
            this.getNthWeekdayOfMonth(year, 10, 4, 4),
            // Day after Thanksgiving
            this.getNthWeekdayOfMonth(year, 10, 4, 4, 1),
            // Christmas Eve
            new Date(year, 11, 24),
            // Christmas Day
            new Date(year, 11, 25),
            // NYE
            new Date(year, 11, 31)
        ];
        // loop through dates and check if any fall on a weekend and shift ether forwards or backwards
        // Sunday (0) goes to Monday (+1) Saturday (6) goes to Friday (-1)
        let dayOfWeekOffset = holidayDates.map(x => x.getDay() === 0 ? 1 : x.getDay() === 6 ? -1 : 0);
        holidayDates.forEach((x, idx) => x.setDate(x.getDate() + dayOfWeekOffset[idx]));
        return holidayDates;
    }
    getWeekendDatesForYear(currentYear) {
        const weekendDates = [];
        const currentDate = new Date(currentYear, 0, 1); // Start from January 1st of the current year

        while (currentDate.getFullYear() === currentYear) {
            if (currentDate.getDay() === 0 || currentDate.getDay() === 6) {
                // 0 represents Sunday, and 6 represents Saturday
                weekendDates.push(new Date(currentDate));
            }
            currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
        }

        return weekendDates;
    }
}
export default new DateHelpers();