<template>
    <div>
        <Toast position="top-center" group="noti" class="system-notification-wrapper">
            <template #message="slotProps">
                <svg class="alert-icon">
                    <use href="/svg/icons.svg#Alert icon" xlink:href="/svg/icons.svg#Alert icon"></use>
                </svg>
                <div class="toast-message">{{slotProps.message.summary}}</div>  
                <svg class="close-icon" @click="onClose">
                    <use href="/svg/icons.svg#Cross" xlink:href="/svg/icons.svg#Cross"></use>
                    <title>Close</title>
                </svg>
            </template>
        </Toast>
    </div>
</template>

<script>
import Toast from 'primevue/toast';
export default {
    name:'NotificationAlert',
    components:{
        Toast
    },
    methods:{
        onClose(){
            this.$emit("closeAlert")
            this.$toast.removeGroup("noti")
        }
    }
}
</script>

<style lang="scss">
.system-notification-wrapper{
   width:fit-content;
   &.p-toast{
       width:fit-content;
        .p-toast-message {
            margin:none;
            box-shadow:none;
        &.p-toast-message-warn{
            border: none;
            border-radius: 4px;
            color:#fff;
            margin:none;
        .p-toast-message-content{
            border: none;
            border-radius: 4px;
            background:#E74C42;
            color:#fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding:4px 10px 4px 10px;
            .alert-icon{
                    width: 20px;
                    height: 20px;
                    display: inline-block;
            }
            .toast-message{
                margin:0 10px 0 10px;
                font-size: 14px;
            }
            .close-icon{
                width:12px;
                height: 12px;
                cursor:pointer;
            }
            .p-toast-icon-close{
                display:none;
            }
        }
        }
    }
   }
}
</style>