class Constants {
    Max_Location_Capacity = 25;

    Pickup = "pickup";

    Drop = "drop";

    Portals = Object.freeze({
        LDI_CORPORATE: 1,
        CINDY_LOU_LLC: 1036
    });

    packingGroupOptions = Object.freeze([
        { label: 'I', value: 'I' },
        { label: 'II', value: 'II' },
        { label: 'III', value: 'III' },
        { label: 'N/A', value: 'NONE' }
      ]);
    MasterStatusArray=[
        { name: "Posting", id: "9" },
        { name: "Available", id: "0" },
        { name: "Covered", id: "1" },
        { name: "Dispatched", id: "8" },
        { name: "In-Transit", id: "2" },
        { name: "Delivered", id: "3" },
        { name: "Cancelled", id: "5" },
        { name: "Claims", id: "6" },
        { name: "On Hold", id: "7" }      
      ];
}

export default new Constants();