<template>
  <Dialog
    v-model:visible="isShowCreateLoad"
    :modal="true"
    maximizable
    :closable="false"
    class="theme-dialog createLoadPopupResponsive h-auto"
    @maximize="{isMaximized = true;}"
    @unmaximize="{isMaximized = false;}"
  >
    <template #header>
      <span id="pv_id_7_header" class="p-dialog-title">Create Load</span>
      <div class="p-dialog-custome-header-icons">
        <button
          class="p-dialog-header-icon p-dialog-header-close p-link"
          @click="close"
          type="button"
        >
          <span class="p-dialog-header-close-icon pi pi-times"></span>
        </button>
      </div>    
    </template>
    <div class="confirmation-content createload-body">
      <p class="mb-2">
        Enter load details, then select an action or click cancel...
      </p>
      <label for="Customer">Customer</label>
      <div class="flex items-center gap-3 mb-2">
        <div class="lg:w-5/12">
          <div id="customerFilter">
            <Dropdown
              v-model="selectedCustomer"
              :loading="customerLoading"
              :options="customersList"
              optionLabel="CustomerName"
              placeholder="Select"
              autoFilterFocus
              :filter="true"
              :showClear="true"
              :dataKey="CustomerName"
              ref="customerFilter"
              @before-show="clearFilterSearch(1)"
              class="no-search-icon"
              @change="changeCustomer($event)"
            >
              <template #option="slotProps">
                <div
                  class="p-dropdown-car-option"
                  :key="slotProps.CustomerName"
                >
                  <span>{{ slotProps.option.CustomerName }}</span>
                </div>
              </template>
              <template #header>
                <div class="carrier-multiseleect-header">
                  <svg
                    class="p-header-close-icon"
                    @click="clearFilterSearch(1)"
                  >
                    <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="flex lg:w-7/12">
          <div
            v-for="type of typeRadioButtonList"
            :key="type.value"
            class="field-radiobutton"
          >
            <RadioButton
              :id="type.value"
              name="types"
              :value="type.value"
              v-model="typeSelected"
              :disabled="type.value === '2' || type.value === '3'"
            />
            <label :for="type.value">{{ type.name }}</label>
          </div>
        </div>
      </div>
      <div class="flex gap-3 mb-2 w-12">
        <div
          class="field-wrapper w-[20%]"
          :class="{ clerror: submitted && v$.saveform.selectedPickupCity.$error }"
        >
          <label>Pickup City<span class="required">*</span></label>
          <Dropdown
            ref="cityFilter"
            v-model="v$.saveform.selectedPickupCity.$model"
            @before-show="clearFilterSearch(2)"
            :loading="pickupCityStateLoading"
            :options="pickupCities"
            optionLabel="City"
            autoFilterFocus
            optionValue="City"
            id="PickupCityID"
            placeholder="Select a City"
            :filter="true"
            :filterMatchMode="'startsWith'"
            :showClear="true"
          :virtualScrollerOptions="{ itemSize: 42 }"
            class="citydropdown no-search-icon"
            @change="changePickupCity($event)"
          >
            <template v-slot:loader="{ options }">
              <div class="flex align-items-center p-2" style="height: 38px">
                <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
              </div>
            </template>
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.City }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(2)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          class="field-wrapper w-[12%]"
          :class="{
            clerror: submitted && v$.saveform.selectedPickupState.$error,
          }"
        >
          <label>State<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Dropdown
            ref="pickstatefilter"
            v-model="v$.saveform.selectedPickupState.$model"
            :loading="pickupCityStateLoading"
            :options="pickupstatesList"
            optionLabel="State"
            autoFilterFocus
            optionValue="State"
            :dataKey="State"
            :filter="true"
            placeholder="Select"
            class="statedropdown"
            :showClear="true"
            @before-show="clearFilterSearch(5)"
            @change="changePickupState($event)"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.State }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(5)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
          </div>
        </div>
        <div
          class="field-wrapper"
          :class="{ clerror: v$.saveform.pickupdate.$error , 'w-[20%]': !isMaximized, 'w-[11%]':isMaximized }"
        >
          <label for="icon">Date<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="icon"
            v-model="v$.saveform.pickupdate.$model"
            :showIcon="true"
            placeholder="MM/DD/YYYY"
            :showOtherMonth="true"
            :selectOtherMonths="true"
            @date-select="pastDateValidation()"
            @keypress="isNumberSlash($event)"
            @blur="handlePickupDateFormat($event)"
            :showOnFocus="false"
          />
          </div>
        </div>

        <div class="field-wrapper" 
        :class="{ clerror: v$.saveform.pickupstarttime.$error, 'w-[19%]': !isMaximized, 'w-[11%]':isMaximized }" 
        v-if="getHourFormat == '24hrs'">
          <label>Start Time<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time1"
            v-model="v$.saveform.pickupstarttime.$model"
            :timeOnly="true"
            hourFormat="24"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validatePickupStartTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper" 
        :class="{ clerror: v$.saveform.pickupstarttime.$error, 'w-[19%]': !isMaximized, 'w-[11%]':isMaximized }" 
        v-if="getHourFormat == '12hrs'">
          <label>Start Time<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time1"
            v-model="v$.saveform.pickupstarttime.$model"
            :timeOnly="true"
            hourFormat="12"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validatePickupStartTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper"
        :class="{'w-[19%]': !isMaximized, 'w-[11%]':isMaximized}"
         v-if="getHourFormat == '24hrs'">
          <label>End Time</label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time2"
            v-model="pickupendtime"
            :timeOnly="true"
            hourFormat="24"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validatePickupEndTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper"
        :class="{'w-[19%]': !isMaximized, 'w-[11%]':isMaximized}"
        v-if="getHourFormat == '12hrs'">
          <label>End Time</label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time2"
            v-model="pickupendtime"
            :timeOnly="true"
            hourFormat="12"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validatePickupEndTime($event)"
          />
          </div>
        </div>
      </div>
      <div class="flex gap-3 mb-2 w-12">
        <div
          class="field-wrapper w-[20%]"
          :class="{
            clerror: submitted && v$.saveform.selectedDestinationCity.$error,
          }"
        >
          <label>Drop City<span class="required">*</span></label>
          <Dropdown
            ref="dropCityFilter"
            @before-show="clearFilterSearch(3)"
            v-model="v$.saveform.selectedDestinationCity.$model"
            :loading="destinationCityStateLoading"
            :options="destinationCities"
            optionLabel="City"
            autoFilterFocus
            :dataKey="City"
            id="destCityID"
            optionValue="City"
            placeholder="Select a City"
            :filter="true"
            :filterMatchMode="'startsWith'"
            :showClear="true"
              :virtualScrollerOptions="{ itemSize: 42 }"
            class="citydropdown no-search-icon"
            @change="changeDestinationCity($event)"
          >
            <template v-slot:loader="{ options }">
              <div class="flex align-items-center p-2" style="height: 38px">
                <Skeleton :width="options.even ? '60%' : '50%'" height="1rem" />
              </div>
            </template>
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.City }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(3)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
        </div>
        <div
          class="field-wrapper w-[12%]"
          :class="{
            clerror: submitted && v$.saveform.selectedDestinationState.$error,
          }"
        >
          <label>State<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Dropdown
            v-model="v$.saveform.selectedDestinationState.$model"
            :loading="destinationCityStateLoading"
            :options="destinationstatesList"
            :dataKey="State"
            optionLabel="State"
            optionValue="State"
            placeholder="Select"
            autoFilterFocus
            class="statedropdown"
            :filter="true"
            :showClear="true"
            ref="dropstatefilter"
            @before-show="clearFilterSearch(6)"
            @change="changeDestinationState($event)"
          >
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <div>{{ slotProps.value }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div>
                <div>{{ slotProps.option.State }}</div>
              </div>
            </template>
            <template #header>
              <div class="carrier-multiseleect-header">
                <svg class="p-header-close-icon" @click="clearFilterSearch(6)">
                  <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                </svg>
              </div>
            </template>
          </Dropdown>
          </div>
        </div>
        <div
          class="field-wrapper"
          :class="{ clerror: v$.saveform.destinationdate.$error, 'w-[20%]': !isMaximized, 'w-[11%]':isMaximized }"
        >
          <label for="icon">Date<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="icon"
            v-model="v$.saveform.destinationdate.$model"
            :showIcon="true"
            placeholder="MM/DD/YYYY"
            :showOnFocus="false"
            :showOtherMonth="true"
            :selectOtherMonths="true"
            :minDate="v$.saveform.pickupdate.$model"
            @date-select="comparePickupDestinationDate()"
            @keypress="isNumberSlash($event)"
            @blur="handleDestinationDateFormat($event)"
          />
          </div>
        </div>

        <div class="field-wrapper"
        :class="{ clerror: v$.saveform.destinationstarttime.$error, 'w-[19%]': !isMaximized, 'w-[11%]':isMaximized }" 
        v-if="getHourFormat == '24hrs'">
          <label>Start Time<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time3"
            v-model="v$.saveform.destinationstarttime.$model"
            :timeOnly="true"
            hourFormat="24"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validateDestinationStartTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper" 
        :class="{ clerror: v$.saveform.destinationstarttime.$error, 'w-[19%]': !isMaximized, 'w-[11%]':isMaximized }" 
        v-if="getHourFormat == '12hrs'">
          <label>Start Time<span class="required">*</span></label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time3"
            v-model="v$.saveform.destinationstarttime.$model"
            :timeOnly="true"
            hourFormat="12"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validateDestinationStartTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper" v-if="getHourFormat == '24hrs'"
        :class="{'w-[19%]': !isMaximized, 'w-[11%]':isMaximized}">
          <label class="block">End Time</label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time4"
            v-model="destinationendtime"
            :timeOnly="true"
            hourFormat="24"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validateDestinationEndTime($event)"
          />
          </div>
        </div>
        <div class="field-wrapper" v-if="getHourFormat == '12hrs'"
        :class="{'w-[19%]': !isMaximized, 'w-[11%]':isMaximized}">
          <label>End Time</label>
          <div class="field-tooltip-wrapper">
          <Calendar
            id="time4"
            v-model="destinationendtime"
            :timeOnly="true"
            hourFormat="12"
            :showIcon="true"
            icon="pi pi-clock"
            placeholder="HH:MM"
            @blur="validateDestinationEndTime($event)"
          />
          </div>
        </div>
      </div>
      <div class="flex gap-3 mb-2 w-12">
          <div class="field-wrapper w-[20%]">
            <label>Commodity</label>
            <div class="field-tooltip-wrapper">
            <input
              type="text"
              class="form-control w-170"
              placeholder="Enter Commodity"
              v-model="commodity"
              maxlength="50"
              @keypress="spaceValidation(evt)"
              @keydown.space="preventLeadingSpace"
            />
            </div>
          </div>
        <div class="flex gap-3 w-[62%]">
          <div
            class="field-wrapper w-[20%]"
            :class="{ clerror: v$.saveform.weight.$error }"
          >
            <label>Weight<span class="required">*</span></label>
            <div class="field-tooltip-wrapper">
              <input
                maxlength="6"
                type="text"
                :class="[
                  isMaxWeight ? 'form-control weightError' : 'form-control',
                ]"
                placeholder="Weight"
                v-model="v$.saveform.weight.$model"
                @keypress="isNumber(event)"
                @input="ValidationCheck('weight')"
                @blur="zeroClear('weight')"
              />
              <span
                v-if="v$.saveform.weight.$model > 199999"
                v-tooltip="{
                  value: 'The weight entered exceeds 200,000 lbs',
                  class: 'custom-error',
                }"
              >
                <svg class="icon tooltip-error-icon">
                  <use href="/svg/icons.svg#info" xlink:href="/svg/icons.svg#info"></use>
                </svg>
              </span>
            </div>
          </div>
          <div
            class="field-wrapper w-[22%]"
            :class="{ clerror: v$.saveform.miles.$error }"
          >
            <label>Miles<span class="required">*</span></label>
            <div class="field-tooltip-wrapper">
            <input
              type="text"
              class="form-control"
              placeholder="Miles"
              maxlength="4"
              v-model="v$.saveform.miles.$model"
              v-on:keypress="isNumberforMiles(event)"
              
            />
            </div>
          </div>
          <div
            class="field-wrapper w-[29%]"
            :class="{
              clerror: submitted && v$.saveform.selectedEquipment.$error,
            }"
          >
            <label>Equipment<span class="required">*</span></label>
            <div class="field-tooltip-wrapper">
            <Dropdown
              ref="equipmentFilter"
              @before-show="clearFilterSearch(4)"
              v-model="v$.saveform.selectedEquipment.$model"
              :options="equipmentList"
              optionLabel="EquipmentType"
              optionValue="EquipTypeID"
              autoFilterFocus
              placeholder="Select"
              :filter="true"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span>{{ slotProps.option.EquipmentType }}</span>
                </div>
              </template>
              <template #header>
                <div class="carrier-multiseleect-header">
                  <svg
                    class="p-header-close-icon"
                    @click="clearFilterSearch(4)"
                  >
                    <use href="/svg/icons.svg#close" xlink:href="/svg/icons.svg#close"></use>
                  </svg>
                </div>
              </template>
            </Dropdown>
            </div>
          </div>
          <div
            class="field-wrapper w-[29%]"
            :class="{ clerror: v$.saveform.size.$error }"
          >
            <label>Size<span class="required">*</span></label>
            <input
              type="text"
              class="form-control"
              placeholder="Size"
              v-model="v$.saveform.size.$model"
              @keypress="isNumber(event)"
              @input="ValidationCheck('size')"
              @blur="zeroClear('size')"
              maxlength="4"
            />
            <!-- TODO: please don't delete DAT change -->
            <div v-if="sizeError" class="errortext">
              Load will not post to DAT
            </div>
          </div>
        </div>
        <div class="field-wrapper w-auto mt-20p">
          <div class="cl-checkbox-wrapper mb-1">
            <Checkbox
              name="boards"
              value="Team"
              v-model="checkboxVModel"
              @change="changeTeam()"
            />
            <label>Team</label>
          </div>
          <div class="cl-checkbox-wrapper">
            <Checkbox
              name="boards"
              :binary="true"
              v-model="partial"
              @change="changePartial()"
            />
            <label>Partial</label>
          </div>
        </div>
      </div>
      <div class="flex gap-3 mb-2 w-12">
          <div class="w-[20%]">
            <label>Pay</label>
            <div class="field-tooltip-wrapper">
            <input
              type="text"
              class="form-control no-arrows w-170"
              placeholder="Value"
              v-model="payRange"
              maxlength="50"
              @keypress="allowAlphaNumericSpecialChr($event)"
              @keyup="checkboxPostRatesValidation($event)"
              @paste="payRangePaste($event)"
              @keydown.space="preventLeadingSpace"
              @blur="payRangeBlur($event.target.value)"
            />
            </div>
          </div>          
          <div class="w-[62%]">
            <label>Comments</label>
            <input
              type="text"
              class="form-control"
              placeholder="Enter Comments"
              v-model="comments"
              maxlength="75"
              @keypress="spaceValidation(evt)"
              @input="checkboxPostCommentsValidation"
              @keydown.space="preventLeadingSpace"
            />
          </div>
        <div class="w-auto">
          <div class="cl-checkbox-wrapper mt-20p mb-1">
            <Checkbox
              name="boards"
              value="Post Rates"
              :class="this.payRange.length <= 0 ? 'cl-disable-checkbox' : ''"
              v-model="checkboxPostRatesModel"
              @change="changePostRates()"
              :disabled="this.payRange.length <= 0"
            />
            <label>Post Rate</label>
          </div>
          <div class="cl-checkbox-wrapper">
            <Checkbox
              name="boards"
              value="Post Comments"
              :class="this.comments.length <= 0 ? 'cl-disable-checkbox' : ''"
              v-model="checkboxPostCommentsModel"
              @change="changePostComments()"
              :disabled="this.comments.length <= 0"
            />
            <label>Post Comments</label>
          </div>
        </div>
      </div>
      <div class="flex items-center gap-3 w-12">
          <div class="w-[20%]">
            <label class="font-medium">Post to boards</label>
          </div>
          <div class="w-[62%]">
            <div class="flex">
              <div class="cl-checkbox-wrapper mr-2">
                <Checkbox
                  :binary="true"
                  v-model="checkboxDAT"
                  :disabled="!DATCheckboxDisable"
                  :class="!DATCheckboxDisable ? 'cl-disable-checkbox' : ''"
                />
                <label>DAT</label>
              </div>
              <div class="cl-checkbox-wrapper mr-2">
                <Checkbox
                  :binary="true"
                  @change="ITSCheckBoxChange()"  
                  v-model="checkboxITS"
                  :disabled="!ischeckboxITS"
                  :class="!ischeckboxITS ? 'cl-disable-checkbox' : ''"
                />
                <label>ITS</label>
              </div>
              <div class="cl-checkbox-wrapper">
                <Checkbox
                  :binary="true"
                  v-model="checkboxLoadBoardNetwork"
                  :disabled="!ischeckboxLoadBoardNetwork"
                  :class="!ischeckboxLoadBoardNetwork ? 'cl-disable-checkbox' : ''"
                />
                <label>LoadBoard Network</label>
              </div>
            </div>
          </div>
        <div class="field-wrapper w-auto">
          <div class="cl-checkbox-wrapper">
            <Checkbox
              name="boards"
              value="Network Load"
              v-model="checkboxVModel"
              @change="changeNetworkLoad()"
            />
            <label>Network Load</label>
          </div>
        </div>
      </div>
    </div>
    <hr class="mt-2" />
    <template #footer>
      <button
        :disabled="showSaveMessage"
        class="theme-btn"
        @click="saveLoad('saveclose')"
      >
        Save & Close
      </button>
      <button
        class="theme-btn-border"
        @click="saveLoad('postsource')"
      >
        Save & Source
      </button>
      <button
        class="theme-btn-border"
        @click="saveLoad('savebookedit')"
      >
        Save & Book/Edit
      </button>
      <button class="cancel-btn" @click="close()">Cancel</button>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="cancelDialog"
    :style="{ width: '290px' }"
    header="Confirm"
    :modal="true"
    class="theme-dialog"
    @hide="addOverflow"
    @update:visible="handleClose"
  >
    <div class="confirmation-content">
      <span
        >The date selected is currently in the past. Are you sure you would like
        to proceed?</span
      >
    </div>
    <template #footer>
      <button class="theme-btn" @click="Yes()">Yes</button>
      <button class="btn-outline" @click="Cancel()">Cancel</button>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="weightConfirmationDialogFlag"
    :style="{ width: '450px' }"
    header="Confirm"
    :modal="true"
    class="theme-dialog"
  >
    <div class="confirmation-content">
      <span>Weight for Partial load is over 32,000 lbs. Are you sure you want to proceed?</span>
    </div>
    <template #footer>
      <button class="theme-btn" @click="closeWeightPopupDialog()">
        Yes
      </button>
      <button class="btn-outline" @click="clearWeightPopupDialog()">No</button>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="timeDialog"
    :style="{ width: '450px' }"
    header="Error"
    :modal="true"
    class="theme-dialog"
    @hide="addOverflow"
  >
    <div class="confirmation-content">
      <span>{{ alertErrorText }}</span>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="milegeDialog"
    :style="{ width: '450px' }"
    header="Error"
    :modal="true"
    class="theme-dialog"
    @hide="addOverflow"
  >
    <div class="confirmation-content">
      <span>Mileage information is not available for selected locations.</span>
    </div>
  </Dialog>
  <Dialog
    v-model:visible="customerDialog"
    :style="{ width: '450px' }"
    header="Alert"
    :modal="true"
    class="theme-dialog"
    @hide="addOverflow"
  >
    <div class="confirmation-content">
      <span v-html="customerResponseText"></span>
    </div>
    <template #footer>
      <button class="btn-outline" @click="this.customerDialog = false">
        Ok
      </button>
    </template>
  </Dialog>
  <Dialog
    v-model:visible="confirmDialog"
    :style="{ width: '290px' }"
    header="Confirm"
    :modal="true"
    class="theme-dialog"
  >
    <div class="confirmation-content">
            Are you sure you want to cancel?
    </div>
    <template #footer>
      <button class="theme-btn" @click="closeConfirmCreateLoad()">Yes</button>
      <button class="btn-outline" @click="cancelClose()">Cancel</button>
    </template>
  </Dialog>  
  <vue-element-loading
    :active="isActive"
    spinner="bar-fade-scale"
    color="#FF6700"
    :is-full-screen="true"
  />  
</template>
<script>
    import DateHelper from "@/assets/datehelpers";
import VueElementLoading from "vue-element-loading";
import { dataProvider } from "@/api/dataProvider.js";
import useVuelidate from "@vuelidate/core";
import constants from "@/assets/constants.js"
import { required, maxValue, maxLength } from "@vuelidate/validators";
import CustomerService from "@/service/CustomerService";
import moment from "moment";

export default {
  data() {
    return {
      isMaximized: false,
      customerService: null,
      isActive:false,
      confirmDialog:false,
      loading: false,
      v$: useVuelidate(),
      submitted: false,
      typeSelected: "",
      typeRadioButtonList: [
        { name: "FTL", value: "1" },
        { name: "Intermodal", value: "3" },
      ],
      commodity: "",
      payRange: "",
      comments: "",
      checkboxTeam: false,
      checkboxPartial: false,
      partial: false,
      checkboxPostRates: false,
      checkboxPostComments: false,
      checkboxNetworkLoad: false,
      checkboxDAT: false,
      checkboxITS: false,
      checkboxLoadBoardNetwork: false,
      weightConfirmationDialogFlag: false,
      ischeckboxDAT: false,
      ischeckboxITS: false,
      ischeckboxLoadBoardNetwork: false,
      isPostEnabled: false,
      checkboxVModel: "",
    //  pickupstarttime: null,
      pickupendtime: null,
     // destinationstarttime: null,
      destinationendtime: null,
      selectedCustomer: {},
      customersList: [],
      equipmentList: [],
      cityStateList: [],
      destinationCities: [],
      pickupCities: [],
      pickupstatesList: [],
      destinationstatesList: [],
      saveform: {
        selectedPickupCity: "",
        selectedDestinationCity: "",
        selectedPickupState: "",
        selectedDestinationState: "",
        selectedEquipment: "",
        weight: "",
        miles: "",
        size: "",
        pickupdate: null,
        destinationdate: null,
        isMaxWeight: false,
        pickupstarttime:null,
        destinationstarttime:null
      },
      customerLoading: true,
      pickupCityStateLoading: true,
      destinationCityStateLoading: true,
      commentsCheckboxDisable: true,
      ratesCheckboxDisable: true,
      DATCheckboxDisable: false,
      sizeError: false,
      checkboxPostRatesModel: "",
      checkboxPostCommentsModel: "",
      cancelDialog: false,
      cancelType: "",
      showSaveMessage: false,
      timeDialog: false,
      milegeDialog: false,
      alertErrorText: "",
      isCustomerLoading: false,
      customerDialog: false,
      customerResponseText: "",
      constants,
    };
  },

  components: {
    VueElementLoading
  },

  validations() {
    return {
      saveform: {
        selectedPickupCity: { required },
        selectedDestinationCity: { required },
        selectedPickupState: { required },
        selectedDestinationState: { required },
        selectedEquipment: { required },
        weight: { required, maxValue: maxValue(199999) },
        miles: { required, maxLength: maxLength(4) },
        size: { required },
        pickupdate: { required },
        destinationdate: { required },
        pickupstarttime :{required},
        destinationstarttime:{required}
      },
    };
  },
  created() {
    this.customerService = new CustomerService();
  
        },
 
  methods: {
      async ITSCheckBoxChange() {
          if (this.checkboxITS === true) {
          await dataProvider.getCheckITSToken()
              .then((res) => {
                  //if ITS token is invalid open prompt to get new ITS token.
                  if (res.IsValid === false) {
                      this.emitter.emit("itsCredsShow"); 
                  }                  
          });
          }
    },
    handleDestinationDateFormat(evt) {
      this.v$.saveform.destinationdate.$model = DateHelper.formatDateAsDate(evt);
      this.comparePickupDestinationDate();
    },
    handlePickupDateFormat(evt) {
      this.v$.saveform.pickupdate.$model = DateHelper.formatDateAsDate(evt);
      this.pastDateValidation();
    },
    isNumberSlash(evt) {
      evt = evt ? evt : window.event;
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '/'];
    
      var textInField = evt.key;
        
      if (textInField.length == 1 ) 
      {
        if ( !keysAllowed.includes(textInField)) 
        {
          evt.preventDefault();
        }
          
      }

        if(textInField !== '/'){
        DateHelper.AutoAddSlashes(evt)
            }
    },
    validatePickupStartTime(event)
    {
      this.v$.saveform.pickupstarttime.$model =this.handletimeformat(event);
      this.pickupTimeValidation();
    },
    validatePickupEndTime(event)
    {
      this.pickupendtime =this.handletimeformat(event);
      this.pickupTimeValidation();
    },
    validateDestinationStartTime(event)
    {
      this.v$.saveform.destinationstarttime.$model =this.handletimeformat(event);
      this.destinationTimeValidation();
    },
    validateDestinationEndTime(event)
    {
      this.destinationendtime =this.handletimeformat(event);
      this.destinationTimeValidation();
    },
    handletimeformat(event)
    {
      let time = this.customerService.TimeValidation(event.value,this.getHourFormat);
     return !time || time.includes("Invalid date") ? null : time;
    },
    close() {
      this.confirmDialog = true;
    },    
    closeConfirmCreateLoad(){
      this.confirmDialog = false;
      this.$store.commit("login/closeCreateLoad", false);
    },
    cancelClose(){
      this.confirmDialog = false;
    },
    Cancel() {
      this.saveform.pickupdate = new Date();
      this.cancelDialog = false;
    },
    Yes() {
      this.cancelDialog = false;
    },
    handleClose() {
      this.saveform.pickupdate = new Date();
    },
    pastDateValidation() {
      if (!this.saveform.pickupdate) {
        return;
      }
      let currentDate = new Date();
      let pickupDate = new Date(this.saveform.pickupdate);
      if (pickupDate.getFullYear() < currentDate.getFullYear()) {
        this.cancelDialog = true;
      } else if (
        pickupDate.getMonth() < currentDate.getMonth() &&
        pickupDate.getFullYear() <= currentDate.getFullYear()
      ) {
        this.cancelDialog = true;
      } else if (
        pickupDate.getMonth() <= currentDate.getMonth() &&
        pickupDate.getFullYear() <= currentDate.getFullYear() &&
        pickupDate.getDate() < currentDate.getDate()
      ) {
        this.cancelDialog = true;
      } else {
        this.cancelDialog = false;
      }
    },
    comparePickupDestinationDate() {
      if (
        this.saveform.destinationdate &&
        this.saveform.pickupdate &&
        this.saveform.destinationdate.getTime() <
          this.saveform.pickupdate.getTime()
      ) {
        this.timeDialog = true;
        this.alertErrorText = "Destination Date should not be less than Pickup Date.";
        this.v$.saveform.destinationdate.$model = null;
        return true;
      }
    },
    zeroClear(type) {
      if (this.saveform.size == 0 && type == "size") {
        this.saveform.size = "";
      }
      if (this.saveform.weight == 0 && type == "weight") {
        this.saveform.weight = "";
      }
   
    },
    isNumber(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 43
      ) {
        evt.preventDefault();
      } else if ((evt.target.value == 0 && evt.which == 48) || charCode == 43) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isNumberforMiles(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 43
      ) {
        evt.preventDefault();
      } else if (  charCode == 43) {
        evt.preventDefault();
      }
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    ValidationCheck(type) {
      if (type == "size") {
        if (this.saveform.size > 149) {
          this.sizeError = true;
        } else {
          this.sizeError = false;
        }
       
        if (!this.ischeckboxDAT || this.saveform.size > 149) {
          this.DATCheckboxDisable = false;
          this.checkboxDAT = false;
        } else if (this.ischeckboxDAT && this.saveform.size < 149) {
          this.DATCheckboxDisable = true;
       
        }
      }
      if (type == "weight") {
        if (this.saveform.weight > 199999) {
          this.isMaxWeight = true;
        } else {
          this.isMaxWeight = false;
        }
        if(this.saveform.weight>=32000 && this.partial)
        {
          this.changePartial();
        }
      }
    },
    openCreateLoad() {
      this.v$.$reset();
      this.commodity = "";
      this.payRange = "";
      this.comments = "";
      this.sizeError = false;
      this.DATCheckboxDisable = false;
      this.checkboxTeam = false;
      this.checkboxPartial = false;
      this.partial = false;
      this.checkboxPostRates = false;
      this.checkboxPostComments = false;
      this.checkboxNetworkLoad = false;
      this.checkboxDAT = false;
      this.checkboxITS = false;
      this.checkboxLoadBoardNetwork = false;
      this.checkboxVModel = "";
      //this.pickupstarttime = null;
      this.pickupendtime = null;
      //this.destinationstarttime = null;
      this.destinationendtime = null;
      this.selectedCustomer = "";
      this.checkboxPostRatesModel = false;
      this.checkboxPostCommentsModel = false;
      var saveform = {
        selectedPickupCity: "",
        selectedDestinationCity: "",
        selectedPickupState: "",
        selectedDestinationState: "",
        selectedEquipment: "",
        weight: "",
        miles: "",
        size: "",
        pickupdate: null,
        destinationdate: null,
        pickupstarttime:null,
        destinationstarttime:null
      };
      this.saveform = saveform;
      // this.$store.commit("login/openCreateLoad");
      this.typeSelected = this.typeRadioButtonList[0].value;
      this.getCustomers();
      this.getEquipments();
      this.getCityStates();
      this.getLoadBoardAccess();
    },
    async getCustomers() {
      await dataProvider.getCustomers().then((res) => {
        this.customersList = res;
        this.customerLoading = false;
      });
    },
    async getEquipments() {
      await dataProvider.getEquipmentTypes().then((res) => {
        this.equipmentList = res;
      });
    },
    async getCityStates() {
      await dataProvider.getCityState(0, null, null, 0).then((res) => {
        this.$store.commit("login/setCities", res.cities);
        this.$store.commit("login/setStates", res.states);
        this.pickupCities = res.cities;
        this.destinationCities = res.cities;
        this.pickupstatesList = res.states;
        this.destinationstatesList = res.states;
        this.pickupCityStateLoading = false;
        this.destinationCityStateLoading = false;
      });
    },

    async changeCustomer(customer) {
      this.saveform.miles = "";
      this.pickupCityStateLoading = true;
      this.destinationCityStateLoading = true;
      if (customer.value) {
        let customerId = 0;
        customerId = customer.value.CustomerID;
        await dataProvider
          .getCityState(customerId, null, null, 1)
          .then((res) => {
            this.pickupCities = res.cities;
            this.pickupstatesList = res.states;
            this.saveform.selectedPickupCity = "";
            this.saveform.selectedPickupState = "";
          });
        await dataProvider
          .getCityState(customer.value.CustomerID, null, null, 2)
          .then((res) => {
            this.destinationCities = res.cities;
            this.destinationstatesList = res.states;
            this.saveform.selectedDestinationCity = "";
            this.saveform.selectedDestinationState = "";
          });
      } else {
        let storeCities = this.$store.state.login.cities;
        let storeStates = this.$store.state.login.states;

        this.pickupCities = storeCities;
        this.pickupstatesList = storeStates;
        this.saveform.selectedPickupCity = "";
        this.saveform.selectedPickupState = "";
        this.saveform.selectedDestinationState = "";
        this.saveform.selectedDestinationCity = "";
        this.destinationCities = storeCities;
        this.destinationstatesList = storeStates;
        // await dataProvider.getCityState(0, null, null, 0).then((res) => {
        //   this.pickupCities = res.cities;
        //   this.pickupstatesList = res.states;
        //   this.saveform.selectedPickupCity = "";
        //   this.saveform.selectedPickupState = "";
        //   this.saveform.selectedDestinationState = "";
        //   this.saveform.selectedDestinationCity = "";
        //   this.destinationCities = res.cities;
        //   this.destinationstatesList = res.states;
        // });
      }
      this.pickupCityStateLoading = false;
      this.destinationCityStateLoading = false;
      let customerSelect = document.getElementById("customerFilter");
      if (this.getPortalID == 1) {
        this.customerDialog = false;
      } else if (customer.value.HasShipped == false) {
        customerSelect.classList.add("text-red");
        this.customerDialog = true;
        this.customerResponseText =
          "This Customer has not shipped in 45 or more days and is no longer protected. See Protected Account List for status.";
      } else {
        customerSelect.classList.remove("text-red");
        customerSelect.classList.add("text-black");
        // this.$refs.customerFilter.inputStyle.textColor("black");
      }

      if (this.canUse(customer) == false) {
        this.selectedCustomer = null;
        // if (customer.CurrentLoadID == 0) {// exeecute in case of edit load
        // } else {
        // }
      }
    },
    canUse(customer) {
      let TotalCreditLimit = customer.value?.CreditLimit;
      if (customer.value.Prepay == true) {
        return true;
      }
      var date = new Date();
      var now_utc = Date.UTC(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        date.getUTCHours(),
        date.getUTCMinutes(),
        date.getUTCSeconds()
      );

      if (
        customer.value.GraceEnd != null &&
        customer.value.GraceCredit != null &&
        customer.value.GraceEnd >= new Date(now_utc)
      ) {
        TotalCreditLimit += customer.value.GraceCredit;

        return true;
      }
      if (
        customer.value.OverrideEnd != null &&
        customer.value.OverrideEnd >= new Date(now_utc)
      ) {
        this.customerDialog = true;
        this.customerResponseText =
          "This Customer has a credit override active until " +
          customer.value.OverrideEnd;

        return true;
      }
      let NewInvoiceTotal = 0;
      if (
        customer.value.InvoicedTotal +
          customer.value.UnInvoicedTotal +
          NewInvoiceTotal >
        TotalCreditLimit
      ) {
        this.customerDialog = true;
        let creditLimit = TotalCreditLimit.toFixed(2);
        let invoicedTotal = customer.value.InvoicedTotal.toFixed(2);
        let unInvoicedTotal = (
          customer.value.UnInvoicedTotal + NewInvoiceTotal
        ).toFixed(2);
        let amountOver =
          customer.value.InvoicedTotal +
          customer.value.UnInvoicedTotal +
          NewInvoiceTotal -
          TotalCreditLimit;
        this.customerResponseText = `This Customer is currently over their credit limit and CANNOT be added to the Load.<br/> <br/> Credit Limit&nbsp;:&nbsp;<strong>$${creditLimit}</strong>,<br/> Invoiced Total&nbsp;:&nbsp;<strong>$${invoicedTotal}</strong>,<br/>Un-Invoiced Total&nbsp;:&nbsp;<strong>$${unInvoicedTotal}</strong>,<br/>AMOUNT OVER&nbsp;:&nbsp;<strong>$${amountOver.toFixed(
          2
        )}</strong>`;
        return false;
      }
      return true;
    },
    async changePickupCity(CityState) {
      this.saveform.miles = "";
      this.pickupCityStateLoading = true;
      let pickupCity = CityState.value;
      if (this.selectedCustomer != null && this.selectedCustomer != "") {
        if (CityState.value == null || CityState.value == "") {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          await dataProvider
            .getCityState(
              selectedCustomer,
              null,
              this.saveform.selectedPickupState,
              1
            )
            .then((res) => {
              this.pickupCities = res.cities;
              this.pickupstatesList = res.states;
            });
        } else {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          await dataProvider
            .getCityState(
              selectedCustomer,
              pickupCity,
              this.saveform.selectedPickupState,
              1
            )
            .then((res) => {
              this.pickupCities = res.cities;
              this.pickupstatesList = res.states;

              if (
                (this.saveform.selectedPickupState == "" ||
                  this.saveform.selectedPickupState == null) &&
                res.states.length == 1
              ) {
                this.saveform.selectedPickupState = res.states[0].State;
              } 
            });
        }
      } else if (CityState.value == null || CityState.value == "") {
        let selectedCustomer = 0;
        await dataProvider
          .getCityState(
            selectedCustomer,
            null,
            this.saveform.selectedPickupState,
            1
          )
          .then((res) => {
            this.pickupCities = res.cities;
            this.pickupstatesList = res.states;
            //  this.saveform.selectedPickupState = "";
          });
      } else {
        let selectedCustomer = 0;
        await dataProvider
          .getCityState(
            selectedCustomer,
            pickupCity,
            this.saveform.selectedPickupState,
            1
          )
          .then((res) => {
            this.pickupCities = res.cities;
            this.pickupstatesList = res.states;
            if (
              (this.saveform.selectedPickupState == "" ||
                this.saveform.selectedPickupState == null) &&
              res.states.length == 1
            ) {
              this.saveform.selectedPickupState = res.states[0].State;
            } 
          });
      }
      this.pickupCityStateLoading = false;
      this.getPCMiler();
    },

    async changePickupState(State) {
      this.saveform.miles = "";
      this.pickupCityStateLoading = true;
      if (this.selectedCustomer != null && this.selectedCustomer != "") {
        let selectedCustomer = this.selectedCustomer.CustomerID;
        let pickupState = State.value;
        if (State.value == null || State.value == "") {
          await dataProvider
            .getCityState(selectedCustomer, null, null, 1)
            .then((res) => {
              this.pickupCities = res.cities;
              this.pickupstatesList = res.states;
              this.saveform.selectedPickupCity = "";
            });
        } else {
          await dataProvider
            .getCityState(selectedCustomer, null, pickupState, 1)
            .then((res) => {
              this.pickupCities = res.cities;
            });
        }
      } else if (State.value == null || State.value == "") {
        let storeCities = this.$store.state.login.cities;
        let storeStates = this.$store.state.login.states;
        this.pickupCities = storeCities;
        this.pickupstatesList = storeStates;
        this.saveform.selectedPickupCity = "";
        // let selectedCustomer = 0;
        // await dataProvider
        //   .getCityState(selectedCustomer, null, null, 1)
        //   .then((res) => {
        //     this.pickupCities = res.cities;
        //     this.pickupstatesList = res.states;
        //     this.saveform.selectedPickupCity = "";
        //   });
      } else {
        let selectedCustomer = 0;
        let pickupState = State.value;
        await dataProvider
          .getCityState(selectedCustomer, null, pickupState, 1)
          .then((res) => {
            this.pickupCities = res.cities;
          });
      }
      this.pickupCityStateLoading = false;
      this.getPCMiler();
    },

    async changeDestinationCity(CityState) {
      this.saveform.miles = "";
      this.destinationCityStateLoading = true;
      let destinationCity = CityState.value;
      if (this.selectedCustomer != null && this.selectedCustomer != "") {
        if (CityState.value == null || CityState.value == "") {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          await dataProvider
            .getCityState(
              selectedCustomer,
              null,
              this.saveform.selectedDestinationState,
              2
            )
            .then((res) => {
              this.destinationCities = res.cities;
              this.destinationstatesList = res.states;
            });
        } else {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          await dataProvider
            .getCityState(
              selectedCustomer,
              destinationCity,
              this.saveform.selectedDestinationState,
              2
            )
            .then((res) => {
              this.destinationCities = res.cities;
              this.destinationstatesList = res.states;
              if (
                (this.saveform.selectedDestinationState == "" ||
                  this.saveform.selectedDestinationState == null) &&
                res.states.length == 1
              )
                this.saveform.selectedDestinationState = res.states[0].State;
              
            });
        }
      } else if (CityState.value == null) {
        let selectedCustomer = 0;
        await dataProvider
          .getCityState(
            selectedCustomer,
            null,
            this.saveform.selectedDestinationState,
            2
          )
          .then((res) => {
            this.destinationCities = res.cities;
            this.destinationstatesList = res.states;
            // this.saveform.selectedDestinationState = "";
          });
      } else {
        let selectedCustomer = 0;
        await dataProvider
          .getCityState(
            selectedCustomer,
            destinationCity,
            this.saveform.selectedDestinationState,
            2
          )
          .then((res) => {
            this.destinationCities = res.cities;
            this.destinationstatesList = res.states;
            if (
              (this.saveform.selectedDestinationState == "" ||
                this.saveform.selectedDestinationState == null) &&
              res.states.length == 1
            )
              this.saveform.selectedDestinationState = res.states[0].State;
            
          });
      }
      this.destinationCityStateLoading = false;
      this.getPCMiler();
    },
    async changeDestinationState(State) {
      this.saveform.miles = "";
      this.destinationCityStateLoading = true;
      if (this.selectedCustomer != null && this.selectedCustomer != "") {
        if (State.value == null || State.value == "") {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          await dataProvider
            .getCityState(selectedCustomer, null, null, 2)
            .then((res) => {
              this.destinationCities = res.cities;
              this.destinationstatesList = res.states;
              this.saveform.selectedDestinationCity = "";
            });
        } else {
          let selectedCustomer = this.selectedCustomer.CustomerID;
          let destinationState = State.value;
          await dataProvider
            .getCityState(selectedCustomer, null, destinationState, 2)
            .then((res) => {
              this.destinationCities = res.cities;
            });
        }
      } else if (State.value == null || State.value == "") {
        let storeCities = this.$store.state.login.cities;
        let storeStates = this.$store.state.login.states;
        this.destinationCities = storeCities;
        this.destinationstatesList = storeStates;
        this.saveform.selectedDestinationCity = "";
        // let selectedCustomer = 0;
        // await dataProvider
        //   .getCityState(selectedCustomer, null, null, 1)
        //   .then((res) => {
        //     this.destinationCities = res.cities;
        //     this.destinationstatesList = res.states;
        //     this.saveform.selectedDestinationCity = "";
        //   });
      } else {
        let selectedCustomer = 0;
        let destinationState = State.value;
        await dataProvider
          .getCityState(selectedCustomer, null, destinationState, 1)
          .then((res) => {
            this.destinationCities = res.cities;
          });
      }
      this.destinationCityStateLoading = false;
      this.getPCMiler();
    },
    clearCustomerDropdown() {
      this.selectedCustomer = "";
      let storeCities = this.$store.state.login.cities;
      let storeStates = this.$store.state.login.states;

      this.pickupCities = storeCities;
      this.pickupstatesList = storeStates;
      this.saveform.selectedPickupCity = "";
      this.saveform.selectedPickupState = "";
      this.saveform.selectedDestinationState = "";
      this.saveform.selectedDestinationCity = "";
      this.saveform.miles = "";
      this.destinationCities = storeCities;
      this.destinationstatesList = storeStates;
    },
    clearPickupCityDropdown() {
      this.saveform.selectedPickupCity = "";
      this.saveform.miles = "";
    },
    async clearPickupStateDropdown() {
      this.saveform.miles = "";
      if (this.selectedCustomer != "" || this.selectedCustomer != null) {
        this.saveform.selectedPickupState = "";
        let selectedCustomer = this.selectedCustomer.CustomerID;
        await dataProvider
          .getCityState(selectedCustomer, null, null, 1)
          .then((res) => {
            this.pickupCities = res.cities;
            this.pickupstatesList = res.states;
            this.saveform.selectedPickupCity = "";
          });
      } else {
        this.saveform.selectedPickupState = "";
        this.saveform.selectedPickupCity = "";
        let storeCities = this.$store.state.login.cities;
        let storeStates = this.$store.state.login.states;
        this.pickupCities = storeCities;
        this.pickupstatesList = storeStates;
      }
    },
    clearDestinationCityDropdown() {
      this.saveform.miles = "";
      this.saveform.selectedDestinationCity = "";
    },
    async clearDestinationStateDropdown() {
      this.saveform.miles = "";
      if (this.selectedCustomer != null && this.selectedCustomer != "") {
        this.saveform.selectedDestinationCity = "";
        this.saveform.selectedDestinationState = "";
        let selectedCustomer = this.selectedCustomer.CustomerID;
        await dataProvider
          .getCityState(selectedCustomer, null, null, 2)
          .then((res) => {
            this.destinationCities = res.cities;
            this.destinationstatesList = res.states;
            this.saveform.selectedDestinationCity = "";
          });
      } else {
        this.saveform.selectedDestinationCity = "";
        this.saveform.selectedDestinationState = "";
        let storeCities = this.$store.state.login.cities;
        let storeStates = this.$store.state.login.states;
        this.destinationCities = storeCities;
        this.destinationstatesList = storeStates;
      }
    },
    clearEquipmentDropdown() {
      this.saveform.selectedEquipment = "";
    },
    changeTeam() {
      this.checkboxTeam = !this.checkboxTeam;
    },
     changePartial() {
      if(this.saveform.weight >= 32000 && this.partial)
      {
        this.weightConfirmationDialogFlag = true;
      }
    },
    changePostRates() {
      this.checkboxPostRates = !this.checkboxPostRates;
    },
    changePostComments() {
      this.checkboxPostComments = !this.checkboxPostComments;
    },
    changeNetworkLoad() {
      this.checkboxNetworkLoad = !this.checkboxNetworkLoad;
    },
    postClose() {
      this.$store.commit("login/closeCreateLoad");
    },
    clearFilterSearch(ref) {
      if ((this.$refs.customerFilter.filterValue != null) & (ref == 1)) {
        this.$refs.customerFilter.filterValue = "";
        this.saveform.miles = "";
      }
      if ((this.$refs.cityFilter.filterValue != null) & (ref == 2)) {
        this.$refs.cityFilter.filterValue = "";
      }

      if ((this.$refs.dropCityFilter.filterValue != null) & (ref == 3)) {
        this.$refs.dropCityFilter.filterValue = "";
      }

      if ((this.$refs.equipmentFilter.filterValue != null) & (ref == 4)) {
        this.$refs.equipmentFilter.filterValue = "";
      }
      if ((this.$refs.pickstatefilter.filterValue != null) & (ref == 5)) {
        this.$refs.pickstatefilter.filterValue = "";
      }
      if ((this.$refs.dropstatefilter.filterValue != null) & (ref == 6)) {
        this.$refs.dropstatefilter.filterValue = "";
      }
    },
    async saveLoad(actionType) {
      this.submitted = true;
      this.v$.saveform.$touch();
      if (this.v$.saveform.$error) {
        return;
      }
      this.showSaveMessage = true;
      this.isPostEnabled = true;
      if (this.saveform.destinationstarttime == null) {
        this.saveform.destinationstarttime = "00:00";
      }
      if (this.destinationendtime == null) {
        this.destinationendtime = "00:00";
      }
      if (this.saveform.pickupstarttime == null) {
        this.saveform.pickupstarttime = "00:00";
      }
      if (this.pickupendtime == null) {
        this.pickupendtime = "00:00";
      }
      if (this.comparePickupDestinationDate()) {
        this.showSaveMessage = false;
        this.isPostEnabled = false;
        return;
      }
      if (this.isMaxWeight) {
        this.showSaveMessage = false;
        this.isPostEnabled = false;
        return;
      }
    
      if (this.saveform.size == 0) {
        this.showSaveMessage = false;
        this.isPostEnabled = false;
        return;
      }
      if (this.saveform.weight == 0) {
        this.showSaveMessage = false;
        this.isPostEnabled = false;
        return;
      }

      this.saveform.selectedDestinationCity =
        this.saveform.selectedDestinationCity.split(",")[0];
      this.saveform.selectedPickupCity =
        this.saveform.selectedPickupCity.split(",")[0];

      // Date Conversion comman for all
      let pickupstarttime = this.saveform.pickupstarttime ? this.saveform.pickupstarttime : "00:00" ;
      let pickupendtime = this.pickupendtime ? this.pickupendtime : "00:00" ;
      let destinationstarttime = this.saveform.destinationstarttime ? this.saveform.destinationstarttime : "00:00" ;
      let destinationendtime = this.destinationendtime ? this.destinationendtime : "00:00" ;


       if (moment(pickupstarttime).isValid())
        pickupstarttime = this.convertUTCDateToLocalDate(pickupstarttime);
        if (moment(pickupendtime).isValid())
        pickupendtime = this.convertUTCDateToLocalDate(pickupendtime);
       if (moment(destinationstarttime).isValid())
        destinationstarttime = this.convertUTCDateToLocalDate(destinationstarttime);
         if (moment(destinationendtime).isValid())
        destinationendtime = this.convertUTCDateToLocalDate(destinationendtime);
  

      let TimezoneOffset = this.getTimezoneOffsetInMins();
      TimezoneOffset = TimezoneOffset * -1;
      var saveInitialLoad = {
        LoadType: this.typeSelected,
        CustomerID: this.selectedCustomer.CustomerID,
        CustomerName: this.selectedCustomer.CustomerName,
        PickupCity: this.saveform.selectedPickupCity,
        PickupState: this.saveform.selectedPickupState,
        PickupDate: this.saveform.pickupdate,
        PickupStartTime: this.saveform.pickupstarttime,
        PickupEndTime: pickupendtime,
        DestinationCity: this.saveform.selectedDestinationCity,
        DestinationState: this.saveform.selectedDestinationState,
        DestinationDate: this.saveform.destinationdate,
        DestinationStartTime: this.saveform.destinationstarttime,
        DestinationEndTime: destinationendtime,
        Commodity: this.commodity,
        PickupWeight: this.saveform.weight,
        Mileage: this.saveform.miles,
        EquipmentID: this.saveform.selectedEquipment,
        EquipMinSize: this.saveform.size,
        TeamService: this.checkboxTeam,
        PartialLoad: this.partial,
        PayRange: this.payRange,
        Comments: this.comments,
        PostRate: this.checkboxPostRates,
        PostComments: this.checkboxPostComments,
        DATEnabled: this.checkboxDAT,
        TruckstopEnabled: this.checkboxITS,
        PEPEnabled: this.checkboxLoadBoardNetwork,
        NetworkLoad: this.checkboxNetworkLoad,
        ActionType: actionType,
        TimezoneOffset: TimezoneOffset,
        LoadDate: new Date(),
      };

      //Set timezoneOfset
      saveInitialLoad.PickupDate.setMinutes(
        saveInitialLoad.PickupDate.getTimezoneOffset() * -1
      );
      saveInitialLoad.DestinationDate.setMinutes(
        saveInitialLoad.DestinationDate.getTimezoneOffset() * -1
      );
      let newlyCreatedLoadId = "";

      this.isActive = true;
      await dataProvider.saveLoadCreateALoad(saveInitialLoad).then((res) => {
     
        if (res.IsSuccess) {
          newlyCreatedLoadId = res.LoadID;
          if (res.SuccessMessage) {
            this.$toast.add({
              severity: "success",
              summary: "Load Saved",
              detail: res.SuccessMessage,
              life: 10000,
              closable: true,
            });
          }
          if (res.ErrorMessage) {
            this.$toast.add({
              severity: "error",
              summary: "Load Post Error",
              detail: res.ErrorMessage,
              life: null,
            });
          }
          this.showSaveMessage = false;
          this.isPostEnabled = false;
        }
        this.isActive = false;
      });
      if (actionType === "postsource") {
        this.$store.commit("login/openSourceLoadPopup", newlyCreatedLoadId);
      } 
      if (actionType === "savebookedit") {
       
          this.$router.push({ path: `/book-edit/${newlyCreatedLoadId}/1`, });            
              
          // let route = this.$router.resolve({ path: '/book-edit/' + newlyCreatedLoadId });
          // window.open( route, "_blank" );              
      }            
      this.$store.commit("login/closeCreateLoad", true);
    },
    getTimezoneOffsetInMins() {
      const today = new Date();
      return today.getTimezoneOffset();
    },
    convertUTCDateToLocalDate(date) {
      var newDate = new Date(
        date.getTime() + date.getTimezoneOffset() * 60 * 1000
      );

      var offset = date.getTimezoneOffset() / 60;
      var hours = date.getHours();
      var minutes = date.getMinutes();
      let manipulatedHrs = hours - offset;
      if (minutes > 0) {
        let maipulatedMins = minutes / 60;
        manipulatedHrs = manipulatedHrs + maipulatedMins;
      }
      newDate.setHours(manipulatedHrs);

      return newDate;
    },
    pickupTimeValidation() {
      //   TODO: commented this code because createload popup giving issue here while closing it
      //   this.pickupstarttime.setSeconds(0, 0);
      //   this.pickupendtime.setSeconds(0, 0);
      if (
        this.pickupendtime != null &&
        this.saveform.pickupstarttime != null &&
        this.pickupendtime != "" &&
        this.saveform.pickupstarttime != "" &&
        this.saveform.pickupstarttime > this.pickupendtime
      ) {
        this.timeDialog = true;
        this.alertErrorText = "End Time should not be less than Start Time";
        this.pickupendtime = "";
      }
    },
    destinationTimeValidation() {
      //TODO: commented this code because createload popup giving issue here while closing it
      // this.destinationstarttime.setSeconds(0, 0);
      // this.destinationendtime.setSeconds(0, 0);
      if (
        this.saveform.destinationstarttime != null &&
        this.destinationendtime != null &&
        this.saveform.destinationstarttime != "" &&
        this.destinationendtime != "" &&
        this.saveform.destinationstarttime > this.destinationendtime
      ) {
        this.timeDialog = true;
        this.alertErrorText = "End Time should not be less than Start Time";
        this.destinationendtime = "";
      }
    },
    spaceValidation(evt) {
      evt = evt ? evt : window.event;
      // var charCode = evt.which ? evt.which : evt.keyCode;
      var textInField = evt.target.value;
      if (textInField.length == 1) {
        var firstChar = textInField.charAt(0);
        if (firstChar == " ") {
          evt.preventDefault();
        }
      }
    },
payRangeBlur(value){
      if(value[value.length - 1] == '.'){
          this.payRange = value.slice(0, -1);
      }
},
    isNumberdot (evt) {
//      const pattern=/^\d+(\.\d+)?(-\d+(\.\d+)?)?$/
      evt = evt ? evt : window.event;
      const keysAllowed = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '.','-',','];
      var textInField = evt.key;
      if(evt.target.value.length == 0 && textInField == '-'){
        evt.preventDefault();
      }
      if(evt.target.value.length == 0 && textInField == '.'){
        evt.preventDefault();
      }      
      if(evt.target.value.includes('-')&& textInField=='-'){
        evt.preventDefault();
      }      
      if (textInField.length == 1 ) 
      {
        if ( !keysAllowed.includes(textInField)) 
        {
          evt.preventDefault();
        }
        else if(this.payRange.length>50)
        {
          evt.preventDefault();
        }   
      }

      if(evt.target.value.includes('-')){
            if(evt.target.value.includes(".") && evt.target.value.includes("-")){
                if(evt.target.value.split(".").length > 2 && textInField=='.'){
                  evt.preventDefault();
                }  
              else if(textInField=='.' && (evt.target.value.indexOf('.') > evt.target.value.indexOf('-'))){
                              evt.preventDefault();
                      }     
              else if(textInField == '.' && evt.target.value[evt.target.value.length - 1] == '-'){    
                          evt.preventDefault();
                  }                                     
            }        
          else if(textInField == '.'){
                    if(evt.target.value[evt.target.value.length - 1] == '-'){
                      evt.preventDefault();
                    }
              }
      }
      else{
              if(textInField == '-'){
                    if(evt.target.value[evt.target.value.length - 1] == '.'){
                      evt.preventDefault();
                    }
              }
              else{
                    if(evt.target.value.includes(".")&& textInField=='.'){
                      evt.preventDefault();
                    }        
              }
      }
    },   
    checkboxPostRatesValidation() {
      if (this.payRange.length == 0) {
        this.checkboxPostRatesModel = false;
      }
    },
    allowAlphaNumericSpecialChr(e) {
  var regex = new RegExp("^[a-zA-Z0-9$,.()-]");
    var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
    if (regex.test(str)) {
        return true;
    }
    e.preventDefault();
    return false;
    },
    payRangePaste(e) {
      e.preventDefault();
      // Get the pasted text
      let pastedText = (e.clipboardData || window.clipboardData).getData("text");
      // Get the current cursor position and selection
      let startPosition = e.target.selectionStart;
      let endPosition = e.target.selectionEnd;

      // Get the current input value
      let currentValue = e.target.value;

      // Replace the selected portion of the current value with the pasted text
      let newValue =
          currentValue.substring(0, startPosition) + pastedText +
          currentValue.substring(endPosition);

      // Remove disallowed characters
      let allowedChars = newValue.replace(/[^a-zA-Z0-9$,.()-]/g, "");

      // Check if the length exceeds the limit (50 characters)
      if (allowedChars.length > 50) {
        allowedChars = allowedChars.substring(0, 50);
      }
      // Update the input value
      e.target.value = allowedChars;
      // Move the cursor to the correct position
      let newPosition = startPosition + allowedChars.length - (endPosition - startPosition);
      e.target.setSelectionRange(newPosition, newPosition);
    },
    checkboxPostCommentsValidation() {
      if (this.comments.length == 0) {
        this.checkboxPostCommentsModel = false;
      }
    },
    closeWeightPopupDialog(){
        this.weightConfirmationDialogFlag = false;
    },
    clearWeightPopupDialog(){
      this.saveform.weight = "";
      this.weightConfirmationDialogFlag = false;
    },
    preventLeadingSpace(e) {
      // only prevent the keypress if the value is blank
      if (!e.target.value) e.preventDefault();
      // otherwise, if the leading character is a space, remove all leading white-space
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    addOverflow() {
      document.body.classList.add("overflow-hidden");
    },
    removeOverflow() {
      document.body.classList.remove("overflow-hidden");
    },
    async getLoadBoardAccess() {
      await dataProvider.getLoadBoardAccess().then((res) => {
        this.checkboxDAT = res.IsDATEnabled;
        this.ischeckboxDAT = res.IsDATEnabled;
        this.checkboxITS = res.IsTruckStopEnabled;
        this.checkboxLoadBoardNetwork = res.IsPEPEnabled;
        this.DATCheckboxDisable = res.IsDATEnabled;
        this.ischeckboxITS = res.IsTruckStopEnabled;
        this.ischeckboxLoadBoardNetwork = res.IsPEPEnabled;
        this.isPostEnabled = this.isPostingCheck;
      });
    },
    async getPCMiler() {
      if (
        this.saveform.selectedPickupCity &&
        this.saveform.selectedPickupState &&
        this.saveform.selectedDestinationCity &&
        this.saveform.selectedDestinationState
      ) {
        await dataProvider
          .getTrackLoadMilege(
            this.saveform.selectedPickupCity,
            this.saveform.selectedPickupState,
            this.saveform.selectedDestinationCity,
            this.saveform.selectedDestinationState
          )
          .then((res) => {
            if (!isNaN(parseFloat(res)) && isFinite(res)) {
              const result = Math.round(res);
              this.saveform.miles = result;
            } else {
              this.milegeDialog = true;
            }
          });
        this.showSaveMessage = false;
        this.isPostEnabled = false;
      }
    },
    onLazyLoad() {
      this.loading = true;
      if (this.loadLazyTimeout) {
        clearTimeout(this.loadLazyTimeout);
      }
      //imitate delay of a backend call
      this.loadLazyTimeout = setTimeout(() => {
        const pickupCities = [...this.pickupCities];
        const destinationCities = [...this.destinationCities];
        this.pickupCities = pickupCities;
        this.destinationCities = destinationCities;
        this.loading = false;
      }, Math.random() * 1000 + 250);
    },
  },
  computed: {
    checkPostRateEmpty() {
      return this.payRange.length <= 0;
    },
    isPostingCheck() {
      if (
        this.checkboxDAT ||
        this.checkboxITS ||
        this.checkboxLoadBoardNetwork
      ) {
        return false;
      } else {
        return true;
      }
    },
    isShowCreateLoad() {
      return this.$store.state.login.isShowCreateLoad;
    },
    getStoreCities() {
      return this.$store.state.login.cities;
    },
    getPortalID() {
      return this.$store.state.login.portalID;
    },
    getHourFormat() {
      return this.$store.state.login.timeFormat;
    },
  },
  watch: {
    "$store.state.login.isShowCreateLoad"() {
      if (this.isShowCreateLoad == true) {
        this.openCreateLoad();
      }
    },
  },
};
</script>
<style lang="scss">
.no-arrows::-webkit-inner-spin-button, 
.no-arrows::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0;
 }
.p-component,
.p-component * {
  font-size: 14px;
}

.statedropdown {
  &.p-dropdown.p-dropdown-clearable .p-dropdown-label {
    padding-right: 0.5rem;
  }
}

.w-82 {
  width: 84%;
}

.customer-multiseleect-header,
.origin-multiseleect-header,
.destination-multiseleect-header,
.carrier-multiseleect-header {
  .p-header-close-icon {
    margin-right: 15px;
    margin-top: 16px;
    margin-left: 5px;
    position: absolute;
    width: 1.5rem;
    height: 1.5rem;
    right: 8px;
    z-index: 1;
  }
}

.pos-r {
  position: relative;

  .toggle-btn {
    position: absolute;
    right: 30px;
    top: -30px;
  }
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter-icon.pi.pi-search {
  display: none;
}

table.p-datepicker-calendar {
  border: none;
}

.createload-body {
.clerror {
  .p-dropdown,
  .p-calendar,
  .form-control {
    border: 1px solid #ef4d43;

    .p-button {
      border: 1px solid transparent;
      border-left: 1px solid #ef4d43;
    }
  }

  .p-calendar {
    border-radius: 3px;
  }

  .p-dropdown:hover {
    border: 1px solid #ef4d43 !important;
  }
}
  .cl-checkbox-wrapper {
    display: flex;
    align-items: center;

    label {
      margin: 0;
    }

    .p-checkbox {
      width: 16px;
      height: 16px;
      margin-right: 3px;
    }
  }

  .p-component,
  .p-component * {
    font-size: 14px;
  }

  .p-checkbox .p-checkbox-box {
    width: 16px;
    height: 16px;
  }

  .p-button:enabled:hover,
  .p-button:enabled:active {
    background: transparent;
  }

  .p-dropdown {
    width: 100%;
  }

  .p-multiselect-close {
    margin-left: 5px;
    margin-right: 5px;
    position: absolute;
    display: none;
  }

  .p-dropdown-clearable {
    .p-dropdown-clear-icon {
      display: none;
    }

    &.p-inputwrapper-filled {
      .p-dropdown-clear-icon {
        display: block;
      }
    }
  }

  hr {
    border: 1px solid #5d626b;
  }

  .field-checkbox,
  .field-radiobutton {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .field-checkbox > label,
  .field-radiobutton > label {
    margin: 0;
  }

  label {
    display: inline-block;
    margin-bottom: 3px;
  }

  .required {
    color: red;
  }

  .p-button {
    background: transparent;
    border: 1px solid #cbd2d9;

    .pi {
      color: #ef4d43;
    }

    &:hover {
      background: transparent;
    }
  }

  .mt-20p {
    margin-top: 19px;
  }
  .cl-disable-checkbox {
      pointer-events: none;

      .p-checkbox-box {
        border: 1px solid #818181;
      }
  }  
}

.statedropdown {
  &.p-dropdown {
    width: 100%;
  }
}

.citydropdown {
  &.p-dropdown {
    width: 100% !important;
  }
}

.cancel-btn {
  color: #046a7e;
  padding: 0 10px;
}

.weightError {
  border: 1px solid #ef4d43;
  padding-right: 20px;
}

.theme-dark {
  .p-dropdown .p-dropdown-label.p-placeholder {
    color: #9ca3af;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    color: #fff;
    background: #1d4859;
  }

  .text-red {
    .p-dropdown-label.p-inputtext {
      color: #ef4d43;
    }
  }

  .field-wrapper .p-dropdown {
    background: #2e2f3c;
  }

  .cancel-btn {
    color: #fff;
  }

  .p-dropdown-panel .p-dropdown-items {
    padding: 0;
  }

  .p-dropdown-panel .p-dropdown-header {
    background: #292c2f;
  }

  .p-header-close-icon {
    fill: #fff;
  }

  .p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    background: #292c2f;
    color: #fff;
  }

  .p-dropdown {
    border: 1px solid transparent;
  }

  .form-control {
    border: none;
  }

  .createload-body .p-calendar .p-button {
    background: #2e2f3c;
    border: none;
    border-left: 1px solid #5f6272;
  }

  .p-inputtext {
    background: #2e2f3c;
    border: none;
    font-size: 14px;
    color: #c6ccd3;
    font-family: "roboto", sans-serif;
  }

  .p-radiobutton .p-radiobutton-box {
    border: 2px solid #ffffff !important;
    background: #4b4d5e !important;
  }

  .p-dropdown-panel
    .p-dropdown-items
    .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
    color: #fff;
    background: #000;
  }

  .p-dropdown {
    background: #2e2f3c;
  }

  hr {
    border: 1px solid #fff !important;
  }
}

.font-medium {
  font-weight: 500;
}

.errortext {
  color: #65b0ba;
  font-size: 10px !important;
  position: absolute;
  bottom: -15px;
  left: 0;
}

.p-checkbox ~ label {
  font-size: 12px;
}

.form-control.w-170 {
  width: 100%;
}

@-moz-document url-prefix() {
  .font-medium {
    font-weight: 600 !important;
  }
}

.custom-error.p-tooltip .p-tooltip-text {
  background-color: #ef4d43;
  color: rgb(255, 255, 255);
}

.custom-error.p-tooltip.p-tooltip-right .p-tooltip-arrow {
  border-right-color: #ef4d43;
}

.tooltip-error-icon.icon {
  fill: #e64a40;
}

.theme-white {
  .tooltip-error-icon.icon {
    fill: #e64a40;
  }
}

.field-tooltip-wrapper {
  position: relative;

  .tooltip-error-icon {
    position: absolute;
    top: 10px;
    right: 4px;
    width: 15px;
    height: 15px;
  }
}

.p-dialog-custome-header-icons{
  position: absolute;
  right:15px;
}
.createLoadPopupResponsive{
    overflow: auto;
    width:810px;
  .p-dialog-content{
   height:100%;
  }
.p-dialog-header-icons{
  position: absolute;
  right:50px;
}
}
.theme-dialog {
  .p-dialog-header-icons + .p-dialog-header-icons {
    display: none;
  }
}

.p-toast.p-component {
  z-index: 9999 !important;
}

.p-dropdown-label.p-inputtext {
  div {
    div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.text-red {
  .p-dropdown-label.p-inputtext {
    color: #ef4d43;
  }
}

.text-black {
  .p-dropdown-label.p-inputtext {
    color: #000;
  }
}

body.theme-dark
  .createload-body
  .p-radiobutton.p-radiobutton-disabled
  .p-radiobutton-box {
  border: 1px solid #fff !important;
  background: #4b4d5e !important;
}
.p-dropdown .p-dropdown-label.p-placeholder {
  color: #6c757d;
}

.customW-10{
 width:10% !important;
}
.postBoard{
  flex-direction: column;
  gap: 5px !important;
}
</style>
